import {JsonConverter, JsonCustomConvert} from "json2typescript";

@JsonConverter
export class NumberConverter implements JsonCustomConvert<number> {

    deserialize(data: string): number {
        //detect if int or float
        if (data.indexOf(".") > -1) {
            return parseFloat(data);
        } else {
            return parseInt(data);
        }
    }

    serialize(data: number): any {
        return data;
    }
}
import React, {Component} from 'react';
import {FlagsEnum} from "../../../../../../../../../server/common/Enum/live/FlagsEnum";
import {connect} from "react-redux";
import {getLabel, t} from "../../../translation/translator";
import Zoom from 'react-reveal/Zoom';
import LiveReducerType from "../../../type/LiveReducerType";
import ReducerType from "../../../type/ReducerType";
import {ReactSVG} from "react-svg";

const storeToProps = (state: ReducerType) => ({
    params: state.live.live.params,
    currentSession: state.live.currentSession,
    lang: state.live.lang
});

type Props = ReturnType<typeof storeToProps>;

class RaceNameStatusView extends Component<Props, any> {

    renderSvg(raceState: FlagsEnum){

        const GREEN_SVG = <svg width="15px" height="15px" viewBox="0 0 15 15">
            <g id="NAV/Icon_live/On" stroke="none" fill="none" >
                <g id="Oval">
                    <circle stroke="#00C340" cx="7.5" cy="7.5" r="7"></circle>
                    <circle fill="#00C340" cx="7.5" cy="7.5" r="3"></circle>
                </g>
            </g>
        </svg>;

        const YELLOW_SVG = <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1">
            <g id="NAV/Icon_live/Drapeau-jaune" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="NAV/Icon_live/On" stroke="#FFD500" strokeWidth="0.5">
                    <circle id="Oval" cx="7.5" cy="7.5" r="7"></circle>
                </g>
                <rect id="Rectangle" fill="#FFD500" transform="translate(7.500000, 7.500000) rotate(45.000000) translate(-7.500000, -7.500000) " x="4.99999991" y="4.99999991" width="5" height="5"></rect>
            </g>
        </svg>;

        const RED_SVG = <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1">
            <g id="NAV/Icon_live/Red" stroke="none" fill="none" >
                <g id="NAV/Icon_live/On" stroke="#C90D0D" >
                    <circle id="Oval" cx="7.5" cy="7.5" r="7"></circle>
                </g>
                <rect id="Rectangle" fill="#C90D0D" x="5" y="5" width="5" height="5"></rect>
            </g>
        </svg>;

        const SAFETY_SVG = <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1">
            <g id="NAV/Icon_live/Saefty" stroke="none" fill="none" >
                <g id="NAV/Icon_live/On" stroke="#C9C9C9" >
                    <circle id="Oval" cx="7.5" cy="7.5" r="7"></circle>
                </g>
                <rect id="Rectangle" fill="#C9C9C9" x="5" y="5" width="5" height="5"></rect>
            </g>
        </svg>;

        const FINISH_SVG = <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1">
            <defs>
                <circle id="circle-finish-1" cx="7.5" cy="7.5" r="7"></circle>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g>
                    <mask id="mask-finish-2" fill="white">
                        <circle cx="7.5" cy="7.5" r="7"></circle>
                    </mask>
                    <use id="Oval" stroke="#000000" strokeWidth="0.5" xlinkHref="#circle-finish-1"></use>
                    <rect id="Rectangle" fill="#000000" mask="url(#mask-finish-2)" x="0" y="0" width="5" height="5"></rect>
                    <rect id="Rectangle" fill="#ffffff" mask="url(#mask-finish-2)" x="5" y="0" width="5" height="5"></rect>
                    <rect id="Rectangle" fill="#000000" mask="url(#mask-finish-2)" x="10" y="0" width="5" height="5"></rect>
                    <rect id="Rectangle" fill="#ffffff" mask="url(#mask-finish-2)" x="0" y="5" width="5" height="5"></rect>
                    <rect id="Rectangle" fill="#000000" mask="url(#mask-finish-2)" x="5" y="5" width="5" height="5"></rect>
                    <rect id="Rectangle" fill="#ffffff" mask="url(#mask-finish-2)" x="10" y="5" width="5" height="5"></rect>
                    <rect id="Rectangle" fill="#000000" mask="url(#mask-finish-2)" x="0" y="10" width="5" height="5"></rect>
                    <rect id="Rectangle" fill="#ffffff" mask="url(#mask-finish-2)" x="5" y="10" width="5" height="5"></rect>
                    <rect id="Rectangle" fill="#000000" mask="url(#mask-finish-2)" x="10" y="10" width="5" height="5"></rect>
                </g>
            </g>
        </svg>;

        const FLAG_CHECK = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#000000">
            <path d="M 23 0.09375 L 21.5 1.40625 C 21.5 1.40625 19.292969 3.1875 17.09375 3.1875 C 16.09375 3.1875 15.3125 2.804688 14.3125 2.40625 C 13.210938 1.90625 11.898438 1.1875 10 1.1875 C 4.601563 1.1875 1.511719 4.488281 1.3125 4.6875 L 1 5 L 6.1875 19.59375 L 7.65625 23.71875 L 9.53125 23.0625 L 7.78125 18.09375 C 8.589844 17.410156 10.324219 16.09375 12.09375 16.09375 C 13.59375 16.09375 14.511719 16.394531 15.3125 16.59375 C 16.113281 16.792969 16.894531 17.09375 18.09375 17.09375 C 20.492188 17.09375 22.492188 16 22.59375 16 L 23.09375 15.6875 Z M 10.09375 3 C 10.355469 3 10.605469 3.03125 10.84375 3.0625 L 11.6875 6.3125 C 12.585938 6.3125 15.09375 7.5 15.09375 7.5 L 14.375 4.375 C 15.199219 4.730469 16.050781 5 17.1875 5 C 17.652344 5 18.113281 4.941406 18.5625 4.84375 L 18.8125 8 C 18.8125 8 19.851563 7.722656 21.1875 6.875 L 21.1875 11.09375 C 20.078125 11.585938 19.09375 11.8125 19.09375 11.8125 L 19.34375 15.0625 C 18.917969 15.144531 18.476563 15.1875 18 15.1875 C 17.5625 15.1875 17.195313 15.132813 16.84375 15.0625 L 16.09375 11.8125 C 15.09375 11.511719 13.605469 11 12.90625 11 L 13.8125 14.34375 C 13.273438 14.257813 12.675781 14.1875 11.90625 14.1875 C 11.273438 14.1875 10.652344 14.34375 10.0625 14.53125 L 8.8125 11.3125 C 7.789063 11.734375 6.742188 12.507813 6.03125 13.09375 L 4.40625 8.53125 C 5.875 7.410156 7.3125 6.90625 7.3125 6.90625 L 6.03125 3.78125 C 7.132813 3.335938 8.488281 3 10.09375 3 Z M 19.09375 11.8125 L 18.8125 8 C 18.8125 8 18.207031 8.1875 17.40625 8.1875 C 16.304688 8.1875 15.09375 7.59375 15.09375 7.59375 L 16 11.6875 C 16 11.6875 16.894531 11.90625 17.59375 11.90625 C 18.292969 11.90625 19.09375 11.8125 19.09375 11.8125 Z M 11.6875 6.3125 L 10.90625 6.3125 C 8.90625 6.3125 7.1875 7 7.1875 7 L 8.90625 11.3125 C 8.90625 11.3125 10.195313 10.8125 11.09375 10.8125 C 11.992188 10.8125 12.90625 10.90625 12.90625 10.90625 Z"/>
        </svg>;

        let flagEnumToSvg = {
            [FlagsEnum.GREEN]: GREEN_SVG,
            [FlagsEnum.RED]: RED_SVG,
            [FlagsEnum.SAFETY]: SAFETY_SVG,
            [FlagsEnum.YELLOW]: YELLOW_SVG,
            [FlagsEnum.FULL_YELLOW]: YELLOW_SVG,
            [FlagsEnum.CHECK]: FINISH_SVG
        };
        return flagEnumToSvg[raceState];
    }

    render() {
        let state = this.props.params.raceState;
        let name = t(this.props.params.sessionName, this.props.lang);


        if (this.props.currentSession && this.props.currentSession.status == "Closed")
        {
            state = FlagsEnum.CHECK;
            name = getLabel(this.props.currentSession,"name", this.props.lang);
            if (!name) {
                name = "";
            }
            try {
                name = name.toUpperCase();
                name = name.replace("&AMP;",'&');
            }
            catch (ex) {
                name = ""
            }
        }
        return (
            <div className="free-practice">
                <Zoom spy={state}>
                    <div className="icon">
                        {this.renderSvg(state)}
                    </div>
                </Zoom>
                <div className="label">{name}</div>
            </div>
        )
    }
}
const RaceNameStatus = connect(storeToProps, null)(RaceNameStatusView);
export default RaceNameStatus;

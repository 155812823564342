import React, {Component, useEffect, useRef, useState} from "react";
import {Modal} from "react-bootstrap";
import {t} from "../../translation/translator";
import ReducerType from "../../type/ReducerType";
import ActionLiveType from "../../reducer/live/ActionLiveType";
import {connect} from "react-redux";

const storeToProps = (state: ReducerType) => {
    return {
        showModal: state.live.showModalRequestLogin,
        lang: state.live.lang
    }
};
const storeDispatchToProps = (dispatch) => ({
    handleClose: () => dispatch(ActionLiveType.updateShowModalRequestLogin(false))
});

type Props = ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>;

export const RequestLoginModalView = (props: Props) => {
    const [urlLogin, setUrlLogin] = useState("");

    useEffect(() => {
        const linkLogin = document.querySelector(".login a") as any;
        if (linkLogin) {
            setUrlLogin(linkLogin.attributes.href.value.replace("en", props.lang).replace("fr", props.lang));
        }
    }, [props.lang]);

    return (
        <Modal show={props.showModal} onHide={props.handleClose} className="request-login-modal" backdrop="static">
            <Modal.Body>{t('login_to_access', props.lang)}</Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-primary" onClick={props.handleClose}>
                    {t("close", props.lang)}
                </button>
                <a href={urlLogin} className="btn btn-primary" onClick={props.handleClose}>
                    {t("login", props.lang)}
                </a>
            </Modal.Footer>
        </Modal>
    )
}
const RequestLoginModal = connect(storeToProps, storeDispatchToProps)(RequestLoginModalView);
export default RequestLoginModal;
import {FlagsEnum} from "../../Enum/live/FlagsEnum";

export default class FlagProgress {
    state: FlagsEnum;
    percentProgress: number = 0;
    startTime: number = 0;
    current: boolean = false;

    constructor(state: FlagsEnum) {
        this.state = state;
    }
}
import ReducerType from "../../type/ReducerType";
import ActionLiveType from "../../reducer/live/ActionLiveType";
import {connect} from 'react-redux';
import {t} from "../../translation/translator";
import React from "react";

const storeToProps = (state: ReducerType) => ({
    currentCategory: state.live.currentCategory,
    categories: state.live.referential.categories,
    lang: state.live.lang,
});
const storeDispatchToProps = (dispatch) => ({
    filterCategory: (id: number) => dispatch(ActionLiveType.filterCategory(id)),
});

type Props = {
    enabledAllByCategory?: boolean
} & ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>

const CategoryFilterView = (props: Props) => {

    function checkCurrent(cat: number): string {
        if (props.currentCategory == cat || (cat == -1 && props.currentCategory == -2 && !props.enabledAllByCategory))
            return " current";
        return "";
    }

    function clickCategory(e: React.MouseEvent, id: number) {
        e.preventDefault();
        props.filterCategory(id);
    }

    return (
        <div className="category-filter">
            <a
                key={-1}
                href="#"
                onClick={(e) => clickCategory(e, -1)}
                className={"filter overall " + checkCurrent(-1)}
            >
                <div/>
                <span>{t("overall", props.lang)}</span>
            </a>
            {props.enabledAllByCategory &&
                <a
                    key={-2}
                    href="#"
                    onClick={(e) => clickCategory(e, -2)}
                    className={"filter overall " + checkCurrent(-2)}
                >
                    <div/>
                    <span>{t("overallCategory", props.lang)}</span>
                </a>
            }
            {props.categories.map((cat, index) => {
                return <a
                    key={cat.id}
                    href="#"
                    onClick={(e) => clickCategory(e, cat.id)}
                    className={"filter " + checkCurrent(cat.id)}
                    style={{color: cat.color}}
                >
                    <div style={{backgroundColor: cat.color}}/>
                    <span>{cat.name.toUpperCase()}</span>
                </a>
            })}
        </div>
    );
}
export default connect(storeToProps, storeDispatchToProps)(CategoryFilterView);
import ParticipantStints from "../../../../../../../server/common/Models/alkamel/ParticipantStints";
import {DriverStints} from "./ReferentialType";
export default class StintsReducerType {

    constructor() {
        this.byEntry = [];
        this.byDriver = [];
    }

    byEntry: Array<ParticipantStints> = [];
    byDriver: Array<DriverStints> = [];
}

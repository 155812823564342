import RaceControlMessage from "../../../../../../../server/common/Models/alkamel/RaceControlMessage";

export default class RaceControlReducerType {

    constructor() {
        this.messages = [];
    }

    messages: Array<RaceControlMessage> = [];
}

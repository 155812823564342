import React, {Component} from 'react';
import {
    ReferentialBrandType, ReferentialCarType, ReferentialCategoryType,
    ReferentialDriverType,
    ReferentialEntryType,
    ReferentialTeamType
} from "../../../type/ReferentialType";
import ReactModal from 'react-modal';
import {connect} from "react-redux";
import ReducerType from "../../../type/ReducerType";
import ActionLaps from "../../../reducer/laps/ActionLaps";
import ActionTunnel from "../../../reducer/tunnel/ActionTunnel";
import ActionLiveType from "../../../reducer/live/ActionLiveType";
import {NavigationEnum} from "../../../enum/NavigationEnum";
import {t} from "../../../translation/translator";
import {AlignBottom} from "akar-icons";
import {Button} from "../Button";
import {displayLapTime, displayTime, getDriverPicture} from "../../../reducer/live/reducer";
import moment from "moment";
import ParticipantLaps from "../../../../../../../../../server/common/Models/alkamel/ParticipantLaps";
import {getDriverDetail} from "../../../reducer/stints/reducer";
import Entry from "../../../../../../../../../server/common/Models/live/Entry";
import ActionStints from "../../../reducer/stints/ActionStints";
import DriverDetail from "../../../type/DriverDetail";


const storeToProps = (state: ReducerType) => {
    return {
        lang: state.live.lang,
        categoriesRef: state.live.referential.categories,
        drivers: state.live.referential.drivers,
        teams: state.live.referential.teams,
        brands: state.live.referential.brands,
        cars: state.live.referential.cars,
        member: state.tunnel.member,
        noLogin: state.live.noLogin,
        championship: state.live.championship,
        race: state.live.referential.race,
        allCarLaps: state.laps.laps,
        byDriverStints: state.stints.byDriver,
        entries: state.live.live.entries,
        bestTimesByCategory: state.live.live.bestTimesByCategory,
        darkMode: state.live.darkMode,
    }
};
const storeDispatchToProps = (dispatch) => ({
    showTunnel: () => dispatch(ActionTunnel.toggleTunnel(false)),
    navigate: (view: string, lang: string, params: string = "") => dispatch(ActionLiveType.navigate(view, lang, params)),
    connectToStintsSocket: () => dispatch(ActionStints.connectToStintsSocket()),
    disconnectFromStintsSocket: () => dispatch(ActionStints.disconnectFromStintsSocket()),
    connectToLapsSocket: () => dispatch(ActionLaps.connectToLapsSocket()),
    disconnectFromLapsSocket: () => dispatch(ActionLaps.disconnectFromLapsSocket()),
    showModalRequestLogin: () => dispatch(ActionLiveType.updateShowModalRequestLogin(true))

});
declare var reactParameters;

type Props = ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps> & {
    entryRef: ReferentialEntryType,
    driver: ReferentialDriverType,
    closeCallback: () => void
};
const customStyles = {
    content: {
        padding: 0,
        maxwidth: '50%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class LiveTablePopupView extends Component<Props> {
    team: ReferentialTeamType = null;
    drivers: Array<ReferentialDriverType> = null;
    brand: ReferentialBrandType = null;
    model: ReferentialCarType = null;
    category: ReferentialCategoryType = null;

    carLaps: ParticipantLaps = null;
    entry: Entry = null;

    constructor(props: Props) {
        super(props);
        this.team = this.props.teams.find(e => e.id == this.props.entryRef.team);
        this.model = this.props.cars.find(e => e.id == this.props.entryRef.vehicle);
        this.brand = this.props.brands.find(e => e.id == this.props.entryRef.brand);
        this.category = this.props.categoriesRef.find(e => e.id == this.props.entryRef.category);
        this.drivers = this.props.entryRef.drivers.map((id, index) => this.props.drivers.find((d => d.id == id)));
        this.carLaps = this.props.allCarLaps.find(item => item.participant.toString() == this.props.entryRef.number)
        this.entry = this.props.entries.find(e => e && e.number.toString() == this.props.entryRef.number)
    }

    componentDidMount() {
        ReactModal.setAppElement('body');
    }

    goToStats() {
        if (null === this.props.member && false === this.props.noLogin) {
            // alert(t('login_to_access', this.props.lang));
            this.props.showModalRequestLogin();
            this.props.showTunnel();
        } else {
            this.props.navigate(NavigationEnum.CAR_STATS, this.props.lang, "number=" + this.props.entryRef.number)
        }
    }

    render() {
        return <ReactModal
            isOpen={true}
            onRequestClose={this.props.closeCallback}
            style={customStyles}
            appElement={document.getElementById('app')}
            overlayClassName={(this.props.darkMode ? 'dark' : 'light')}
        >
            <div className={"entry-popup" + (this.props.darkMode ? ' dark' : ' light')}>
                <div className="header">
                    <h2>
                        {this.props.entryRef.custom_car_number_small_url ?
                            <img className="custom-number" src={this.props.entryRef.custom_car_number_small_url}/> :
                            <span className="number" style={{
                                WebkitTextStroke: "1px " + this.category.color
                            }}>{this.props.entryRef.number}</span>
                        }
                        <span className="team">{this.team.name}</span>
                        <Button onClick={() => {
                            this.goToStats()
                        }}>
                            <AlignBottom/>
                            <span className="label"> {t("stats", this.props.lang)}</span>
                        </Button>
                        { this.team.country_id &&
                        <img className="country"
                             src={"/bundles/easycore/img/flag/" + this.team.country_id.toLowerCase() + ".png"}/>
                        }
                    </h2>
                </div>
                <div className="car">
                    <div className="car-picture-container">
                        {this.props.entryRef.picture_url &&
                            <>
                                <img src={this.props.entryRef.picture_url.replace("media/cache/easy_thumb/", "")}
                                     className="car-picture"/>
                                <span>{(this.brand ? this.brand.title : "") + " " + (this.model ? this.model.name : "")}</span>
                            </>
                        }
                    </div>
                </div>
                <div className="drivers">
                    {this.drivers.map((driver, inder) => {
                            let date = moment(this.props.race.start_date);
                            let driverStints = this.props.byDriverStints.find(item => item.driverId == driver.id);
                            let detail: DriverDetail = null;
                            if (driverStints) {
                                detail = getDriverDetail(driverStints, this.carLaps, this.entry.lap);
                            }
                            let race = this.props.race;
                            let alias = this.props.championship.alias;
                            if (race.name_fr === '24 Heures du Mans') {
                                alias = "Circuit du Mans"
                            }
                            let picture = getDriverPicture(driver, alias, date.year())
                            let akDriver = this.entry.drivers.find(d => d.number == this.entry.driverId);
                            let current = akDriver && akDriver["ECM Driver Id"] === driver.id.toString();
                            if (!current && 1 === this.entry.drivers.length) {
                                current = true;
                            }
                            let bestLap = null !== detail && 0 !== detail.best && this.entry.bestlapTime >= detail.best;

                            let bestOverall = false;
                            // best de la categ ?
                            if (detail && this.props.bestTimesByCategory && this.props.bestTimesByCategory[this.category.id] === detail.best) {
                                bestOverall = true;
                            }
                            let bestClass = bestOverall ? 'best-overall' :
                                (bestLap ? 'best' : '');

                            return <div className={"driver " + (current ? ' current' : '')} key={driver.id}>
                                {driver.rank &&
                                <div className={"driver-rank " + driver.rank.toLowerCase()} title={t('rank', this.props.lang) + ' : ' + driver.rank}>
                                    {driver.rank.substr(0,1)}
                                </div>
                                }
                                <div className="portrait-container">
                                    {picture ?
                                        <img src={picture}
                                             className="portrait"/> :
                                        <img src="https://www.europeanlemansseries.com/assets/driver_unknown.png"
                                             className="portrait"/>
                                    }
                                </div>
                                <div className="description">
                                    {driver.country &&
                                        <img className="country"
                                             src={"/bundles/easycore/img/flag/" + driver.country.toLowerCase() + ".png"}/>
                                    }
                                    <span>{driver.firstname} {driver.lastname.toUpperCase()}</span>
                                    {/*{current &&*/}
                                    {/*<div className="current-wheel">*/}
                                    {/*    <ReactSVG*/}
                                    {/*        src={"/bundles/front/svg/wheel.svg"}/>*/}
                                    {/*</div>*/}
                                    {/*}*/}
                                </div>
                                <div className="detail">
                                    {detail &&
                                        <>
                                            <div><label>{t('nblap', this.props.lang)} : </label><span>{detail.laps}</span>
                                            </div>
                                            <div>
                                                <label>{t('bestlap', this.props.lang)} : </label><span
                                                className={bestClass}>{displayLapTime(detail.best)}</span>
                                            </div>
                                            <div>
                                                <label>{t('drive_time', this.props.lang)} : </label><span>{displayTime(detail.drive_time)}</span>
                                            </div>
                                        </>
                                    }
                                    {!detail &&
                                        <p>{t('driver_no_laps', this.props.lang)}</p>
                                    }
                                </div>
                            </div>
                        }
                    )}
                </div>

            </div>
        </ReactModal>
    }

}


const LiveTablePopup = connect(storeToProps, storeDispatchToProps)(LiveTablePopupView);
export default LiveTablePopup;
import {JsonConverter, JsonCustomConvert} from "json2typescript";

@JsonConverter
export class TimeDurationToSecond implements JsonCustomConvert<number> {

    deserialize(data: string): number {
        let tab = data.split(":");
        if (tab.length == 3) {
            return (parseInt(tab[0])*3600) + (parseInt(tab[1])*60) + parseInt(tab[2]);
        } else {
            return (parseInt(tab[0])*60) + parseInt(tab[1]);
        }
    }

    serialize(data: number): any {
        return data;
    }

}
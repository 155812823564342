import React, {Component} from 'react';
import {connect} from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import LiveReducerType from "../../type/LiveReducerType";
import Draggable, {DraggableCore} from 'react-draggable';
import ReducerType from "../../type/ReducerType";
import {t} from "../../translation/translator";

const storeToProps = (state: ReducerType) => ({
    flagVisible: state.live.flagVisible,
    currentSession: state.live.currentSession,
    lang: state.live.lang,
    videos: state.live.videos
});
type State = {
    forceHidden: boolean,
    visible: boolean,
    activeCheckVisible: boolean,
    activeDrags: number,
    axis: boolean,
    deltaPosition: {
        x: number, y: number
    },
    controlledPosition: {
        x: number, y: number
    }
};
type Props = ReturnType<typeof storeToProps>;
declare var reactParameters;

class VideoDisplayView extends Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            forceHidden: false,
            visible: true,
            activeCheckVisible: true,
            axis: true,
            activeDrags: 0,
            deltaPosition: {
                x: 0, y: 0
            },
            controlledPosition: {
                x: -400, y: 200
            }
        };
        this.onChangeVisibility = this.onChangeVisibility.bind(this);
    }

    componentDidMount(): void {
        const fCheckSize = () => {
            if (this.state.forceHidden || window.innerWidth <= 990 || window.pageYOffset < 212) {
                this.setState({visible: true, activeCheckVisible: false, axis: false});
            } else {
                this.setState({activeCheckVisible: true, axis: true});
            }
        };
        window.addEventListener("resize", fCheckSize);
        window.addEventListener("scroll", fCheckSize);
        fCheckSize();
    }

    onChangeVisibility(visible: boolean) {
        this.setState({visible: visible});
    }

    handleDrag = (e, ui) => {
        const {x, y} = this.state.deltaPosition;
        this.setState({
            deltaPosition: {
                x: x + ui.deltaX,
                y: y + ui.deltaY,
            }
        });
    };

    onStart = () => {
        let activeDrags = this.state.activeDrags
        this.setState({activeDrags: ++activeDrags});
    };

    onStop = () => {
        let activeDrags = this.state.activeDrags
        this.setState({activeDrags: --activeDrags});
    };

    sendTop = () => {
        this.setState({forceHidden:true,visible: true, activeCheckVisible: false, axis: false})
    }

    render() {

        let videoUrl = "";
        if (this.props.videos) {
            videoUrl = this.props.lang=="fr" ? this.props.videos.fr : this.props.videos.en;
        }
        if (null === videoUrl && this.props.videos.channels.length > 0) {
            videoUrl = this.props.videos.channels[0].url;
        }
        const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
        const axis = this.state.axis === true ? "both" : "none";
        //let videoUrl = this.props.currentSession.replay_url ? this.props.currentSession.replay_url : reactParameters.videoUrl[this.props.lang];
        return (
            <>
                <VisibilitySensor minTopValue={100} active={this.state.activeCheckVisible}
                                  onChange={this.onChangeVisibility}>
                    <div className="trigger-visibility view video"></div>
                </VisibilitySensor>
                <Draggable
                    axis={axis}
                    handle=".handle"
                    position={null}
                    grid={[5, 5]}
                    {...dragHandlers}
                    scale={1}
                >
                        <div className={"video-wrapper " + ((!this.state.visible) ? "scrolled" : "")}>
                            <div className="video-container">
                                <div className={"handle drag-control"}>
                                    <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                </div>

                                <div className="drag-control" style={{right:0, cursor: "pointer"}} onClick={this.sendTop.bind(this)}>
                                    <i className="fa fa-arrow-up" title={t("force_top",this.props.lang)}></i>
                                </div>

                                <iframe src={videoUrl} frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                            </div>
                        </div>
                </Draggable>
            </>
        )
    }
}

const VideoDisplay = connect(storeToProps, null)(VideoDisplayView);
export default VideoDisplay;
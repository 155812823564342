import React, {Component, useState} from 'react';
import Accordion from "../fragment/Accordion";
import {connect} from "react-redux";
import ActionLiveType from "../../reducer/live/ActionLiveType";
import {t} from "../../translation/translator";
import ReducerType from "../../type/ReducerType";
import ActionTunnel from "../../reducer/tunnel/ActionTunnel";
import {Globe, ChevronRight, PersonAdd, CircleTriangleRight, CircleChevronLeft, CircleChevronRight} from 'akar-icons';
import {useCookies} from "react-cookie";
import NavItem from "../fragment/NavItem";
import {NavigationEnum} from "../../enum/NavigationEnum";
import {useLocation, useParams} from "react-router";

declare var reactParameters;
type Props = {
    home: string,
    lang: string,
    session: number
} & ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>;

const storeToProps = (state: ReducerType) => (
    {
        replay: state.live.live.params.replay,
        socialNetworks: state.live.socialNetworks,
        championship: state.live.championship,
        lang: state.live.lang,
        sessions: state.live.referential.sessions,
        currentSession: state.live.currentSession,
        forLemans: state.live.forLemans,
        selectedMenu: state.live.selectedMenu,
        member: state.tunnel.member,
        hasRacePack: state.tunnel.hasCurrentRacePack,
        tunnelHidden: state.tunnel.tunnelHidden,
        race: state.live.referential.race,
        darkMode: state.live.darkMode,
    });

const storeDispatchToProps = (dispatch) => ({
    updateSelectedMenu: (selected: string) => dispatch(ActionLiveType.updateSelectedMenu(selected)),
    showTunnel: () => dispatch(ActionTunnel.toggleTunnel(false)),
    navigate: (view: string, lang: string, params: string = "") => dispatch(ActionLiveType.navigate(view, lang, params)),
});

export const LiveSideBarView = (props: Props) => {
    const [cookies, setCookie] = useCookies(['hideTunnel', 'sidebarCollapsed']);
    const [collapsed, setCollapsed] = useState(cookies["sidebarCollapsed"]);
    let [currentSession, setCurrentSession] = useState(props.sessions.find(s => s.id == props.session))

    function showTunnel() {
        setCookie('hideTunnel', 0);
        props.showTunnel();
        props.navigate("live", props.lang)
    }

    function toggleSidebar() {
        setCookie('sidebarCollapsed', !collapsed);
        setCollapsed(!collapsed);
    }

    let alias = props.championship.alias;
    if (props.forLemans) {
        alias = "lemans";
    }

    let footer = null;
    if ("wec" === alias || props.forLemans) {
        footer = <>
            {"wec" === alias &&
            <div className="sidebar-item">
                <a href={props.home}>
                    <Globe/>
                    <span> {t("wec_website", props.lang)}</span>
                    <ChevronRight/>
                </a>
            </div>
            }
            {props.tunnelHidden && !props.hasRacePack &&
            <div className="sidebar-item">
                <a onClick={(e) => {
                    e.preventDefault();
                    showTunnel();
                }} href="#">
                    <PersonAdd/>
                    <span>
                    {t("premium_access", props.lang)}
                    </span>
                    <ChevronRight/>
                </a>
            </div>
            }
        </>
    }

    let classname = "sidebar ";
    if (props.darkMode) {
        classname += "dark ";
    }
    let icon = <CircleChevronLeft/>
    let alt = t('hideSidebar', props.lang)
    if (collapsed) {
        classname += "collapsed";
        icon = <CircleChevronRight/>
        alt = t('showSidebar', props.lang)
    }
    return (
        <div className={classname}>
            <div className="sidebar-inner">
                <a href={props.home} className="logo" target="_blank">
                    <img
                        src={"/bundles/front/images/logo_" + alias + ".png"} alt={alias}/>
                </a>
                <div className="sidebar-nav">
                    <div className="accordion-menu">
                        <Accordion/>
                    </div>
                    <NavItem page={NavigationEnum.LIVE} svg="play" title="live_overview" lang={props.lang}
                             free={true}/>
                    {(props.forLemans && props.race && props.race.id === 4706) &&
                    <>
                        {/*<NavItem page={NavigationEnum.KEYNOTE} svg="newspaper" title="live_keynote" lang={props.lang}*/}
                        {/*         free={true} member={props.member}/>*/}
                        {/*<NavItem page={NavigationEnum.RACE_EVENTS} svg="clock" title="live_raceevent" lang={props.lang}*/}
                        {/*         free={true} member={props.member}/>*/}
                    </>
                    }
                    {!props.replay &&
                    <>
                        <NavItem page={NavigationEnum.SESSION_STATS} svg="stats" title="session_stats" lang={props.lang}
                                 member={props.member}/>
                        <NavItem page={NavigationEnum.PIT_TIME} svg="drill" title="live_pittime" lang={props.lang}
                                 member={props.member}/>
                        <NavItem page={NavigationEnum.DRIVE_TIME} svg="wheel" title="live_drivetime" lang={props.lang}
                                 member={props.member}/>
                        <NavItem page={NavigationEnum.CAR_STATS} svg="car" title="live_carstats" lang={props.lang}
                                 member={props.member}/>
                        <NavItem page={NavigationEnum.CAR_COMPARISON} svg="compare" title="live_carcomparisons"
                                 lang={props.lang}
                                 member={props.member}/>
                        <NavItem page={NavigationEnum.RACE_CONTROL} svg="sheet" title="live_racecontrol"
                                 lang={props.lang}
                                 member={props.member}/>
                    </>
                    }
                    <div className="social">
                        <div className="social-icons">
                            {props.socialNetworks["facebook"] &&
                            <a href={props.socialNetworks["facebook"]} target="_blank">
                                <i className="fa fa-facebook fa-fw" aria-hidden="true"></i>
                            </a>
                            }
                            {props.socialNetworks["twitter"] &&
                            <a href={props.socialNetworks["twitter"]} target="_blank">
                                <i className="fa fa-twitter fa-fw" aria-hidden="true"></i>
                            </a>
                            }
                            {props.socialNetworks["instagram"] &&
                            <a href={props.socialNetworks["instagram"]} target="_blank">
                                <i className="fa fa-instagram fa-fw" aria-hidden="true"></i>
                            </a>
                            }
                            {props.socialNetworks["youtube"] &&
                            <a href={props.socialNetworks["youtube"]} target="_blank">
                                <i className="fa fa-youtube-play fa-fw" aria-hidden="true"></i>
                            </a>
                            }
                            {props.socialNetworks["linkedin"] &&
                            <a href={props.socialNetworks["linkedin"]} target="_blank">
                                <i className="fa fa-linkedin fa-fw" aria-hidden="true"></i>
                            </a>
                            }

                        </div>
                    </div>
                    <div className="footer">
                        <span title={alt} className="toggle" onClick={() => toggleSidebar()}>{icon}</span>
                        {footer}
                    </div>
                </div>
            </div>
        </div>
    )
}


const LiveSideBar = connect(storeToProps, storeDispatchToProps)(LiveSideBarView);
export default LiveSideBar;
import {actionTypeEnum} from "./actionTypeEnum";
import ReducerType from "../../type/ReducerType";
import RaceControlReducerType from "../../type/RaceControlReducerType";
import Socket = SocketIOClient.Socket;

/**
 * Données initiales
 */
const initialState = new RaceControlReducerType();

/**
 * Reducer Stints
 * @param state
 * @param action
 */
const reducer = (state = initialState, action: { type: actionTypeEnum, data: any }) => {
    if (undefined == state) {
        state = new RaceControlReducerType();
    }
    let newState = {...state};
    switch (action.type) {
        case actionTypeEnum.UPDATE_RACE_CONTROL:
            let messages = newState.messages;
            for (let index in action.data) {
                let message = action.data[index];
                if (null != message) {
                    let id = message.id;
                    // recherche dans les lignes existantes
                    let previous = messages.findIndex(e => null != e && e.id === id);
                    if (0 <= previous) {
                        messages[previous] = message;
                    } else {
                        messages.push(message);
                    }
                }
            }
            newState.messages = messages;
            break;

        default:
            return state;
    }
    return newState;
};


export function connectToSocket(socket: Socket) {
    return async function (dispatch: Function, getState: () => ReducerType) {
        socket.emit("race_control:join");
    }
}

export function disconnectFromSocket() {
    return async function (dispatch: Function, getState: () => ReducerType) {
        let socket = getState().live.socket;
        socket.emit("race_control:leave");
    }
}

export default reducer;
export enum NavigationEnum {
    LIVE = "live",
    REPLAY = "replay",
    SESSION_STATS = "session_stats",
    PIT_TIME = "pit_time",
    DRIVE_TIME = "drive_time",
    CAR_STATS = 'car_stats',
    CAR_COMPARISON = 'car_comparison',
    RACE_CONTROL = 'race_control',
    // spé 24h
    KEYNOTE = "keynote",
    RACE_EVENTS = "race_events",

}
import socketIOClient from "socket.io-client";

class Home {
    constructor($view) {
        this.view = $view;
        this.socket = this.view.data('socket');
        this.cta = this.view.find(".js-cta");
        this.countdown = $("body").find(".js-countdown");
        this.days = this.countdown.find(".js-days");
        this.hours = this.countdown.find(".js-hours");
        this.minutes = this.countdown.find(".js-minutes");
        this.seconds = this.countdown.find(".js-seconds");
        this.progress = this.view.find(".in-progress .progress-bar");
        this.eventCards = this.view.find('.all-event-card');
        this.viewer = $(".viewer");
        this.bind();
        this.initRaceListener();
    }

    bind() {
        this.bindEventCards();

        $(".timeline-inner").on('click','.disabled a', function(e) {
            e.preventDefault();
            return false;
        });
        try {
            $(".timeline-inner").animate({scrollLeft: $(".in-progress").offset().left});
        } catch (e) {
            return false;
        }
    }

    bindEventCards() {
        $("body").on("click", ".all-event-card", (e) => {
            //if (e.target.tagName !== "SPAN") {
            e.preventDefault();
            let link = $(e.target).parents(".all-event-card");
            // let url = link.data("href");
            // if (url) {
            //     $("html").animate({scrollTop: 0}, 300, 'swing', () => {
            //         window.location = url;
            //     });
            // }
            //}
            // appel ajax pour récupérer la timeline
            let url = link.data("timeline-href");
            if (url)
                Api.GET(url);
            let id = link.data("id");
            let race = link.data("race");

            $("body").animate({scrollTop: 0}, 300, 'swing', () => {
                let el = $(e.currentTarget).find(".display")[0];
                let content = el.innerHTML;
                $(this.viewer).find(".race-content").html(content);

                let logo = $(el).find(".logo img")[0];
                let logoEl = $(this.viewer).find("img");
                $(logoEl).attr("src", $(logo).attr("src"));
                let img = $(el).css("background-image").replace('url(', '').replace(')', '').replace(/\"/gi, "");
                img = img.replace('easy_thumb','race_header');
                $(this.viewer).css("background-image", "url(" + img + ")");


                $(".current").removeClass("current");
                // couleur du bandeau :
                let status = link.data("status");
                link.addClass("current");

                let url = this.updateQueryStringParameter(location.href, 'r', id);
                document.title = race;
                window.history.pushState(race,race,url);
            });
        });
    }

    updateQueryStringParameter(uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
            return uri + separator + key + "=" + value;
        }
    }

    change_favicon(img) {
        var favicon = document.querySelector('link[rel="shortcut icon"]');

        if (!favicon) {
            favicon = document.createElement('link');
            favicon.setAttribute('rel', 'shortcut icon');
            var head = document.querySelector('head');
            head.appendChild(favicon);
        }

        favicon.setAttribute('type', 'image/png');
        favicon.setAttribute('href', img);
    }
    initRaceListener() {
        let self = this;
        // init ws and update data course
        let socket = socketIOClient(self.socket, {secure: self.socket.includes("wss")});
        socket.on("race_light", (data) => {
            // maj du countdown
            if (self.countdown) {
                let remaining = Math.max(0, data.remaining * 1000);
                let duration = moment.duration(remaining);
                self.days.html(Math.floor(duration.asDays()).toLocaleString('en-US', {minimumIntegerDigits: 2}));
                self.hours.html(Math.floor(duration.hours()).toLocaleString('en-US', {minimumIntegerDigits: 2}));
                self.minutes.html(Math.floor(duration.minutes()).toLocaleString('en-US', {minimumIntegerDigits: 2}));
                self.seconds.html(Math.floor(duration.seconds()).toLocaleString('en-US', {minimumIntegerDigits: 2}));
            }

            // maj du bouton Home
            self.cta.removeClass("green red yellow full_yellow safety_car Chk").addClass(data.raceState)

            // maj de la favicon
            switch (data.raceState) {
                case "red":
                case "green":
                case "yellow":
                case "full_yellow":
                case "safety_car":
                case "Chk":
                    this.change_favicon("/bundles/front/images/favicon_" + data.raceState + ".png");
                    break;
                default:
                    this.change_favicon("/favicon.png");
                    break;
            }

            // maj de la timeline
            self.progress.html("");
            // data.progress.forEach((p, i) => {
            //     self.progress.append("<div class='" + p.state + "' style='width:" + Math.ceil(p.percentProgress) + "%'></div>");
            // });
        });
    }
}

module.exports = Home;
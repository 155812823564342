import React from "react";
import {Question} from "akar-icons";
import {Member} from "../../type/TunnelReducerType";
import {connect} from "react-redux";
import ReducerType from "../../type/ReducerType";
import ActionTunnel from "../../reducer/tunnel/ActionTunnel";
import ActionLiveType from "../../reducer/live/ActionLiveType";

type Props = {
    title: string,
    help?: string,
    children :any,
    className?: string
}
const storeToProps = (state: ReducerType) => (
    {
        lang: state.live.lang,
        member: state.tunnel.member,
        tunnelHidden: state.tunnel.tunnelHidden,
        noLogin: state.live.noLogin,
        darkMode: state.live.darkMode,
    });

const storeDispatchToProps = (dispatch) => ({
    showTunnel: () => dispatch(ActionTunnel.toggleTunnel(false)),
    navigate: (view: string, lang: string, params: string = "") => dispatch(ActionLiveType.navigate(view,lang, params))
});

const StatScreenLayoutView = (props: Props & ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>) => {
    if (null === props.member && false === props.noLogin) {
        // pas d'accès, retour à la home
        if (true === props.tunnelHidden) {
            props.showTunnel();
        }
        props.navigate("live",props.lang);
        return null;
    }
    return (<div className={"stat-content " + (props.darkMode ? " dark " : " light ") + props.className }>
        <div className="intro">
            <h1>{props.title}</h1>
            {/*{props.help &&*/}
            {/*    <Question />*/}
            {/*}*/}

        </div>
        <div className="content">
            {props.children}
        </div>
    </div>)
}

const StatScreenLayout = connect(storeToProps, storeDispatchToProps)(StatScreenLayoutView);
export default StatScreenLayout;
import ReducerType from "../../../type/ReducerType";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {ReferentialCategoryType, ReferentialDriverType, ReferentialEntryType} from "../../../type/ReferentialType";
import ActionLaps from "../../../reducer/laps/ActionLaps";
import {ResponsiveBar} from '@nivo/bar'
import {line} from "d3-shape";

import ParticipantLaps from "../../../../../../../../../server/common/Models/alkamel/ParticipantLaps";
import {displayLapTime, displayTime} from "../../../reducer/live/reducer";
import {t} from "../../../translation/translator";

type Props = {
    mainEntry: ReferentialEntryType,
    otherEntry: ReferentialEntryType
}

type LapValue = {
    lapNumber: number,
    lapDiff: number,
    cumulativeDiff: number,
    mainLap: number,
    otherLap: number
}

const storeToProps = (state: ReducerType) => ({
    entries: state.live.referential.entries,
    categories: state.live.referential.categories,
    lang: state.live.lang,
    lapsDetail: state.laps,
    socket: state.live.socket,
    darkMode: state.live.darkMode,
});

const storeDispatchToProps = (dispatch) => ({
    connectToLapsSocket: () => dispatch(ActionLaps.connectToLapsSocket()),
    disconnectFromLapsSocket: () => dispatch(ActionLaps.disconnectFromLapsSocket()),
});

const CarComparisonView = (props: Props & ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>) => {
    const positive_color = "green";
    const negative_color = "red";

    useEffect(() => {
        if (null !== props.socket) {
            // connect to socket
            props.connectToLapsSocket();
            return function cleanup() {
                // disconnect from socket
                props.disconnectFromLapsSocket();
            }
        }
    }, [props.socket])

    let mainLaps: ParticipantLaps = null;
    let otherLaps: ParticipantLaps = null;
    let values: LapValue[] = [];
    let lapDiffs = [];
    let maxLapDiff = null;
    let minLapDiff = null;
    let maxCumul = null;
    let minCumul = null;
    let mainCategory = null;
    let otherCategory = null;

    if (props.mainEntry && props.otherEntry) {
        // useEffect(() => {
        mainLaps = props.lapsDetail.laps.find(l => l.participant.toString() == props.mainEntry.number);
        otherLaps = props.lapsDetail.laps.find(l => l.participant.toString() === props.otherEntry.number);
        mainCategory = props.categories.find(c => c.id == props.mainEntry.category);
        otherCategory = props.categories.find(c => c.id == props.otherEntry.category);

        if (mainLaps && otherLaps) {
            for (let i in mainLaps.laps) {
                let mainLap = mainLaps.laps[i];
                let otherLap = otherLaps.laps[i];

                if (mainLap && otherLap) {
                    let lapDiff = otherLap.time - mainLap.time;
                    let cumulativeDiff = (otherLap.time + otherLap.startTime) - (mainLap.time + mainLap.startTime);
                    if (null == maxLapDiff || lapDiff > maxLapDiff) {
                        maxLapDiff = lapDiff;
                    }
                    if (null == minLapDiff || lapDiff < minLapDiff) {
                        minLapDiff = lapDiff;
                    }
                    if (null == maxCumul || cumulativeDiff > maxCumul) {
                        maxCumul = cumulativeDiff;
                    }
                    if (null == minCumul || cumulativeDiff < minCumul) {
                        minCumul = cumulativeDiff;
                    }
                    let lapValue: LapValue = {
                        lapNumber: mainLap.lapNum,
                        lapDiff: lapDiff,
                        cumulativeDiff: cumulativeDiff,
                        mainLap: mainLap.time,
                        otherLap: otherLap.time
                    };
                    lapDiffs.push(lapDiff);
                    values.push(lapValue);
                }
            }
        }
        // }, [props.lapsDetail, props.mainEntry, props.otherEntry])
    }
    // on homogénéise les min/max :
    let maxLapDiffValue = Math.max(Math.abs(maxLapDiff), Math.abs(minLapDiff));
    let maxCumulValue = Math.max(Math.abs(maxCumul), Math.abs(minCumul));
    maxLapDiff = maxLapDiffValue;
    minLapDiff = -1 * maxLapDiffValue;
    maxCumul = maxCumulValue;
    minCumul = -1 * maxCumulValue;

    const Line = ({bars, xScale, yScale}) => {
        const lineGenerator = line()
            .x(bar => xScale(bar.data.indexValue) + bar.width / 2)
            .y(bar => {
                let cumul = bar.data.data.cumulativeDiff;
                cumul *= (cumul > 0) ? maxLapDiff / maxCumul : minLapDiff / minCumul
                return yScale(cumul)
            });

        return (
            <path
                d={lineGenerator(bars)}
                fill="none"
                stroke="blue"
                style={{pointerEvents: "none"}}
            />
        );
    };

    const getColor = bar => {
        let data = bar.data as LapValue;
        return data.lapDiff > 0 ? positive_color : negative_color;
    }
    const tooltip = (mainEntry: ReferentialEntryType, otherEntry: ReferentialEntryType, value: LapValue) => {

        return <div className="car-comp-tooltip">
            <div className="lap-number"><span>{t("lap", props.lang)}</span><span
                className="number">{value.lapNumber}</span></div>
            <div className="gaps">
                <div className="cars-row">
                    <div className="tooltip-row">
                        <span className="car-number"
                              style={{backgroundColor: mainCategory ? mainCategory.color : ''}}>#{mainEntry.number}</span>
                        <span>{displayLapTime(value.mainLap)}</span>
                    </div>
                    <div className="tooltip-row">
                        <span className="car-number"
                              style={{backgroundColor: otherCategory ? otherCategory.color : ''}}>#{otherEntry.number}</span>
                        <span>{displayLapTime(value.otherLap)}</span>
                    </div>
                </div>
                <div className="tooltip-row">
                    <span
                        style={{backgroundColor: value.lapDiff < 0 ? negative_color : positive_color}}>{t("lap_gap", props.lang)}</span>
                    <span
                        style={{color: value.lapDiff < 0 ? negative_color : positive_color}}>{displayLapTime(value.lapDiff)}</span>
                </div>
                <div className="tooltip-row">
                    <span
                        style={{backgroundColor: value.cumulativeDiff < 0 ? negative_color : positive_color}}>{t("cumulative_gap", props.lang)}</span>
                    <span
                        style={{color: value.cumulativeDiff < 0 ? negative_color : positive_color}}>{displayLapTime(value.cumulativeDiff)}</span>
                </div>
            </div>

        </div>
    }

    return (<div className="car-stats">
        <div className="row">
            <div className="col-12" style={{height: 700}}>
                <ResponsiveBar
                    data={values}
                    keys={["lapDiff"]}
                    indexBy="lapNumber"
                    margin={{
                        top: 10,
                        right: 100,
                        bottom: 36,
                        left: 100
                    }}
                    colors={getColor}
                    minValue={minLapDiff}
                    maxValue={maxLapDiff}
                    axisBottom={null}
                    enableLabel={false}
                    tooltip={({id, value, data}) => {
                        let lapVal = data as LapValue;
                        return tooltip(props.mainEntry, props.otherEntry, lapVal)
                    }}
                    layers={["grid", "axes", "bars", Line, "markers", "legends"]}
                    theme={{ axis: { ticks: { text: { fill: props.darkMode ? '#fff' : '#999' } } } }}

                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        format: (value: number) => displayLapTime(value)
                    }}
                    axisRight={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: 'middle',
                        format: (value: number) => {
                            // repasser à la valeur cumul
                            value /= (value > 0) ? maxLapDiff / maxCumul : minLapDiff / minCumul
                            return displayTime(value / 1000)
                        }
                    }}
                />
            </div>
        </div>
    </div>);
}


export default connect(storeToProps, storeDispatchToProps)(CarComparisonView);
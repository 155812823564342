import {ClassificationTypeEnum} from "../../../../../../../server/common/Enum/live/ClassificationTypeEnum";

export class ReferentialDriverType {
    id: number;
    city: string;
    country: string;
    country_id: string;
    facebook_url: string;
    firstname: string;
    lastname: string;
    picture_url: string;
    shortname: string;
    twitter_url: string;
    website_url: string;
    rank: string;
    small_picture_url: string;
    driver_portraits: ReferentialPortraitType[];
}

export class ReferentialPortraitType {
    portrait_url: string;
    original_portrait_url: string;
    small_portrait_url: string;
    legend: string;
    seasons: string[];
    championships: string[]
}

export class ReferentialTeamType {
    id: number;
    country: string;
    country_id: string;
    facebook_url: string;
    name: string;
    logo: string;
    twitter_url: string;
    website_url: string;
}


export class ReferentialBrandType {
    id: number;
    country: string;
    title: string;
}

export class ReferentialCarType {
    id: number;
    name: string;
}

export class ReferentialEntryType {
    id: number;
    brand: number;
    category: number;
    world_championship: boolean;
    drivers: Array<number>; // id referential ReferentialDriverType
    number: string;
    picture_url: string;
    team: number;
    tyres: string;
    tyres_picture: string;
    vehicle: number;
    custom_car_number_small_url: string;
    category_label: string;
    category_group: string;
}

export class ReferentialCategoryType {
    id: number;
    color: string; // hexadecimal color
    name: string;
    order: number;
}

export class ReferentialRaceType {
    id: number;
    name_fr: string;
    name_en: string;
    logo: string; // url
    points: Array<number>;
    start_date: string;
    status: number;
}

export class ReferentialSessionType {
    id: number;
    name_fr: string;
    name_en: string;
    type: string;
    type_id: number;
    chrono_type: string;
    start_date: string;
    duration: number;
    status: string;
    results: string;
    replay_url: string;
    alkamel_id: string;
}

class ReferentialType {
    entries: Array<ReferentialEntryType> = [];
    drivers: Array<ReferentialDriverType> = [];
    teams: Array<ReferentialTeamType> = [];
    categories: Array<ReferentialCategoryType> = [];
    sessions: Array<ReferentialSessionType> = [];
    race: ReferentialRaceType;
    races: Array<ReferentialRaceType> = [];
    brands: Array<ReferentialBrandType> = [];
    cars: Array<ReferentialCarType> = [];
    classifications: Array<ClassificationType> = [];
    poles: Array<number>;
}

export class ChampionshipType {
    id: number;
    alias: string;

    constructor(id: number, alias: string) {
        this.id = id;
        this.alias = alias;
    }
}

export class ClassificationType {
    type: ClassificationTypeEnum;
    titlefr: string;
    name: string;
    titleen: string;
    data: ClassificationDataType;
    id: number;
    ponderation: number;
}

export class ClassificationDataType {
    championship: ClassificationChampionshipType;
    classification: Array<ClassificationRowType>;
}

export class ClassificationChampionshipType {
    name: string;
    code: string;
    year: string;
    main_title: string;
    sessions: Array<ClassificationSessionType>;
}

export class ClassificationSessionType {
    sessionIndex: number;
    event_name: string;
    session_name: string;
}

export class ClassificationRowType {
    id: number;
    position: number;
    total_points: number;
    net_position: number;
    total_net_points: number;
    key: string;
    team: string;
    name: string;
    nat: string;
    ecm_driver_id: string;
    ecm_team_id: string;
    ecm_brand_id: string;
    ecm_category_id: string;
    points_by_session: Array<ClassificationSessionPointsType>;
}

export class ClassificationSessionPointsType {
    session_index: number;
    total_points: number;
    race_points: number;
    race_points_valid_for_net_points: boolean;
    fastest_lap_points: number;
    fastest_lap_points_valid_for_net_points: boolean;
    pole_points: number;
    pole_points_valid_for_net_points: boolean;
    extra_points_1: number;
    extra_points_1_valid_for_net_points: boolean;
    extra_points_2: number;
    extra_points_2_valid_for_net_points: boolean;
    penalty_points: number;
    penalty_points_valid_for_net_points: boolean;
    status: string;
    extra_participation_points: number;
    extra_participation_points_valid_for_net_points: boolean;
    extra_not_started_points: number;
    extra_not_started_points_valid_for_net_points: boolean;
    extra_not_classified_points: number;
    extra_not_classified_points_valid_for_net_points: boolean;
}

export class VideosType {
    fr: string;
    picture_fr: string;
    en: string;
    picture_en: string;
    channels: FreeChannelType[];
    radio_fr: string;
    radio_en: string;
    radio_fr_attr: string;
    radio_en_attr: string;
}



export class FreeChannelType {
    number: number;
    title: string;
    url: string;
    picture: string;
}

export class PredictiveRowType {
    old_position?: number;
    new_position?: number;
    number?: string;
    name: string;
    total: number;
    points: Array<PredictivePointsType>;
    category_label?: string;
}

export class PredictivePointsType {
    race: number;
    pole: number;
    predictive?: boolean = false;
}

export class DriverStints {
    driverId: number;
    participant: number;
    stints: Map<string, String> = new Map<string, String>();
    pitTime: number = 0;
    driveTime: number = 0;
}

export default ReferentialType;
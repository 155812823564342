import React, {Component, useEffect, useState} from 'react';
import BrandRace from "../fragment/BrandRace";
import LocaleSwitch from "../fragment/LocaleSwitch";
import Player from "../fragment/liveHeader/Player";
import BrandTimeSwitch from "../fragment/BrandTimeSwitch";
import {connect} from "react-redux";
import Login from "../fragment/Login";
import ResponsiveMenu from "./ResponsiveMenu";
import {ChampionshipType, ReferentialRaceType} from "../../type/ReferentialType";
import LiveReducerType from "../../type/LiveReducerType";
import ReducerType from "../../type/ReducerType";
import {isMobile, isMobileOnly} from "react-device-detect";
import {ReactSVG} from "react-svg";
import ActionLiveType from "../../reducer/live/ActionLiveType";
import ActionTunnel from "../../reducer/tunnel/ActionTunnel";
import {useCookies} from "react-cookie";
import {t} from "../../translation/translator";
import DarkSwitcher from "../fragment/DarkSwitch";

type Props = {
    account: string,
    home: string,
    liveHome: string,
    member?: any,
    championship: ChampionshipType,
    race: ReferentialRaceType,
    forLemans: boolean,
    noLogin: boolean,
    lemansUrl: string,
    lang: string,
    tunnelHidden: boolean,
    hasRacePack: boolean,
    darkMode: boolean,
    showTunnel: () => void,
    navigate: (view: string, lang: string, params: string) => void,
};
type State = { menuResponsiveOpen: boolean }

const storeToProps = (state: ReducerType) => ({
    forLemans: state.live.forLemans,
    noLogin: state.live.noLogin,
    lemansUrl: state.live.lemansUrl,
    lang: state.live.lang,
    hasRacePack: state.tunnel.hasCurrentRacePack,
    tunnelHidden: state.tunnel.tunnelHidden,
    darkMode: state.live.darkMode,
});

const storeDispatchToProps = (dispatch) => ({
    showTunnel: () => dispatch(ActionTunnel.toggleTunnel(false)),
    navigate: (view: string, lang: string, params: string = "") => dispatch(ActionLiveType.navigate(view, lang, params)),
});

export const LiveHeaderView = (props: Props) => {
    const [menuResponsiveOpen, setMenuResponsiveOpen] = useState(false);
    const [cookies, setCookie] = useCookies(['hideTunnel', 'sidebarCollapsed']);

    useEffect(()=>{
        window.addEventListener("resize", () => {
            setMenuResponsiveOpen(false);
        });
    },[])

    function onClickBurger(e) {
        e.preventDefault();
        setMenuResponsiveOpen(!menuResponsiveOpen);
    }

    function closeResponsive() {
        setMenuResponsiveOpen(false);
    }

    function _showTunnel() {
        setCookie('hideTunnel', 0);
        props.showTunnel();
        props.navigate("live", props.lang, "")
    }

    let url = props.liveHome;
    if (props.race)
        url += "?r=" + props.race.id;
    if (props.forLemans)
        url = props.lemansUrl;

    const rolexStyle = {
        width: "220px",
        height: "70px",
        border: 0,
        margin: 0,
        padding: 0,
        overflow: "hidden",
        scroll: 'none'
    };
    let iframeSrc =
        props.forLemans ?
            "https://static.rolex.com/clocks/2020/24h_wec_" + (props.lang ? props.lang.toUpperCase() : 'EN') + "_HTML_210x70/rolex.html" :
            "https://static.rolex.com/clocks/2019/fiawec_" + (props.lang ? props.lang.toLowerCase() : 'en') + "_HTML_218x66/rolex.html";
    let alias = props.championship.alias;
    if (props.forLemans) {
        alias = "lemans";
    }
    return (

        <div className={"live-header" + (props.darkMode ? " dark" : " light")}>
            <div className="live-nav">
                <a href="#" onClick={onClickBurger}
                   className={"js-burger " + ((menuResponsiveOpen) ? "menu-expanded" : "")}>
                    <div className="bar-wrapper">
                        <div className="bar"></div>
                    </div>
                </a>
                <ResponsiveMenu home={props.home} liveHome={props.liveHome}
                                open={menuResponsiveOpen}
                                closeResponsive={closeResponsive}/>

                <a className="home" href={url}>
                    <img src="/bundles/front/images/icons/Home_Icon.svg" alt="Login icon"/>
                </a>

                <div className="index-logo">
                    <a href={url} className="logo">
                        <img src={"/bundles/front/images/logo_" + alias + ".png"}
                             height="75" alt={alias}/>
                    </a>
                </div>
                {props.forLemans && props.tunnelHidden && !props.hasRacePack &&
                <div className="brand-race">
                    <a onClick={(e) => {
                        e.preventDefault();
                        _showTunnel();
                    }} href="#">
                            <span>
                                {t("premium_access", props.lang)}
                            </span>
                    </a>
                </div>
                }
                {!props.forLemans &&
                    <BrandRace liveHome={props.liveHome}/>
                }
                <Player/>
                {(props.forLemans || 1 === props.championship.id)
                    ?
                    <iframe src={iframeSrc}
                            style={rolexStyle}
                            scrolling={'no'} frameBorder={'no'}/>
                    :
                    <BrandTimeSwitch/>
                }
                <DarkSwitcher />
                <LocaleSwitch/>
                { !props.noLogin &&
                    <Login account={props.account} member={props.member}/>
                }

            </div>
        </div>
    )

}

const LiveHeader = connect(storeToProps, storeDispatchToProps)(LiveHeaderView);
export default LiveHeader;
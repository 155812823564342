import React, {Component} from 'react';
import LiveSideBar from "./LiveSideBar";
import LiveHeaderView from "./LiveHeader";

export const Layout = (props: any) => {
    return (<div>
        <LiveSideBar home={props.home} session={props.session}/>
        <div className="main-wrapper">
            <LiveHeaderView forLemans={props.forLemans} race={props.race}
                            championship={props.championship} home={props.home}
                            liveHome={props.liveHome} account={props.account}
                            member={props.member}/>
            {props.children}
        </div>
    </div>);
}

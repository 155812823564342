import Live from "../../../../../../../../server/common/Models/live/Live";
import ConstantLiveType from "./ConstantLiveType";
import ReferentialType, {
    ClassificationType,
    ReferentialRaceType,
    ReferentialSessionType,
    VideosType
} from "../../type/ReferentialType";
import {
    initResult,
    initSocialNetworks,
    updateChannelId,
    updateChannels,
    updateInfos,
    initExpertFromCookie,
    initDarkFromCookie,
    navigate
} from "./reducer";
import Params from "../../../../../../../../server/common/Models/live/Params";
import Entry from "../../../../../../../../server/common/Models/live/Entry";
import Socket = SocketIOClient.Socket;
import ChannelType from "../../type/ChannelType";
import FlagProgress from "../../../../../../../../server/common/Models/live/FlagProgress";
import SectorOrLap from "../../../../../../../../server/common/Models/alkamel/SectorOrLap";

export default class ActionLiveType {

    static readonly updateSocket = (socket:Socket) => ({type: ConstantLiveType.UPDATE_SOCKET, data: socket});
    static readonly updateSocialNetworks = (sn: []) => ({type: ConstantLiveType.UPDATE_SOCIAL_NETWORKS, data: sn});
    static readonly updateLive = (live: Live) => ({type: ConstantLiveType.UPDATE_LIVE, data: live});
    static readonly updateParams = (params: Params) => ({type: ConstantLiveType.UPDATE_PARAMS, data: params});
    static readonly updateFlags = (flags: FlagProgress[]) => ({type: ConstantLiveType.UPDATE_FLAGS, data: flags});
    static readonly updateEntries = (entries: Entry[]) => ({type: ConstantLiveType.UPDATE_ENTRIES, data: entries});
    static readonly updateBestSectors = (sectors: SectorOrLap[]) => ({type: ConstantLiveType.UPDATE_BEST_SECTORS, data: sectors});
    static readonly clearEntries = () => ({type: ConstantLiveType.CLEAR_ENTRIES});
    static readonly updateResults = (results: any) => ({type: ConstantLiveType.UPDATE_RESULTS, data: results});
    static readonly updateFlagVisible = (visible: boolean) => ({type: ConstantLiveType.UPDATE_FLAG_VISIBLE, data: visible});
    static readonly updateReferential = (ref: ReferentialType) => ({type: ConstantLiveType.UPDATE_REFERENTIAL, data: ref});
    static readonly updateClassifications = (ref: ClassificationType[]) => ({type: ConstantLiveType.UPDATE_CLASSIFICATIONS, data: ref});
    static readonly updateSessions = (sessions: ReferentialSessionType[]) => ({type: ConstantLiveType.UPDATE_SESSIONS, data: sessions});
    static readonly switchLang = (lang: string) => ({type: ConstantLiveType.SWITCH_LANG, data: lang});
    static readonly switchTime = (local: boolean) => ({type: ConstantLiveType.SWITCH_TIME, data: local});
    static readonly switchSession = (sessionId: number) => ({type: ConstantLiveType.SWITCH_SESSION, data: sessionId});
    static readonly connect = () => ({type: ConstantLiveType.CONNECT, data: true});
    static readonly disconnect = () => ({type: ConstantLiveType.DISCONNECT, data: false});
    static readonly setName = (name: string) => ({type: ConstantLiveType.SET_NAME, data: name});
    static readonly setChannels = (channels: ChannelType[]) => ({type: ConstantLiveType.SET_CHANNELS, data: channels});
    static readonly setChannel = (channel: string) => ({type: ConstantLiveType.SET_CHANNEL, data: channel});
    static readonly setChannelId = (id: number) => ({type: ConstantLiveType.SET_CHANNEL_ID, data: id});
    static readonly setVideos = (videos: VideosType) => ({type: ConstantLiveType.SET_VIDEOS, data: videos});
    static readonly updateChannelId = (id: number|null) => updateChannelId(id);
    static readonly updateChannels = (cb : () => void) => updateChannels(cb);
    static readonly updateInfos = (cb: (loggedIn: boolean) => void) => updateInfos(cb);
    static readonly initResult = (currentSession: ReferentialSessionType, race: ReferentialRaceType, lang: string) => initResult(currentSession, race, lang);
    static readonly initSocialNetworks = (url: string) => initSocialNetworks(url);
    static readonly updateSelectedMenu = (selected: string) => ({type: ConstantLiveType.UPDATE_SELECTED_MENU, data: selected});
    static readonly filterCategory = (currentCategory: number) => ({type: ConstantLiveType.FILTER_CATEGORY, data: currentCategory});
    static readonly toggleDark = (enable: boolean = null) => ({type: ConstantLiveType.TOGGLE_DARK, data: enable});
    static readonly toggleExpert = (enable: boolean = null) => ({type: ConstantLiveType.TOGGLE_EXPERT, data: enable});
    static readonly toggleBestSectorMode = (enable: boolean = null) => ({type: ConstantLiveType.TOGGLE_BESTSECTORMODE, data: enable});
    static readonly togglePredictive = (enable: boolean = null) => ({type: ConstantLiveType.TOGGLE_PREDICTIVE, data: enable});
    static readonly initExpertFromCookie =  () => initExpertFromCookie();
    static readonly navigate = (view:string, lang: string, params: string) => navigate(view, lang, params);
    static readonly updateShowModalRequestLogin = (show: boolean) => ({type: ConstantLiveType.UPDATE_SHOW_MODAL_REQUEST_LOGIN, data: show});
}
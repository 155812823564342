import {t} from "../../../translation/translator";
import React from "react";
import {ReferentialCategoryType} from "../../../type/ReferentialType";
import Entry from "../../../../../../../../../server/common/Models/live/Entry";

type Props = {
    lang: string;
    categories: Array<ReferentialCategoryType>;
    currentResult: Array<Entry>;
    darkMode: boolean;
}

export const ClassLeaders = (props: Props) => {

    function classLeaders() {
        return props.categories.map((category, index) => {
            // on recherche le 1er entry de cette categorie
            let first = props.currentResult.find((entry, index) => entry.categoryId === category.id);
            if (first) {
                return <tr key={category.id}>
                    <td className="align-middle category"
                        style={ props.darkMode ? {backgroundColor: category.color} :  {color: category.color}}>
                        <span>{category.name}</span></td>
                    <td className="pos numeric align-middle">{first.ranking}</td>
                    <td className="numeric align-middle">
                        {first.number}
                    </td>
                    <td className="status align-middle">
                        <span className={first.state.toLowerCase()}>
                            {first.state}
                        </span>
                    </td>
                    <td className="team">
                        <span className="team-name">{first.team}</span>
                        <span className="car-name">{first.car}</span>
                    </td>
                    <td className="driver align-middle">
                        <span>{first.driver}</span>
                    </td>
                </tr>
            }
            return null;
        });
    }

    return (
        <div className="table-race">

            <table className="table table-race table-striped class-leaders">
                <thead>
                <tr>
                    <th className="class">{t("class", props.lang)}</th>
                    <th className="position">{t("pos", props.lang)}</th>
                    <th className="number">#</th>
                    <th className="status">{t("state", props.lang)}</th>
                    <th className="align-left">{t("teamCar", props.lang)}</th>
                    <th className="align-left">{t("driver", props.lang)}</th>
                </tr>
                </thead>
                <tbody>
                {classLeaders()}
                </tbody>
            </table>
        </div>
    )
}
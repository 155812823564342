import ReducerType from "../../../type/ReducerType";
import React, {Ref, useEffect, useState} from "react";
import {connect} from "react-redux";
import {Question} from "akar-icons";
import ActionTunnel from "../../../reducer/tunnel/ActionTunnel";
import ActionLiveType from "../../../reducer/live/ActionLiveType";
import {t} from "../../../translation/translator";
import CarSelector from "../CarSelector";
import {ReferentialEntryType} from "../../../type/ReferentialType";
import {NavigationEnum} from "../../../enum/NavigationEnum";
import CarStats from "./CarStats";
import CarComparison from "./CarComparison";
import CarStatsMenu ,{CarStatsItems} from "./CarStatsMenu";
import {useLocation} from "react-router";

type Props = {
    title: string,
    help?: string,
    children: any
}

const storeToProps = (state: ReducerType) => ({
    entries: state.live.referential.entries,
    teams: state.live.referential.teams,

    lang: state.live.lang,
    member: state.tunnel.member,
    tunnelHidden: state.tunnel.tunnelHidden,
    noLogin: state.live.noLogin,
    darkMode: state.live.darkMode,
});

const storeDispatchToProps = (dispatch) => ({
    showTunnel: () => dispatch(ActionTunnel.toggleTunnel(false)),
    navigate: (view: string, lang: string, params: string = "") => dispatch(ActionLiveType.navigate(view, lang, params))
});

const getUrlParams = (mainEntry: ReferentialEntryType, otherEntry: ReferentialEntryType) => {
    let params = [];
    if (null !== mainEntry) {
        params.push("main=" + mainEntry.number);
    }
    if (null !== otherEntry) {
        params.push("other=" + otherEntry.number);
    }
    return params.join("&");
}

const CarComparisonContainerView = (props: Props & ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>) => {
    let entry: ReferentialEntryType = null;

    const location = useLocation();
    let query = new URLSearchParams(location.search);

    if (query.get("main")) {
        entry = props.entries.find(e => query.get("main") === e.number)
    }
    let other: ReferentialEntryType = null;
    if (query.get("other")) {
        other = props.entries.find(e => query.get("other") === e.number)
    }
    let teamName = "";
    let [mainEntry, setMainEntry] = useState(null);
    let [otherEntry, setOtherEntry] = useState(null);

    useEffect(() => {
        setMainEntry(entry);
        setOtherEntry(other);
    }, [props]);

    if (null === props.member && false === props.noLogin) {
        // pas d'accès, retour à la home
        if (true === props.tunnelHidden) {
            props.showTunnel();
        }
        props.navigate("live", props.lang);
        return null;
    }

    return (<div className={"stat-content car-stats-container " + (props.darkMode ? "dark" : "light")}>
        <div className="intro">
            <h1>{t('car_comparison', props.lang)}</h1>
            {/*{props.help &&*/}
            {/*<Question/>*/}
            {/*}*/}
            <div className="selectors">
                <CarSelector
                    className={!mainEntry ? "empty" : ''}
                    selected={mainEntry}
                    selectedCallback={(entry) => {
                        setMainEntry(entry);
                        props.navigate(NavigationEnum.CAR_COMPARISON, props.lang, getUrlParams(entry, otherEntry))
                    }}
                />
                <span>{t("vs", props.lang)}</span>
                <CarSelector
                    className={!otherEntry ? "empty" : ''}
                    selected={otherEntry}
                    selectedCallback={(entry) => {
                        setOtherEntry(entry);
                        props.navigate(NavigationEnum.CAR_COMPARISON, props.lang, getUrlParams(mainEntry, entry))
                    }}
                />
            </div>

        </div>
        <CarStatsMenu entry={mainEntry} lang={props.lang} currentItem={CarStatsItems.COMPARISON} />
        <div className="content">
            {!mainEntry || !otherEntry ?
                <div>
                    <div className="selector-back"></div>
                    <CarComparison />
                </div> :
                <CarComparison mainEntry={mainEntry} otherEntry={otherEntry}/>
            }
        </div>
    </div>)
}


export default connect(storeToProps, storeDispatchToProps)(CarComparisonContainerView);
import ReducerType from "../../../type/ReducerType";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import CategoryFilter from "../CategoryFilter";
import EntryCartouche from "../EntryCartouche";
import {t} from "../../../translation/translator";
import {displayTime} from "../../../reducer/live/reducer";
import {StintTypeEnum} from "../../../../../../../../../server/common/Models/alkamel/Enum/StintTypeEnum";
import ReactTooltip from "react-tooltip";
import Stint from "../../../../../../../../../server/common/Models/alkamel/Stint";
import {Toggle} from "../../Toggle";
import {StintTooltip} from "./StintTooltip";
import {StatefulToolTip} from "react-portal-tooltip"
import ActionStints from "../../../reducer/stints/ActionStints";

const storeToProps = (state: ReducerType) => ({
    duration: state.live.live.params.duration,
    elapsedTime: state.live.live.params.elapsedTime,
    start: state.live.live.params.startTime,
    stints: state.stints.byDriver,
    entries: state.live.referential.entries,
    currentCategory: state.live.currentCategory,
    categories: state.live.referential.categories,
    drivers: state.live.referential.drivers,
    lang: state.live.lang,
    result: state.live.live.entries,
    socket: state.live.socket
});


const storeDispatchToProps = (dispatch) => ({
    connectToStintsSocket: () => dispatch(ActionStints.connectToStintsSocket()),
    disconnectFromStintsSocket: () => dispatch(ActionStints.disconnectFromStintsSocket()),
});

type Props = ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>;

const DriverStintsView = (props: Props) => {


    useEffect(() => {
        if (null !== props.socket) {
            // connect to socket
            props.connectToStintsSocket();
            return function cleanup() {
                // disconnect from socket
                props.disconnectFromStintsSocket();
            }
        }
    }, [props.socket])


    let [descending, setDescending] = useState(true);
    let [fullwidth, setFullwidth] = useState(false);

    // calcul de l'échelle
    let duree = (props.elapsedTime > props.duration) ? props.duration : props.elapsedTime;
    let unit = duree / 7;
    let scaledUnit = unit; //Math.ceil(unit / 900) * 900;
    let times: number[] = [];
    let totalDuration = 0;
    for (let i = 1; i <= 7; i++) {
        let duration = i * scaledUnit;
        times.push(duration);
        totalDuration = duration;
    }
    // totalDuration est la durée qui correspond à une largeur de 100%
    // chaque stint aura une largeur en fonction de son pourcentage de la durée

    function getScale() {
        return (
            <div className="scale">
                {times.map((time) => {
                    return <div key={time} className="align-right">&nbsp;</div>
                })
                }
            </div>)
    }

    let stints = props.stints.sort((p1, p2) => descending ? p2.driveTime - p1.driveTime : p1.driveTime - p2.driveTime)

    function changeOrder(e) {
        setDescending(!descending)
    }

    function changeWidth(e) {
        setFullwidth(!fullwidth)
    }

    return <div className={"timeline-container " + (fullwidth ? " fullwidth" : "")}>
        <div className="timeline-filter">
            <CategoryFilter/>

            <div className="expert-mode">
                <Toggle checked={fullwidth} label="fullwidth" onChange={changeWidth} lang={props.lang}/>
                <Toggle checked={descending} label="descending" onChange={changeOrder} lang={props.lang}/>
            </div>
        </div>
        <div className="stats-timeline">
            <div className="stats-timeline-content">
                <div className="timeline-header">
                    <div className="name-header">{t("car", props.lang)}</div>
                    <div className="time-header">{t("total", props.lang)}</div>
                    <div className="axis-header">
                        {times.map((time, index) => {
                            return <div key={index} className="align-right">{displayTime(time)}</div>
                        })}
                    </div>
                </div>
                <div className="timeline-content">

                    {stints.map((driverStints, index) => {
                        let entry = props.entries.find(value => driverStints.participant.toString() === value.number);
                        let driver = props.drivers.find(value => driverStints.driverId === value.id);
                        if (undefined === driver || undefined === entry || (-2 !== props.currentCategory && -1 !== props.currentCategory && props.currentCategory !== entry.category)) {
                            return null;
                        }
                        // on cherche le resultat de l'entry :
                        let result = props.result[entry.number];
                        // if (undefined === result) {
                        //     return null;
                        // }
                        let category = props.categories.find((cat) => cat.id === entry.category)
                        let stints: Stint[] = [];
                        for (let start in driverStints.stints) {
                            if (driverStints.stints[start].type === StintTypeEnum.TRACK) {
                                stints.push(driverStints.stints[start])
                            }
                        }

                        return <div className="timeline-row" key={driver.id}>
                            <div className="name-cell driver-name-cell">
                                {driver.firstname} {driver.lastname} (#{entry.number})
                            </div>
                            <div className="time-cell numeric">{displayTime(driverStints.driveTime / 1000)}</div>
                            <div className="axis-cell">
                                <div className="bar">
                                    {stints.map((stint, index) => {
                                        const parent = <div
                                            className="inside">{t("stint", props.lang)} #{index + 1}</div>

                                        if (null === stint) {
                                            return null;
                                        }
                                        let classname = 'driver-time bar-part'
                                        // if (index % 2 === 1) {
                                        //     classname += ' back'
                                        // }
                                        let width = 100 * (stint.duration / 1000) / totalDuration;
                                        return (
                                            <div style={{
                                                width: width + '%',
                                                borderColor: category.color,
                                                backgroundColor: category.color + "66"
                                            }}
                                                 className={classname} key={index}>
                                                <StatefulToolTip parent={parent} position="bottom" arrow="center"
                                                                 className="stateful-button">
                                                    <StintTooltip stint={stint} lang={props.lang}
                                                                  sessionStart={props.start}
                                                                  currentResult={result}/>
                                                </StatefulToolTip>
                                            </div>
                                        )
                                    })}
                                </div>
                                {getScale()}

                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>

    </div>
}
export default connect(storeToProps, storeDispatchToProps)(DriverStintsView);
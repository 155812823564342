import React, {Component} from 'react';
import {connect} from "react-redux";
import KeynoteType from "../../type/KeynoteType";
import LiveReducerType from "../../type/LiveReducerType";
import {KeynoteCardView} from "./KeynoteCard";
import {t} from "../../translation/translator";
import moment = require("moment");
import ReducerType from "../../type/ReducerType";

declare var reactParameters;
declare var $;

type Props = { lang: string };
type State = { has_more: boolean, offset: number, error: boolean, isLoaded: boolean, isLoading: boolean, items: Array<KeynoteType>, page: number, intervalKey: NodeJS.Timeout }

const storeToProps = (state: ReducerType) => ({lang: state.live.lang});

export class LiveKeynoteView extends Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isLoading: false,
            items: [],
            page: 1,
            intervalKey: null,
            offset: 0,
            has_more: false
        };
    }

    fetchKeynotes() {
        if(!this.state.isLoading){
            this.setState({isLoading: true});
        }
        if(this.state.isLoaded){
            this.setState({isLoaded: false});
        }
        fetch(reactParameters.apiUrl + "/api/app_aco/keynote?site=2&locale=" + this.props.lang + "&order=DESC&length=15&page=" + this.state.page + "&offset=" + this.state.offset)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        isLoading: false,
                        items: this.state.items.concat(result.components),
                        page: this.state.page + 1,
                        has_more: result.has_more
                    });
                }, (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                    })
                }
            );
    }

    fetchNewKeynotes() {
        fetch(reactParameters.apiUrl + "/api/app_aco/keynote?site=2&locale=" + this.props.lang + "&order=DESC&length=15&page=1")
            .then(res => res.json())
            .then(
                (result) => {
                    let nb = 0;
                    let final_nb = 0;
                    if(result.components[0].id != this.state.items[0].id){
                        result.components.forEach(component => {
                            if (component.id == this.state.items[0].id) {
                                final_nb = nb;
                            } else {
                                nb++;
                            }
                        });
                    }
                    if(nb > 0) {
                        this.setState({
                            items: result.components.slice(0, final_nb).concat(this.state.items),
                            offset: this.state.offset + 1
                        });
                    }
                }
            );
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(this.props.lang != prevProps.lang){
            this.setState({
                items: [],
                page: 1
            });
        }
        if(typeof this.props.lang !== 'undefined' && !this.state.isLoading
            && (this.state.items.length === 0 || (prevState.items.length>0 && this.state.items[0].id != prevState.items[0].id))){
            this.fetchKeynotes();
        }
    }

    componentDidMount(): void {
        if(typeof this.props.lang !== 'undefined'){
            this.fetchKeynotes();
        }
        let interval = setInterval(() => {
            this.fetchNewKeynotes();
        }, 30000);
        this.setState({'intervalKey': interval});
    }

    componentWillUnmount(): void {
        clearInterval(this.state.intervalKey)
    }

    render() {
        const {error, isLoaded, items, isLoading, has_more} = this.state;

        return (
                <div className="keynote-cards-wrapper">
                    {error &&
                        <div className="status-message">Problem loading data</div>
                    }

                    {!error && items &&
                    items.map((item) =>
                        <KeynoteCardView key={"keynote-card_" + item.id} item={item}/>
                    )}

                    {has_more &&
                        <a className="load-more" onClick={() => this.fetchKeynotes()}>{t('seemore', this.props.lang)}</a>
                    }

                    {!isLoaded || isLoading &&
                        <div className="status-message"><i className="fa fa-circle-o-notch fa-spin fa-fw"/> Loading ...</div>
                    }
                </div>
        )
    }

}

const LiveKeynote = connect(storeToProps, null)(LiveKeynoteView);
export default LiveKeynote;
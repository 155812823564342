import LiveReducerType from "./LiveReducerType";
import TunnelReducerType from "./TunnelReducerType";
import StintsReducerType from "./StintsReducerType";
import LapsReducerType from "./LapsReducerType";
import RaceControlReducerType from "./RaceControlReducerType";

class ReducerType {

    constructor(liveReducer: LiveReducerType = null) {
        this.live = liveReducer ? liveReducer : new LiveReducerType();
        this.tunnel = new TunnelReducerType();
        this.stints = new StintsReducerType();
        this.laps = new LapsReducerType();
        this.race_control = new RaceControlReducerType();
    }

    live: LiveReducerType;
    tunnel: TunnelReducerType;
    stints: StintsReducerType;
    laps: LapsReducerType;
    race_control: RaceControlReducerType;

}
export default ReducerType;
export type GetReducerType = () => ReducerType;
import React, {Component} from 'react';
import LiveTableRow from "../fragment/liveTable/LiveTableRow";
import {connect} from "react-redux";
import Entry from "../../../../../../../../server/common/Models/live/Entry";
import {t} from "../../translation/translator";
import FlipMove from "react-flip-move";
import LiveReducerType from "../../type/LiveReducerType";
import LiveTableFilter from "../fragment/liveTable/LiveTableFilter";
import LiveTableHeader from "../fragment/liveTable/LiveTableHeader";
import {SessionTypeToChronoTypeEnum} from "../../../../../../../../server/common/Enum/live/SessionTypeEnum";
import {ReferentialDriverType, ReferentialEntryType} from "../../type/ReferentialType";
import LiveTablePopup from "../fragment/liveTable/LiveTablePopup";
import ReducerType from "../../type/ReducerType";
import ActionLiveType from "../../reducer/live/ActionLiveType";
import ActionTunnel from "../../reducer/tunnel/ActionTunnel";

const storeToProps = (state: ReducerType) => {
    let session = state.live.currentSession;
    let chronoType = null;
    if (session) {
        if (session.chrono_type) {
            chronoType = session.chrono_type;
        } else {
            // on "devine" depuis le type de session
            chronoType = SessionTypeToChronoTypeEnum(session.type_id);
        }
    }
    return {
        bestSectors: state.live.live.bestSectors,
        expert: state.live.expert,
        bestSectorMode: state.live.bestSectorMode,
        lang: state.live.lang,
        live: state.live.live,
        // entries: state.live.live.entries,
        // entriesStat: state.live.live.statEntries,
        categoriesRef: state.live.referential.categories,
        entriesRef: state.live.referential.entries,
        drivers: state.live.referential.drivers,
        chronoType: chronoType,
        member: state.tunnel.member,
        currentCategory: state.live.currentCategory,
        categories: state.live.referential.categories,
        darkMode: state.live.darkMode,
    }
};

const storeDispatchToProps = (dispatch) => ({
    toggleExpert: () => dispatch(ActionLiveType.toggleExpert()),
});
type Props = ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>;

type State = {
    flipActive: boolean,
    currentEntry: ReferentialEntryType | null,
    currentDriver: ReferentialDriverType | null,
    sorting: string,
    ascending: boolean
};

class LiveTableView extends Component<Props, State> {

    private tableRef: HTMLDivElement;

    constructor(props) {
        super(props);

        this.state = {
            flipActive: true,
            currentEntry: null,
            currentDriver: null,
            sorting: "ranking",
            ascending: true
        };
        this.onSelectCatFilter = this.onSelectCatFilter.bind(this);
    }

    onSelectCatFilter(catSelected: number) {
        // overall
        this.setState({flipActive: false});
        setTimeout(() => {
            this.setState({flipActive: true});
        }, 400);
    }

    selectEntry(entry: ReferentialEntryType | null, driver: ReferentialDriverType | null) {
        this.setState({currentEntry: entry, currentDriver: driver});
    }

    generateRows(listEntry, separateRows: boolean = false) {
        let rowCount = separateRows ? listEntry.length : null;
        let previousLapGap = null;
        return listEntry.map((entry: Entry, index: number) => {
            if (null === entry) {
                return null;
            }
            let entryRef = this.props.entriesRef.find(e => e.id == entry.id);
            let key = "#" + entry.number;
            let catRef = (entryRef) ? this.props.categoriesRef.find(c => c.id == entryRef.category) : null;
            let row = <LiveTableRow
                darkMode={this.props.darkMode}
                previousLapGap={previousLapGap}
                index={index}
                rowCount={rowCount}
                lang={this.props.lang}
                bestSectors={this.props.bestSectors}
                expert={this.props.expert}
                bestSectorMode={this.props.bestSectorMode}
                catFilter={this.props.currentCategory}
                entryRef={entryRef}
                key={key}
                categoryRef={catRef}
                driversList={this.props.drivers}
                chronoType={this.props.chronoType}
                selectCallback={this.selectEntry.bind(this)}
                sorting={this.state.sorting}
                {...entry}
            />
            previousLapGap =  (this.props.currentCategory == -1) ? entry.gapLaps : entry.classGapLaps;
            return row;
        });
    }

    changeSort(sort: string) {
        if (sort !== this.state.sorting) {
            this.setState({sorting: sort});
        } else {
            this.setState({sorting: "ranking"});
        }
    }

    sortEntry(listEntries: Entry[]) {
        return listEntries.sort((e1, e2) => {
            let e1value = 0;
            let e2value = 0;

            let sorting = this.state.sorting;
            if (false === this.props.bestSectorMode) {
                sorting = "ranking";
            }
            if (sorting === "ideallap") {
                e1value = e1.bestTimeSector1 + e1.bestTimeSector2 + e1.bestTimeSector3;
                e2value = e2.bestTimeSector1 + e2.bestTimeSector2 + e2.bestTimeSector3;
            } else {
                e1value = e1[sorting];
                e2value = e2[sorting];
            }

            // a part pour les pits, on remplace le 0 par la valeur max de int
            if (sorting != "pitstop") {
                if (!e1value) {
                    e1value = 99999999999;
                }
                if (!e2value) {
                    e2value = 99999999999;
                }
            }

            return this.state.ascending ? e1value - e2value : e2value - e1value;
        });
    }

    render() {

        // default :  get all entries, all categories and sort
        let listEntry = [this.props.live.entries.filter(e => null != e)];
        listEntry[0] = this.sortEntry(listEntry[0]);
        let separateRows = false;

        // filter one category
        if (this.props.currentCategory != undefined && this.props.currentCategory > -1) {
            listEntry[0] = listEntry[0].filter(e => {
                return e.categoryId == this.props.currentCategory;
            });
            listEntry[0] = this.sortEntry(listEntry[0]);
        } else if (this.props.currentCategory != undefined && this.props.currentCategory == -2) {
            // all categories but sort and display
            separateRows = true;
            listEntry = this.props.categories.map(c => {
                const l = listEntry[0].filter(e => e.categoryId == c.id);
                return this.sortEntry(l);
            });
        }

        return (
            <div className="part-table">
                <LiveTableFilter/>
                <div className="table-content table-race" ref={ref => this.tableRef = ref}>
                    <table className="table table-striped" style={{position: 'relative'}}>
                        <LiveTableHeader onSort={this.changeSort.bind(this)} sorting={this.state.sorting}/>

                        {this.state.flipActive &&
                            <FlipMove
                                duration={400}
                                easing="cubic-bezier(0.25,0.1,0.25,1.0)"
                                className={"tbody"}
                                typeName="tbody"
                                staggerDelayBy="20"
                                staggerDurationBy="15"
                                enterAnimation="accordionVertical"
                                maintainContainerHeight={true}
                                leaveAnimation="none"
                            >
                                {listEntry.map(l => this.generateRows(l, separateRows))}
                            </FlipMove>
                        }
                        {!this.state.flipActive &&
                            <tbody>
                            {listEntry.map(l => this.generateRows(l, separateRows))}
                            </tbody>
                        }
                    </table>
                    {this.state.currentEntry &&
                        <LiveTablePopup
                            lang={this.props.lang}
                            entryRef={this.state.currentEntry}
                            closeCallback={() => this.selectEntry(null, null)}
                        />
                    }
                </div>
            </div>
        )
    }
}

const LiveTable = connect(storeToProps, storeDispatchToProps)(LiveTableView);
export default LiveTable;
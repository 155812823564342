import ReducerType from "../../../type/ReducerType";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Question} from "akar-icons";
import ActionTunnel from "../../../reducer/tunnel/ActionTunnel";
import ActionLiveType from "../../../reducer/live/ActionLiveType";
import {t} from "../../../translation/translator";
import CarSelector from "../CarSelector";
import {ReferentialEntryType} from "../../../type/ReferentialType";
import {NavigationEnum} from "../../../enum/NavigationEnum";
import CarStats from "./CarStats";
import CarStatsMenu, {CarStatsItems} from "./CarStatsMenu";
import {useLocation} from "react-router";

type Props = {
    title: string,
    help?: string,
    children: any
}

const storeToProps = (state: ReducerType) => ({
    entries: state.live.referential.entries,
    teams: state.live.referential.teams,

    lang: state.live.lang,
    member: state.tunnel.member,
    tunnelHidden: state.tunnel.tunnelHidden,
    noLogin: state.live.noLogin,
    darkMode: state.live.darkMode,
});

const storeDispatchToProps = (dispatch) => ({
    showTunnel: () => dispatch(ActionTunnel.toggleTunnel(false)),
    navigate: (view: string, lang: string, params: string = "") => dispatch(ActionLiveType.navigate(view, lang, params))
});

const CarStatsContainerView = (props: Props & ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>) => {
    let entry: ReferentialEntryType = null;
    let location = useLocation();
    let query = new URLSearchParams(location.search);

    if (query.get("number")) {
        entry = props.entries.find(e => query.get('number') === e.number)
    }
    let [selected, setSelected] = useState(null);

    useEffect(() => {
        setSelected(entry);
    }, [props]);

    if (null === props.member && false === props.noLogin) {
        // pas d'accès, retour à la home
        if (true === props.tunnelHidden) {
            props.showTunnel();
        }
        props.navigate("live", props.lang);
        return null;
    }
    return (<div className={"stat-content car-stats-container " + (props.darkMode ? 'dark' : 'light')}>
        <div className="intro">
            <h1>{t('live_carstats', props.lang)}</h1>
            {/*{props.help &&*/}
            {/*<Question/>*/}
            {/*}*/}
            <CarSelector
                className={!entry ? "empty" : ''}
                selected={selected}
                selectedCallback={(entry) => {
                    setSelected(entry);
                    props.navigate(NavigationEnum.CAR_STATS,props.lang,"number=" + entry.number)
                }}
            />
        </div>
        <CarStatsMenu entry={selected} lang={props.lang} currentItem={CarStatsItems.STATS} />
        <div className="content">
            {!selected ?
                <div>
                    <div className="selector-back"></div>
                    <CarStats />
                </div> :
                <CarStats entry={selected} />
            }
        </div>
    </div>)
}


export default connect(storeToProps, storeDispatchToProps)(CarStatsContainerView);
import {createBrowserHistory} from "history";
import {applyMiddleware, compose, createStore} from "redux";
import createRootReducer from "./reducers";
import {routerMiddleware} from "connected-react-router";
import thunk from "redux-thunk";
import LiveReducerType from "../type/LiveReducerType";
import ReducerType from "../type/ReducerType";

export const history = createBrowserHistory();

function getInitialState(sessionId, championship, configUrl, forLemans, lemansUrl, streamingUrl, forcer, noLogin) {
    let initialLiveState = new LiveReducerType(sessionId, championship, configUrl, forLemans, lemansUrl, streamingUrl, forcer, noLogin);
    return new ReducerType(initialLiveState);
}

export function configureStore(sessionId, championship, configUrl, forLemans, lemansUrl, streamingUrl, forcer, noLogin) {
    let initialState = getInitialState(sessionId, championship, configUrl, forLemans, lemansUrl, streamingUrl, forcer, noLogin);

    return createStore(
        createRootReducer(history),
        initialState,
        compose(
            applyMiddleware(
                routerMiddleware(history),
                thunk)
        ));
}
import React from "react";
import {t} from "../../translation/translator";
import ReactModal from 'react-modal';
import {connect} from "react-redux";
import LiveReducerType from "../../type/LiveReducerType";
import ReducerType from "../../type/ReducerType";

const storeToProps = (state: ReducerType) => ({
    lang: state.live.lang,
    streamingUrl: state.live.streamingUrl,
    name: state.live.name,
    darkMode: state.live.darkMode,
});
type Props = { lang: string, streamingUrl: string, name: string };

function MyModalView(props){
    const [modalIsOpen,setIsOpen] = React.useState(false);

    const customStyles = {
        content : {
            maxWidth              : '50%',
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)'
        }
    };

    function openModal() {
        setIsOpen(true);
    }

    function closeModal(){
        setIsOpen(false);
    }

    ReactModal.setAppElement('#livePage');
    let url = props.streamingUrl;
    if ('fr' === props.lang) {
        url = url.replace('/en', '/fr');
    }
    return (
        <div>
            {!props.name
                ?
                    <a onClick={openModal}>
                        <span>{t("premium_access", props.lang)}</span>
                    </a>
                :
                    <a>
                        <span>{props.name}</span>
                    </a>
            }
            <ReactModal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                bodyOpenClassName={"ReactModal__Body--open" + (props.darkMode ? 'dark' : 'light')}
                overlayClassName={"ReactModal__Overlay dark" + (props.darkMode ? 'dark' : 'light')}
            >
                <h2>{t("premium_access", props.lang)}</h2>
                <p dangerouslySetInnerHTML={{__html:t("premium_modal_content", props.lang) }}/>
                <a href={url} target={"_blank"}><span className={"modal-btn"}>Continuer</span></a>
            </ReactModal>
        </div>
    )
}

const MyModal = connect(storeToProps, null)(MyModalView);
export default MyModal;
import {JsonObject, JsonProperty} from "json2typescript";

@JsonObject("sector")
export default class SectorOrLap {
    flag: string;
    isValid: boolean;
    number: number;
    time: number;
    timeStr: string;
    dayTime: number;
    participant: string;
}
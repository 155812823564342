import React, {useState} from 'react';
import {connect} from "react-redux";
import WeatherEntry from "../fragment/WeatherEntry";
import Sharing from "../fragment/Sharing";
import RaceFlag from "../fragment/RaceFlag";
import {t} from "../../translation/translator";
import RaceControlPage from "../fragment/RaceControl/RaceControlPage";
import {NavigationEnum} from "../../enum/NavigationEnum";
import ActionTunnel from "../../reducer/tunnel/ActionTunnel";
import ActionLiveType from "../../reducer/live/ActionLiveType";
import ReducerType from "../../type/ReducerType";
import {Button} from "../fragment/Button";
import {TextAlignJustified} from "akar-icons";


const storeToProps = (state: ReducerType) => {
    return {
        lang: state.live.lang,
        member: state.tunnel.member,
        noLogin: state.live.noLogin,
    }
};
const storeDispatchToProps = (dispatch) => ({
    showTunnel: () => dispatch(ActionTunnel.toggleTunnel(false)),
    navigate: (view: string, lang: string, params: string = "") => dispatch(ActionLiveType.navigate(view, lang, params)),
    showModalRequestLogin: () => dispatch(ActionLiveType.updateShowModalRequestLogin(true))
});
type Props = ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>;

export const CourseInfoShareView = (props: Props) => {
    let [weather, setWeather] = useState(false);

    function goToRaceControl() {
        if (null === props.member && false === props.noLogin) {
            props.showModalRequestLogin();
            props.showTunnel();
        } else {
            props.navigate(NavigationEnum.RACE_CONTROL, props.lang)
        }
    }

    return (
        <div className="fcy">
            <RaceFlag class="top" checkVisibility mobileMode={false}/>
            <div className="bottom">
                <div className="toggles">
                    <button className={!weather ? "active" : "" } onClick={() => setWeather(false)}>{t("race_control", props.lang)}</button>
                    <button className={weather ? "active" : "" }  onClick={() => setWeather(true)}>{t("weather", props.lang)}</button>
                </div>

                {weather &&
                    <WeatherEntry/>
                }
                {!weather &&
                    <>
                        <RaceControlPage nbMax={4}/>
                        <div className="justify-content-center d-flex mt-1">
                            <Button onClick={() => goToRaceControl()}>
                                <TextAlignJustified/>
                                <span className="label"> {t('see_all',props.lang)}</span></Button>
                        </div>
                    </>
                }
            </div>

            <div className="social-sharing">
                <div> {t("share_live", props.lang).toUpperCase()}</div>
                <div className="icons">
                    <Sharing lang={props.lang}/>
                </div>
            </div>
        </div>
    )
}


export const CourseInfoShare = connect(null, storeDispatchToProps)(CourseInfoShareView);

import React from "react";
import {t} from "../../translation/translator";
import ReactModal from 'react-modal';
import {connect} from "react-redux";
import LiveReducerType from "../../type/LiveReducerType";
import ActionLiveType from "../../reducer/live/ActionLiveType";
import ReducerType from "../../type/ReducerType";
import {ReferentialCategoryType} from "../../type/ReferentialType";

declare var reactParameters;
const storeToProps = (state: ReducerType) => ({
    lang: state.live.lang,
    listCategories: state.live.referential.categories,
    channels: state.live.channels,
    videos: state.live.videos,
    entries: state.live.live.entries,
    channelId: state.live.channelId,
    championship: state.live.championship.alias,
    darkMode: state.live.darkMode,
});

const storeDispatchToProps = (dispatch) => ({
    updateChannelId: (id: number) => dispatch(ActionLiveType.updateChannelId(id)),
});

function VideoSwitcherView(props){
    const [modalIsOpen,setIsOpen] = React.useState(false);
    const [catSelected,setCat] = React.useState("all");

    const customStyles = {
        content : {
            maxWidth              : '70%',
            minWidth              : '70%',
            maxHeight             : '70%',
            overflowY             : 'scroll',
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)'
        },
        overlay : {
            zIndex                : 2000

        }
    };

    function openModal() {
        setIsOpen(true);
    }

    function closeModal(){
        setIsOpen(false);
    }

    function selectChannel(index){
        props.updateChannelId(index);
        closeModal();
    }

    function nextChannel(){
        if(props.channelId < props.channels.length - 1){
            props.updateChannelId(props.channelId + 1);
        } else {
            props.updateChannelId(0);
        }
    }

    function previousChannel(){
        if(props.channelId > 0){
            props.updateChannelId(props.channelId - 1);
        } else {
            props.updateChannelId(props.channels.length - 1);
        }
    }

    function clickCategory(e, cat){
        e.preventDefault();
        setCat(cat);
    }

    function checkCurrent(category){
        return category == catSelected ? ' current' : '';
    }

    ReactModal.setAppElement('#livePage');

    return (
        <>
            <i className="fa fa-angle-up fa-3x" aria-hidden="true" onClick={nextChannel}/>
            <i className="fa fa-th fa-2x" aria-hidden="true" onClick={openModal}/>
            <i className="fa fa-angle-down fa-3x" aria-hidden="true" onClick={previousChannel}/>
            <ReactModal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Video Switcher"
                overlayClassName={"ReactModal__Overlay " + (props.darkMode ? 'dark' : 'light')}
            >
                <h2>ALL LIVE</h2>

                <div className="table-header table-header-fixed video-filter">
                    {/* default cat */}
                    <a
                        key={-1}
                        href="#"
                        onClick={(e) => clickCategory(e, "all")}
                        className={"overall " + checkCurrent("all")}
                    >
                        <div/>
                        {t("overall", props.lang)}
                    </a>
                    {/* cat for this race */}
                    {props.listCategories.map((cat, index) => {
                        return <a
                            key={cat.id}
                            href="#"
                            onClick={(e) => clickCategory(e, cat.name)}
                            className={checkCurrent(cat.name)}
                            style={{color: cat.color}}
                        >
                            <div style={{backgroundColor: cat.color}}/>
                            {cat.name.toUpperCase()}
                        </a>
                    })}
                </div>
                <div className={'row'}>
                    {props.channels && props.channels.map((channel, index) => {
                        let entry = props.entries.find(e => null != e && e.number == channel.entrynumber);
                        let driver = entry ? entry.driver : "";
                        let team = entry ? entry.team : "";
                        let categoryId = entry ? entry.categoryId : -1;
                        let category:ReferentialCategoryType = null;
                        if (categoryId>0) {
                            category = props.listCategories.find((c) => c.id === categoryId);
                        }
                        let number = entry ? '#'+entry.number+'(POS. '+entry.ranking+')' : "";
                        if(channel.default_en){
                            number = 'Live EN';
                        } else if(channel.default_fr){
                            number = 'Live FR';
                        }

                        // pas de picture : default
                        let picture = (null ==   channel.picture) ? "/bundles/front/images/logo_" + props.championship + ".png" : channel.picture;

                        if(catSelected == "all" || (null != category && category.name.replace(/ /g, '') == catSelected.replace(/ /g, ''))) {
                            return <div className={'col-md-3 video-miniature cat-' + categoryId} key={index}>
                                <span className={'video-miniature-pos'}>{number}</span>

                                <img id={"miniature-" + index} src={picture} onClick={() => selectChannel(index)}/>
                                <span className={'video-miniature-name'}>{driver + (category ? ' - (' + category.name + ')' : '')}</span>
                                <span className={'video-miniature-team'}>{team}</span>
                                {/*<span className={'video-miniature-cat'}>{category}</span>*/}
                            </div>
                        }
                    })}
                </div>
            </ReactModal>
        </>
    )
}

const VideoSwitcher = connect(storeToProps, storeDispatchToProps)(VideoSwitcherView);
export default VideoSwitcher;
import {Pack} from "./live-api-generate";
export interface Member {
    id?: number;
    name?: string;
    first_name?: string;
    firstName?: string;
    lastName?: string;
    picture?: string;
    profileImg?: string;
}


class TunnelReducerType {

    constructor() {
        this.packs = [];
        this.memberPacks = [];
    }

    member?: Member = null;
    packs: Pack[] = [];
    memberPacks: Pack[] = [];
    hasCurrentRacePack: boolean = false;
    displayIdentification: boolean = false;
    tunnelHidden: boolean = false;
    buying: boolean = false;
    currentPack: Pack = null;

}
export default TunnelReducerType;
import lang from "./lang";
import moment from "moment";

let currentLang = '';
let pathArray = window.location.pathname.split('/');
for (let elem of pathArray) {
    switch (elem) {
        case 'fr':
            currentLang = 'fr';
            break;
        case 'en':
            currentLang = 'en';
            break;
    }
}
export const t = (key: string, locale: string) => {
    let v = lang[key];
    if (v && v[locale])
        return v[locale];
    return key;
};

export const getLabel = (data: any, field: string, locale: string) => {
    if (!data)
        return "";
    return data[field + "_" + locale];
}

export const getDate = (date: string, locale: string) => {
    moment.locale(currentLang);
    let day = moment.utc(date);
    let format = (locale=="fr") ? "D MMMM - kk:mm" : "MMMM, D - hh:mma";
    return day.format(format);
}

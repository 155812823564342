import ReducerType from "../../../type/ReducerType";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {t} from "../../../translation/translator";
import {ReferentialDriverType, ReferentialEntryType} from "../../../type/ReferentialType";
import ActionStints from "../../../reducer/stints/ActionStints";
import ActionLaps from "../../../reducer/laps/ActionLaps";
import {displayLapTime, displayTime} from "../../../reducer/live/reducer";
import Stint, {DisplayStint} from "../../../../../../../../../server/common/Models/alkamel/Stint";
import {StintTypeEnum} from "../../../../../../../../../server/common/Models/alkamel/Enum/StintTypeEnum";
import LapAnalysis from "../../../../../../../../../server/common/Models/alkamel/LapAnalysis";
import {ResponsivePie} from '@nivo/pie'
import ChartData from "../../../type/ChartData";
import ParticipantLaps from "../../../../../../../../../server/common/Models/alkamel/ParticipantLaps";
import Entry from "../../../../../../../../../server/common/Models/live/Entry";

type Props = {
    entry: ReferentialEntryType
}

type screenData = {
    drivers: any;
}

const storeToProps = (state: ReducerType) => ({
    entries: state.live.referential.entries,
    drivers: state.live.referential.drivers,
    lang: state.live.lang,
    stints: state.stints,
    lapsDetail: state.laps,
    socket: state.live.socket,
    result: state.live.live.entries,
    sessionStart: state.live.live.params.startTime,
    bestTimesByCategory: state.live.live.bestTimesByCategory,
    darkMode: state.live.darkMode,
});

const storeDispatchToProps = (dispatch) => ({
    connectToStintsSocket: () => dispatch(ActionStints.connectToStintsSocket()),
    disconnectFromStintsSocket: () => dispatch(ActionStints.disconnectFromStintsSocket()),
    connectToLapsSocket: () => dispatch(ActionLaps.connectToLapsSocket()),
    disconnectFromLapsSocket: () => dispatch(ActionLaps.disconnectFromLapsSocket()),
});

const findBestTime = (laps: ParticipantLaps, firstLap: number, lastLap: number) => {
    let best = 0;
    if (!laps) {
        return best;
    }
    let lap: LapAnalysis = null;
    for (let lapNumber = firstLap; lapNumber <= lastLap; lapNumber++) {
        if (laps.laps[lapNumber]) {
            lap = laps.laps[lapNumber];
            if (lap.isValid && (0 === best || best > lap.time)) {
                best = lap.time;
            }
        }
    }
    return best;
}

const CarStatsView = (props: Props & ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>) => {

    useEffect(() => {
        if (null !== props.socket) {
            // connect to socket
            props.connectToStintsSocket();
            props.connectToLapsSocket();
            return function cleanup() {
                // disconnect from socket
                props.disconnectFromStintsSocket();
                props.disconnectFromLapsSocket();
            }
        }
    }, [props.socket])

    let drivers: ReferentialDriverType[] = [];
    if (props.entry) {
        for (let index in props.entry.drivers) {
            let driverId = props.entry.drivers[index];
            let driver = props.drivers.find(d => driverId == d.id);
            if (driver) {
                drivers.push(driver);
            }
        }
    }
    let carLaps = null;
    if (props.entry) {
        carLaps = props.lapsDetail.laps.find(l => props.entry.number === l.participant.toString());
    }
    // stats des pits
    let stints: DisplayStint[] = [];
    let pits = 0;
    let pitTime = 0;
    let longestStint = 0;
    let shortestStint = 0;
    let entryStints = null;

    let result: Entry = null;
    let carBestLap = 0;
    let categBestLap = 0;
    let carResult = null;
    if (props.result && props.entry) {
        // recherche par number
        carResult = props.result.find(e =>  null !== e && e.number.toString() == props.entry.number)
        if (carResult) {
            carBestLap = carResult.bestlapTime;
        }
    }
    if (props.bestTimesByCategory && props.entry) {
        categBestLap = props.bestTimesByCategory[props.entry.category];
    }
    if (props.entry) {
        entryStints = props.stints.byEntry.find(e => e.participant.toString() == props.entry.number);
    }
    if (entryStints) {
        for (let start in entryStints.stints) {
            let stint: Stint = entryStints.stints[start];
            if (stint) {
                if (stint.type == StintTypeEnum.PIT) {
                    pits++;
                } else {

                    // plus long/court ?
                    let lastLap = stint.closeLapNumber;
                    if (!stint.closeLapNumber) {
                        // relais en cours : récupérer le tour courant
                        if (result) {
                            lastLap = result.lap;
                        }
                    }
                    let laps = (1 + lastLap - stint.openLapNumber);
                    if (longestStint < laps) {
                        longestStint = laps;
                    }
                    // pour le plus court, on ignore si relais courant
                    if (stint.closeLapNumber && (0 === shortestStint || shortestStint > laps)) {
                        shortestStint = laps;
                    }

                    // ligne pour l'affichage
                    let displayStint = stint as DisplayStint;

                    let driver = drivers[stint.driver - 1];
                    if (driver) {
                        displayStint.driverName = driver.lastname + " " + driver.firstname;
                    }

                    // recherche du meilleur tour
                    displayStint.bestLap = findBestTime(carLaps, stint.openLapNumber, lastLap);

                    stints.push(displayStint);

                }
            }
        }
        pitTime = entryStints.pitTime / 1000;
    }
    let chartData: ChartData[] = [];
    let totalTime = 0;

    for (let i in drivers) {
        let driver = drivers[i];
        let driverStints = props.stints.byDriver.find(d => d.driverId == driver.id);
        let data: ChartData = {
            id: driver.id.toString(),
            label: driver.lastname + " " + driver.firstname,
            value: 0,
            color: 'red',
            angle: 0
        };
        if (driverStints) {
            data.value = driverStints.driveTime;
            totalTime += data.value;
        }
        chartData.push(data);
    }
    let totalPct = 0;
    for (let i in chartData) {

        let pct = chartData[i].value / totalTime;
        totalPct += pct;
        if (totalPct == 99)
            pct++;
        if (totalPct == 101)
            pct--;
        chartData[i].value = pct;
        chartData[i].angle = pct * 360;
        chartData[i].id = chartData[i].label;
        chartData[i].label += " (" + Math.round(100 * pct) + "%)";
    }
    const margin = {top: 0, right: 200, bottom: 0, left: 10};

    return (<div className="car-stats">
        <h3>{t('driver_totals', props.lang)}</h3>
        <div className="row">
            <div className="col-lg-6 col-xl-9">
                {/* Driver totals */}
                <table className="table table-striped table-race">
                    <thead>
                    <tr>
                        <th className="header" style={{width: "33%"}}>{t("driver", props.lang)}</th>
                        <th>{t("stints", props.lang)}</th>
                        <th>{t("laps", props.lang)}</th>
                        <th>{t("duration", props.lang)}</th>
                        <th>{t("bestlap", props.lang)}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {drivers.map((driver, index) => {
                        let driverStints = props.stints.byDriver.find(d => d.driverId == driver.id)
                        let laps = 0;
                        let best = 0;
                        let driveTime = 0;
                        let nbStints = 0;
                        if (driverStints) {
                            driveTime = driverStints.driveTime / 1000;
                            for (let i in driverStints.stints) {
                                let stint: Stint = driverStints.stints[i];
                                if (stint.type === StintTypeEnum.TRACK) {
                                    nbStints++;
                                    let lastLap = stint.closeLapNumber;
                                    if (!stint.closeLapNumber) {
                                        // relais en cours : récupérer le tour courant
                                        if (carResult) {
                                            lastLap = carResult.lap;
                                        }
                                    }
                                    laps += (1 + lastLap - stint.openLapNumber)
                                    if (carLaps) {
                                        // parcours des tours du relais
                                        let lap: LapAnalysis = null;
                                        for (let lapNumber = stint.openLapNumber; lapNumber <= lastLap; lapNumber++) {

                                            if (carLaps.laps[lapNumber]) {
                                                lap = carLaps.laps[lapNumber];
                                                if (lap.isValid && (0 === best || best > lap.time)) {
                                                    best = lap.time;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        let bestClassname = "";
                        if (best === categBestLap) {
                            bestClassname = "best-overall";
                        } else if (best != 0 && best <= carBestLap) {
                            bestClassname = "best-car";
                        }
                        return <tr key={index}>
                            <td className="header">{driver.lastname} {driver.firstname}</td>
                            <td>{nbStints}</td>
                            <td>{laps}</td>
                            <td className="numeric align-middle">{displayTime(driveTime)}</td>
                            <td className={"numeric align-middle " + bestClassname}>{displayLapTime(best)}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
            <div className="col-lg-6 col-xl-3">

                <table className="table table-race">
                    <thead>
                    <tr>
                        <th>{t("driver_time_share", props.lang)}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{height: 105}}>

                            <ResponsivePie
                                margin={margin}
                                data={chartData}
                                valueFormat=" >-.0%"
                                innerRadius={0}
                                padAngle={0.7}
                                cornerRadius={0}
                                colors={{scheme: 'pastel2'}}
                                enableRadialLabels={false}
                                enableSliceLabels={false}
                                theme={{ legends: { text: { fill: props.darkMode ? '#fff' : '#999' } } }}
                                legends={[
                                    {
                                        anchor: 'right',
                                        direction: 'column',
                                        justify: false,
                                        itemsSpacing: 0,
                                        itemWidth: 100,
                                        itemHeight: 28,
                                        itemTextColor: props.darkMode ? '#fff' : '#999',
                                        textColor: props.darkMode ? '#fff' : '#999',
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 1,
                                        symbolSize: 18,
                                        symbolShape: 'circle',
                                        translateX: 140,
                                        translateY: 0
                                    }
                                ]}
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <h3>{t('stints', props.lang)}</h3>

        <div className="row">

            <div className="col-lg-9">
                {/* Car stints */}
                <table className="table table-striped table-race">
                    <thead>
                    <tr>
                        <th className="header" style={{width: "33%"}}>{t("driver", props.lang)}</th>
                        <th>{t("start_lap", props.lang)}</th>
                        <th>{t("start_time", props.lang)}</th>
                        <th>{t("end_lap", props.lang)}</th>
                        <th>{t("end_time", props.lang)}</th>
                        <th>{t("laps", props.lang)}</th>
                        <th>{t("duration", props.lang)}</th>
                        <th>{t("bestlap", props.lang)}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {stints.map((stint, index) => {
                        let bestClassname = "";
                        if (stint.bestLap === categBestLap) {
                            bestClassname = "best-overall";
                        } else if (stint.bestLap === carBestLap) {
                            bestClassname = "best-car";
                        }
                            return <tr key={index}>
                                <td className="header">{stint.driverName}</td>
                                <td className="numeric">{stint.openLapNumber}</td>
                                <td className="numeric">{displayTime((stint.startTime - props.sessionStart) / 1000)}</td>
                                <td className="numeric">{stint.closeLapNumber && stint.closeLapNumber}</td>
                                <td className="numeric">{stint.closeLapNumber && displayTime((stint.finishTime - props.sessionStart) / 1000)}</td>
                                <td className="numeric">{stint.closeLapNumber && (1 + stint.closeLapNumber - stint.openLapNumber)}</td>
                                <td className="numeric">{stint.duration && displayTime(stint.duration / 1000)}</td>
                                <td className={"numeric " + bestClassname}>{stint.bestLap && displayLapTime(stint.bestLap)}</td>
                            </tr>
                        }
                    )}
                    </tbody>
                </table>
            </div>
            <div className="col-lg-3">
                {/* Pit stats */}
                <table className="table table-race pit-stats">
                    <thead>
                    <tr>
                        <th colSpan={2}>{t("pit_stats", props.lang)}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="header">{t("pit_stops", props.lang)}</td>
                        <td className="value">{pits}</td>
                    </tr>
                    <tr>
                        <td className="header">{t("total_pit_time", props.lang)}</td>
                        <td className="value">{displayTime(pitTime)}</td>
                    </tr>
                    <tr>
                        <td className="header">{t("longest_stint", props.lang)}</td>
                        <td className="value">{longestStint}</td>
                    </tr>
                    <tr>
                        <td className="header">{t("shortest_stint", props.lang)}</td>
                        <td className="value">{shortestStint}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>


    </div>)
}


export default connect(storeToProps, storeDispatchToProps)(CarStatsView);
import {ReferentialEntryType} from "../../../type/ReferentialType";
import React, {useState} from "react";
import {Button} from "../Button";
import {t} from "../../../translation/translator";
import ActionLiveType from "../../../reducer/live/ActionLiveType";
import {connect} from "react-redux";
import {NavigationEnum} from "../../../enum/NavigationEnum";


export enum CarStatsItems {
    STATS = "stats",
    COMPARISON = "comparison"
}

type Props = {
    entry: ReferentialEntryType;
    lang: string;
    currentItem: CarStatsItems
}
const storeDispatchToProps = (dispatch) => ({
    navigate: (view: string, lang: string, params: string = "") => dispatch(ActionLiveType.navigate(view, lang, params))
});
const CarStatsMenuView = (props: Props & ReturnType<typeof storeDispatchToProps>) => {

    return <div className="car-stats-menu">
        <Button onClick={() => {
            if (props.currentItem != CarStatsItems.STATS) {
                props.navigate(NavigationEnum.CAR_STATS, props.lang, props.entry ? "number=" + props.entry.number : null);
            }
        }} current={props.currentItem === CarStatsItems.STATS} secondary={true}>
            <span className="label">{t("stats", props.lang)}</span>
        </Button>
        <Button onClick={() => {
            if (props.currentItem != CarStatsItems.COMPARISON) {
                props.navigate(NavigationEnum.CAR_COMPARISON, props.lang, props.entry ? "main=" + props.entry.number : null);
            }
        }} current={props.currentItem === CarStatsItems.COMPARISON} secondary={true}>
            <span className="label">{t("comparison", props.lang)}</span>
        </Button>
    </div>
}
export default connect(null, storeDispatchToProps)(CarStatsMenuView);
import React from 'react';
import {t} from "../../translation/translator";
import {ReactSVG} from "react-svg";
import {NavLink} from "react-router-dom";
import ReducerType from "../../type/ReducerType";
import ActionTunnel from "../../reducer/tunnel/ActionTunnel";
import {connect} from "react-redux";
import {NavigationEnum} from "../../enum/NavigationEnum";
import ActionLiveType from "../../reducer/live/ActionLiveType";

type Props = {
    page: NavigationEnum,
    svg: string,
    title: string,
    free: boolean,
    onClick: () => void
}
const storeToProps = (state: ReducerType) => (
    {
        championship: state.live.championship,
        lang: state.live.lang,
        member: state.tunnel.member,
        noLogin: state.live.noLogin,
        tunnelHidden: state.tunnel.tunnelHidden
    });

const storeDispatchToProps = (dispatch) => ({
    showTunnel: () => dispatch(ActionTunnel.toggleTunnel(false)),
    showModalRequestLogin: () => dispatch(ActionLiveType.updateShowModalRequestLogin(true))
});

declare var reactParameters;

const NavItemView = (props: Props & ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>) => {
    let baseUrl = ( true === window.location.href.includes("app_dev.php")) ? "/app_dev.php/" : "/"
    const params = new URLSearchParams(window.location.search);
    const port = params.get('port');
    let url = baseUrl + props.lang + "/" + props.page;
    if (port) {
        url += '?port=' + port;
    }

    function noNavigation(e) {
        //alert(t("login_to_access", props.lang));
        props.showModalRequestLogin();
        if (true === props.tunnelHidden) {
            props.showTunnel();
        }
    }

    let content = <span>
                        <div>
                            <ReactSVG
                                src={"/bundles/front/svg/" + props.svg + ".svg"}/>
                        </div>
                        <div className="title">
                            {t(props.title, props.lang)}
                        </div>
                    </span>

    if (null !== props.member || props.free || true === props.noLogin) {
        // navigation
        return <NavLink className="sidebar-item" href={url}
                        to={url} onClick={() => {
            if (props.onClick) {
                props.onClick();
            }
        }}>
            {content}
        </NavLink>
    } else {
        // affichage du tunnel + alerte
        return <a onClick={noNavigation} className="sidebar-item">
            {content}
        </a>
    }
}
const NavItem = connect(storeToProps, storeDispatchToProps)(NavItemView);
export default NavItem;
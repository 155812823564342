import React, {Component} from 'react';
import {connect} from "react-redux";
import LiveReducerType from "../../type/LiveReducerType";
import NewsCardView from "./NewsCard";
import NewsType from "../../type/NewsType";
import {t} from "../../translation/translator";
import ReducerType from "../../type/ReducerType";

declare var reactParameters;

type Props = { lang: string };
type State = { error: boolean, isLoaded: boolean, items: Array<NewsType>}

const storeToProps = (state: ReducerType) => ({lang: state.live.lang});

export class LiveNewsView extends Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
        };
    }

    fetchNews() {
        fetch(reactParameters.apiUrl + "/api/app_aco/news?site=2&locale=" + this.props.lang)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                }, (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                    })
                }
            );
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(this.props.lang != prevProps.lang){
            this.setState({items: []});
        }
        if(this.props.lang != null && this.state.items.length == 0){
            this.fetchNews();
        }
    }

    componentDidMount(): void {
        if(this.props.lang != null){
            this.fetchNews();
        }
    }

    render() {
        const {error, isLoaded, items} = this.state;

        return (

            <div className="news-cards-wrapper">
                <h2 className="h2">{t("latest_news", this.props.lang)}</h2>
                {error &&
                <div className="status-message">Problem loading data</div>
                }
                {!isLoaded &&
                <div className="status-message"><i className="fa fa-circle-o-notch fa-spin fa-fw"/> Loading ...
                </div>
                }
                {isLoaded && !error && items &&

                items.map((item) =>
                    <NewsCardView key={item.id} item={item}/>
                )}
            </div>
        )
    }

}

const LiveNews = connect(storeToProps, null)(LiveNewsView);
export default LiveNews;
import { actionTypeEnum } from './actionTypeEnum';
import {Member} from "../../type/live-api-generate";
import {getMember,initTunnelDisplayFromCookie, logout} from "./reducer";

/**
 * Définit les actions possibles qu'offre le reducer Tunnel
 */
const ActionTunnel = {
    /**
     * Remplit les données du member
     * @param data
     */
    setMember: (data: Member) => {return {type: actionTypeEnum.setMember, data}},
    displayIdentification: (data: boolean) => {return {type: actionTypeEnum.displayIdentification, data}},
    setHasRacePack: (data: boolean) => {return {type: actionTypeEnum.setHasRacePack, data}},

    toggleTunnel: (hidden: boolean) => {return {type:actionTypeEnum.toggleTunnel, data: hidden}},

    /**
     * Appelle l'API pour récupérer le membre connecté
     * @param cbSuccess
     */
    getMember: (cbSuccess: () => void = null) => getMember(cbSuccess),

    logout: () => logout(),
    initTunnelDisplayFromCookie: () => initTunnelDisplayFromCookie()
};
export default ActionTunnel;
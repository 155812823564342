import React, {Component} from 'react';
import {connect} from "react-redux";
import ActionLiveType from "../reducer/live/ActionLiveType";

const storeDispatchToProps = (dispatch) => ({
    switchLang: (lang: string) => dispatch(ActionLiveType.switchLang(lang))
});

class Translator extends Component<ReturnType<typeof storeDispatchToProps>> {

    constructor(props, state) {
        super(props, state);
        this.state = {
            lang : "fr"
        };
    }

    componentDidMount(): void {
        let pathArray = window.location.pathname.split('/');
        for (let elem of pathArray) {
            switch (elem) {
                case 'fr':
                    this.props.switchLang('fr');
                    break;
                case 'en':
                    this.props.switchLang('en');
                    break;
            }
        }
    }
    render() {
        return <div></div>;
    }
}

//connect (function to access props, function to access function)(class to access)
const Translate = connect(null, storeDispatchToProps)(Translator);
export default Translate;
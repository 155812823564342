import ConstantLiveType from "./ConstantLiveType";
import LiveReducerType from "../../type/LiveReducerType";
import ActionLiveType from "./ActionLiveType";
import ActionTunnel from "../tunnel/ActionTunnel";
import Entry from "../../../../../../../../server/common/Models/live/Entry";
import Live from "../../../../../../../../server/common/Models/live/Live";
import ReducerType, {GetReducerType} from "../../type/ReducerType";
import {ReferentialDriverType, ReferentialRaceType, ReferentialSessionType} from "../../type/ReferentialType";
import {Cookies} from "react-cookie";
import {FlagsEnum} from "../../../../../../../../server/common/Enum/live/FlagsEnum";
import {t} from "../../translation/translator";
import {push} from "connected-react-router";
import {NavigationEnum} from "../../enum/NavigationEnum";
import ChannelType from "../../type/ChannelType";
import SectorOrLap from "../../../../../../../../server/common/Models/alkamel/SectorOrLap";

declare var reactParameters;

const initialState = {};

function liveReducer(state: LiveReducerType, action: { type: string, data: any }) {

    if (undefined == state) {
        state = new LiveReducerType();
    }
    let newState = {...state};

    switch (action.type) {
        case ConstantLiveType.TOGGLE_DARK:
            newState.darkMode = action.data;
            break;
        case ConstantLiveType.TOGGLE_EXPERT:
            newState.expert = action.data;
            break;
        case ConstantLiveType.TOGGLE_BESTSECTORMODE:
            newState.bestSectorMode = action.data;
            break;
        case ConstantLiveType.TOGGLE_PREDICTIVE:
            newState.predictive = action.data;
            break;
        case ConstantLiveType.UPDATE_LIVE:
            newState.live = action.data;
            break;
        case ConstantLiveType.UPDATE_SOCKET:
            newState.socket = action.data;
            break;
        case ConstantLiveType.UPDATE_PARAMS:
            newState.live.params = action.data;

            // update favicon + title
            switch (action.data.raceState) {
                case "red":
                case "green":
                case "yellow":
                case "full_yellow":
                case "safety_car":
                case "Chk":
                    change_favicon("/bundles/front/images/favicon_" + action.data.raceState + ".png");
                    break;
                default:
                    change_favicon("/favicon.png");
                    break;
            }

            if (newState.currentSession) {
                let lang = newState.lang ? newState.lang : "fr";
                let session =
                    (newState.live.params && newState.live.params.sessionName) ? newState.live.params.sessionName : newState.currentSession["name_" + lang];
//                this.setTitle(this.props.currentSession["name_" + lang] + " - " + this.props.race["name_" + lang]);
                setTitle(session + " - " + newState.referential.race["name_" + lang]);
            }
            break;


        case ConstantLiveType.UPDATE_ENTRIES:
            let newLive = {...newState.live};
            let entry: Entry;
            for (let index in action.data) {
                entry = action.data[index];
                if (null != entry) {
                    let number = entry.number;
                    // recherche dans les lignes existantes
                    let previous = newLive.entries.findIndex(e => null != e && e.number == number);
                    if (0 <= previous) {
                        newLive.entries[previous] = entry;
                    } else {
                        newLive.entries.push(entry);
                    }
                }
            }

            // calcul des meilleurs temps par categorie :
            let bestByCateg = newLive.bestTimesByCategory;
            if (!bestByCateg) {
                bestByCateg = [];
            }
            for (let index in newLive.entries) {
                entry = newLive.entries[index];
                if (null != entry) {
                    let categId = entry.categoryId;
                    let bestTime = entry.bestlapTime;
                    if (bestTime > 0 && (!bestByCateg[categId] || bestByCateg[categId] > bestTime)) {
                        bestByCateg[categId] = bestTime
                    }
                }
            }
            newLive.bestTimesByCategory = bestByCateg;
            newState.live = newLive;
            break;

        case ConstantLiveType.CLEAR_ENTRIES:
            newState.live.entries = [];
            break;

        case ConstantLiveType.UPDATE_FLAGS:
            newState.live.progressFlagState = action.data
            break;

        case ConstantLiveType.UPDATE_BEST_SECTORS:
            let newLive2 = {...newState.live};
            let bestSector: SectorOrLap;
            for (let index in action.data) {
                bestSector = action.data[index];
                newLive2.bestSectors[index] = bestSector;
            }
            newState.live = newLive2;
            break;

        case ConstantLiveType.UPDATE_FLAG_VISIBLE:
            newState.flagVisible = action.data;
            break;

        case ConstantLiveType.UPDATE_REFERENTIAL:
            newState.referential = action.data;
            newState.currentSession = newState.referential.sessions.find(s => s.id == newState.currentSessionId);
            break;

        case ConstantLiveType.UPDATE_CLASSIFICATIONS:
            newState.referential.classifications = action.data;
            break;

        case ConstantLiveType.SWITCH_TIME:
            newState.localTime = action.data;
            break;

        case ConstantLiveType.SWITCH_LANG:
            newState.lang = action.data;
            break;

        case ConstantLiveType.UPDATE_SOCIAL_NETWORKS:
            newState.socialNetworks = action.data;
            break;

        case ConstantLiveType.CONNECT:
            newState.connected = action.data;
            break;

        case ConstantLiveType.DISCONNECT:
            newState.connected = action.data;
            break;

        case ConstantLiveType.SWITCH_SESSION:
            newState.currentSessionId = action.data;
            newState.currentSession = newState.referential.sessions.find(s => s.id == action.data);
            break;

        case ConstantLiveType.SET_NAME:
            newState.name = action.data;
            break;

        case ConstantLiveType.SET_CHANNELS:
            newState.channels = action.data;
            break;

        case ConstantLiveType.SET_VIDEOS:
            newState.videos = action.data;
            break;

        case ConstantLiveType.SET_CHANNEL:
            newState.channelUrl = action.data;
            break;

        case ConstantLiveType.SET_CHANNEL_ID:
            newState.channelId = action.data;
            break;

        case ConstantLiveType.FILTER_CATEGORY:
            newState.currentCategory = action.data;
            break;

        case ConstantLiveType.UPDATE_SELECTED_MENU:
            newState.selectedMenu = action.data;
            (window as any).gtag('event', 'page_view', {
                page_location: window.location.href,
                page_path: '/' + action.data,
                page_title: action.data + " - " + document.title
            });
            break;

        case ConstantLiveType.UPDATE_SHOW_MODAL_REQUEST_LOGIN:
            newState.showModalRequestLogin = action.data;
            break;

        default:
            return state;
    }
    return newState;
}

export function inIframe(): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    //return true;
    try {
        return (window.self !== window.top || urlParams.get("iframeMode") == "1");
    } catch (e) {
        return true;
    }
}

export default liveReducer;

export function updateChannelId(id: number | null) {

    return async function (dispatch: Function, getState: () => ReducerType) {
        let channels = getState().live.channels;
        let lang = getState().live.lang;
        let channel: ChannelType = null;
        // si id = null : channel par défaut (fr ou en)
        if (null === id) {
            channel = channels.find(ch => {
                if ("fr" === lang)
                    return ch.default_fr;
                else
                    return ch.default_en;
            });
        } else {
            if (!channels[id]) {
                return;
            }
            channel = channels[id];
        }
        if (null === channel) {
            return;
        }
        if (!channel.attr) {
            // video libre :
            dispatch(ActionLiveType.setChannel(channel.url + "?autoplay=1"));

        } else {
            // demande du token
            let membersUrl = reactParameters.fiawecUrl + "/fr/channel/token?attr=" + channel.attr + "&t=" + Math.random();

            let token = await (await fetch(membersUrl)).text();
            dispatch(ActionLiveType.setChannel(channel.url + '?token=' + token + '&autoplay=1'));

        }
        dispatch(ActionLiveType.setChannelId(id));
    }
}

export function updateChannels(cb: () => void = null) {

    return async function (dispatch: Function) {
        let data = getCookie();
        let membersUrl = reactParameters.membersUrl + "/en/check?m=" + data.id + "&t=" + data.token;

        let json = await (await fetch(membersUrl)).json();
        if (json['channel_url']) {
            let channels: ChannelType[] = await (await fetch(json['channel_url'])).json();
            // ajout de l'URL wec aux thumbs
            channels = channels.map((chan, type) => {
                chan.picture = reactParameters.fiawecUrl + chan.picture;
                return chan;
            })
            dispatch(ActionLiveType.setChannels(channels));
            dispatch(ActionLiveType.connect());

            // sauvegarde du cookie 'rp' pour init l'affichage de la page sans attendre le retour du WS

            const cookies = new Cookies();
            cookies.set("rp", 1);
            dispatch(ActionTunnel.setHasRacePack(true));
            if (cb) {
                cb();
            }
        } else {
            const cookies = new Cookies();
            cookies.set("rp", 0);
        }
    }
}

export function updateInfos(cb: (loggedIn: boolean) => void) {

    return async function (dispatch: Function, getState: () => LiveReducerType) {
        let data = getCookie();
        if (data.id) {
            // si on a le cookie, on set tout de suite le member pour éviter que le message de connexion s'affiche en premier
            dispatch(ActionTunnel.setMember(data));
            if (null !== cb) {
                cb(true);
            }

            // on regarde si on a un cookie RacePack pour init la prop aussi
            let racePackSaved = getBooleanCookie("rp");
            if (true === racePackSaved) {
                dispatch(ActionTunnel.setHasRacePack(true));
            }
        }

        // get depuis members.fiawec pour wec/lemans, et sur live pour elms/lmc (qui va taper sur l'api elms/lmc)
        let baseUrl = ( true === window.location.href.includes("app_dev.php")) ? "/app_dev.php/" : "/"

        let membersUrl = reactParameters.elms ?
            baseUrl + 'fr/member/infos' :
            reactParameters.membersUrl + "/en/infos?m=" + data.id + "&t=" + data.token;

        const json = await (await fetch(membersUrl)).json();
        console.log(membersUrl,json);

        if (json["firstName"] && json["lastName"]) {
            json['name'] = json["firstname"] + " " + json["lastname"];
        }

        if (json['name']) {
            dispatch(ActionLiveType.setName(json['name']));
            dispatch(ActionTunnel.setMember(json));
        }
    }
}

function getCookie() {
    const cookies = new Cookies();
    let cookie = cookies.get("_wa");
    if (cookie) {
        return JSON.parse(atob(decodeURIComponent(cookie).replace('/=/', '')))
    } else {
        return {
            id: 0,
            token: 0
        }
    }
}


export function initExpertFromCookie() {
    return async function (dispatch: Function, getState: GetReducerType) {
        let data = getBooleanCookie("expert");
        if (true === data) {
            dispatch(ActionLiveType.toggleExpert(true));
            let data = getBooleanCookie("bestsectormode");
            if (true === data) {
                dispatch(ActionLiveType.toggleBestSectorMode(true));
            }
        }
    }
}

export function initDarkFromCookie() {
    return async function (dispatch: Function, getState: GetReducerType) {
        let data = getBooleanCookie("dark");
        if (true === data) {
            dispatch(ActionLiveType.toggleDark(true));
        }
    }
}

export function initBestSectorsFromCookie() {
    return async function (dispatch: Function, getState: GetReducerType) {
        let data = getBooleanCookie("bestsectormode");
        alert(data);
        if (true === data) {
            dispatch(ActionLiveType.toggleBestSectorMode(true));
        }
    }
}

function getBooleanCookie(name: string): boolean {
    const cookies = new Cookies();
    let cookie = cookies.get(name);
    return "1" === cookie;
}


export function initResult(currentSession: ReferentialSessionType, race: ReferentialRaceType, lang: string) {

    return async function (dispatch: Function, getState: GetReducerType) {
        // on récupère le résultat de la session :
        var request = new Request(reactParameters.httpUrl + 'results/' + currentSession.id.toString());
        fetch(request)
            .then((response) => {

                response.json().then((results) => {
                    if (results.message == "No data") {
                        // recup depuis le json
                        var req = new Request(reactParameters.httpUrl + "results/save/" + currentSession.alkamel_id);
                        fetch(req)
                            .then((response) => {
                                response.json().then((results) => {
                                        let live = transformSavedJson(results)
                                        dispatch(ActionLiveType.updateLive(live));
                                    }
                                )
                            });
                    } else {
                        dispatch(initFromResults(results, currentSession, race, lang));
                    }
                })
            });
    }
}

export function initFromResults(results, currentSession: ReferentialSessionType, race: ReferentialRaceType, lang: string) {
    return async function (dispatch: Function, getState: GetReducerType) {
        let live = transformResults(results)
        dispatch(ActionLiveType.updateLive(live));
        if (currentSession && race) {
            //setTitle(currentSession["name_" + lang] + " - " + race["name_" + lang]);
        }
        change_favicon("/bundles/front/images/favicon_Chk.png");
    }
}

export function change_favicon(img) {
    var favicon = document.querySelector('link[rel="shortcut icon"]');

    if (!favicon) {
        favicon = document.createElement('link');
        favicon.setAttribute('rel', 'shortcut icon');
        var head = document.querySelector('head');
        head.appendChild(favicon);
    }

    favicon.setAttribute('type', 'image/png');
    favicon.setAttribute('href', img);
}

export function setTitle(title) {
    var tag = document.querySelector('title');
    tag.innerText = title;
}

/**
 * Crée un objet Live pour affichage des résultats
 * @param results
 */
function transformResults(results) {
    // on crée un objet live
    let live = new Live();
    live.params.replay = true;
    live.entries = [];

    let categPositions = [];
    results.classification.forEach((r, i) => {
        let e = new Entry();
        e.category = r.class;
        if (!categPositions[e.category])
            categPositions[e.category] = 1;
        else
            categPositions[e.category]++;
        e.ranking = r.position;
        e.positionChange = 0;
        e.categoryPosition = categPositions[e.category];
        e.categoryRanking = categPositions[e.category];
        e.number = r.number;
        e.id = r.ecm_participant_id;
        e.categoryId = r.ecm_category_id;
        e.team = r.team;
        e.tyre = r.tires;
        e.driver = "";
        r.drivers.forEach((d, i) => {
            if (e.driver != "")
                e.driver += ", ";
            e.driver += d.surname + ' ' + d.firstname.substring(0, 1);
        })

        e.car = r.vehicle;
        e.lap = r.laps;
        e.gap = r.gap_first;
        e.gapPrev = r.gap_previous;

        e.classGap = r.gap_first;
        e.classGapPrev = r.gap_previous;
        e.lastlap = '';
        e.pitstop = r.pit_stops;
        e.bestLap = r.time ? r.time : r.fastest_lap_time;
        e.speed = r.kph;
        e.state = "Chk";
        e.nationality = '';
        live.entries.push(e);
    });
    return live;
}

/**
 * Transforme un json sauvegardé pour affichage du live
 * @param json
 */
function transformSavedJson(json) {
    // on crée un objet live
    let live = new Live();
    live.params = json.classements.params;
    live.params.raceState = json.classements.params.etatcourse;
    live.params.replay = true;
    live.entries = [];

    let categPositions = [];
    json.classements.line.forEach((r, i) => {
        let e = new Entry();
        e.category = r.cat;
        if (!categPositions[e.category])
            categPositions[e.category] = 1;
        else
            categPositions[e.category]++;
        e.ranking = r.pos;
        e.positionChange = 0;
        e.categoryPositionChange = 0;
        e.categoryPosition = categPositions[e.category];
        e.categoryRanking = categPositions[e.category];
        e.number = r.num;
        e.id = r.ecmParticipantId;
        e.team = r.team;
        e.tyre = r.tyre;
        e.driver = r.driver;
        e.car = r.car;
        e.lap = r.lap;
        e.gap = r.gap;
        e.gapPrev = r.gapPrev;

        e.classGap = r.classGap;
        e.classGapPrev = r.classGapPrev;
        e.lastlap = r.lastlap;
        e.pitstop = r.pitstop;
        e.bestLap = r.bestlap;
        e.speed = r.speed;
        e.state = r.now;
        e.nationality = r.nat;
        live.entries.push(e);
    });
    return live;
}

export function initSocialNetworks(socialNetworkUrl: string) {
    return async function (dispatch: Function, getState: GetReducerType) {

        // let sn = JSON.parse("{\"twitter\":\"https:\\/\\/twitter.com\\/EuropeanLMS\",\"facebook\":\"https:\\/\\/www.facebook.com\\/EuropeanLMS\\/\",\"instagram\":\"https:\\/\\/www.instagram.com\\/elms_official\\/\",\"youtube\":\"https:\\/\\/www.youtube.com\\/channel\\/UCU8C8RqGhqH4nhk4mlN1Vwg\",\"linkedin\":\"https:\\/\\/linkedin.com\\/company\\/european-le-mans-series\"}");
        // dispatch(ActionLiveType.updateSocialNetworks(sn));

        var request = new Request(socialNetworkUrl);
        fetch(request)
            .then((response) => {
                response.json().then((sn) => {
                    dispatch(ActionLiveType.updateSocialNetworks(sn));
                })
            });
    }
}

export function navigate(view: string, lang: string, params: string) {
    return async function (dispatch: Function, getState: GetReducerType) {
        let baseUrl = ( true === window.location.href.includes("app_dev.php")) ? "/app_dev.php/" : "/"
        let path = baseUrl + lang + "/" + view;
        dispatch(push({pathname: path, search: '?' + params}));
    }
}

export function getFlagColor(state: FlagsEnum) {
    let flagStateToColor = {
        [FlagsEnum.GREEN]: "green",
        [FlagsEnum.RED]: "red",
        [FlagsEnum.SAFETY]: "white",
        [FlagsEnum.YELLOW]: "yellow",
        [FlagsEnum.FULL_YELLOW]: "yellow",
        [FlagsEnum.CHECK]: "chk"
    };
    return flagStateToColor[state];
}

export function getFlagText(state: FlagsEnum, lang: string) {
    let flagStateToText = {
        [FlagsEnum.GREEN]: t("green_flag", lang),
        [FlagsEnum.RED]: t("red_flag", lang),
        [FlagsEnum.SAFETY]: t("safety_car_flag", lang),
        [FlagsEnum.YELLOW]: t("yellow_flag", lang),
        [FlagsEnum.FULL_YELLOW]: t("full_yellow_flag", lang),
        [FlagsEnum.CHECK]: ""
    };
    return flagStateToText[state];

}

export function displayTime(seconds: number) {
    if (isNaN(seconds)) {
        return "";
    }
    let result = "";

    if (seconds < 0) {
        seconds *= -1;
        result = "-";
    }
    let hours = Math.floor(seconds / 3600);
    seconds = seconds - hours * 3600;
    let minutes = Math.floor(seconds / 60);
    seconds = Math.floor(seconds - minutes * 60);

    return result + hours.toLocaleString('en-US', {minimumIntegerDigits: 2}) + " : " +
        minutes.toLocaleString('en-US', {minimumIntegerDigits: 2}) + " : " +
        seconds.toLocaleString('en-US', {minimumIntegerDigits: 2});
}


export function displayLapTime(milliseconds: number) {
    if (isNaN(milliseconds)) {
        return "";
    }

    let result = "";
    if (milliseconds < 0) {
        milliseconds *= -1;
        result = "-";
    }
    let minutes = Math.floor(milliseconds / 60000);
    milliseconds = Math.floor(milliseconds - minutes * 60000);
    let seconds = Math.floor(milliseconds / 1000);
    milliseconds = Math.floor(milliseconds - seconds * 1000);

    return result + minutes.toLocaleString('en-US', {minimumIntegerDigits: 1}) + " : " +
        seconds.toLocaleString('en-US', {minimumIntegerDigits: 2}) + " . " +
        milliseconds.toLocaleString('en-US', {minimumIntegerDigits: 3});
}

/**
 * Renvoie la photo d'un pilote à afficher : selon le championnat, l'année
 *
 * @param driver
 */
export function getDriverPicture(driver: ReferentialDriverType, championshipAlias: string, year: number) {
    if ("lmc" === championshipAlias) {
        championshipAlias = 'Michelin Le Mans Cup';
    }
    let picture = driver.driver_portraits.find(portrait => {
        return !!portrait.seasons.find(s => s === year.toString()) &&
            !!portrait.championships.find(c => c.toUpperCase() === championshipAlias.toUpperCase())
    })
    if (picture) {
        return picture.portrait_url;
    }

    if ("alms" === championshipAlias) {
        return null;
    }

    // pas trouvé : on renvoie le portrait courant :
    return driver.picture_url;
}
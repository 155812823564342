import Params from "../../../../../../../../../server/common/Models/live/Params";
import {getFlagColor, getFlagText} from "../../../reducer/live/reducer";
import {FlagsEnum} from "../../../../../../../../../server/common/Enum/live/FlagsEnum";
import React from "react";
import {FlagCheckerBoard} from "../RaceFlag";


type Props = {
    state: FlagsEnum,
    lang: string
}

export const FlagCell = (props: Props) => {
    let colorFlag = getFlagColor(props.state);

    // construct class
    let className = "";

    if (colorFlag == "chk") {
        className += " race-over";
    } else {
        className += " " + colorFlag + " race-state";
    }

    return <div className={className}>
        {colorFlag == "chk" ?
            <FlagCheckerBoard/> :
            <>
                {getFlagText(props.state, props.lang)}
            </>
        }
    </div>
}
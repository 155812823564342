export enum CarStateEnum {
    RET_CAR = "Ret",
    RUN_CAR = "Run",
    OUT_CAR = "Out",
    IN_CAR = "In",
    STOP_CAR = "Stop",
    CHK_CAR = "Chk"
}

export const States = [
    CarStateEnum.RUN_CAR,
    CarStateEnum.IN_CAR,
    CarStateEnum.OUT_CAR,
    CarStateEnum.STOP_CAR,
    CarStateEnum.RET_CAR,
    CarStateEnum.CHK_CAR,
]
import {JsonObject, JsonProperty} from "json2typescript";
import Entry from "./Entry";
import Params from "./Params";
import FlagProgress from "./FlagProgress";
import SectorOrLap from "../alkamel/SectorOrLap";

@JsonObject("classement")
export default class Live {

    @JsonProperty("params", Params)
    params: Params = new Params();

    @JsonProperty("progressFlagState", [FlagProgress])
    progressFlagState: Array<FlagProgress> = [];

    @JsonProperty("line", [Entry])
    entries: Entry[] = [];

    @JsonProperty("bestSectors", [SectorOrLap])
    bestSectors: SectorOrLap[] = []

    @JsonProperty("bestTimesByCategory")
    bestTimesByCategory: number[] = [];

    // statEntries: EntriesStat = new EntriesStat();
    //
    // driversResult: Array<DriverResult> = [];
}
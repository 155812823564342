import Live from "../../../../../../../server/common/Models/live/Live";
import ReferentialType, {ChampionshipType, ReferentialSessionType, VideosType} from "./ReferentialType";
import ChannelType from "./ChannelType";
import Socket = SocketIOClient.Socket;

class LiveReducerType {

    constructor(currentSessionId = null, championship: ChampionshipType = null, configUrl : string = "", forLemans: boolean = false, lemansUrl: string = "", streamingUrl: string = "", force: boolean = false, noLogin: boolean = false) {
        this.currentSessionId = currentSessionId;
        this.championship = championship;
        this.forLemans = forLemans;
        this.lemansUrl = lemansUrl;
        this.streamingUrl = streamingUrl;
        this.socialNetworks = [];
        this.configUrl = configUrl;
        this.forcer = force;
        this.noLogin = noLogin;
    }

    replay: boolean = false;
    live: Live = new Live();
    results: any = {};
    referential: ReferentialType = new ReferentialType();
    flagVisible: boolean = true;
    lang: string;
    localTime: boolean = true;
    currentSessionId: number;
    currentSession: ReferentialSessionType;
    championship: ChampionshipType;
    currentCategory: number = -1;
    socialNetworks: [];
    forLemans: boolean = false;
    noLogin: boolean = false;
    connected: boolean = false;
    name: string;
    lemansUrl: string = 'https://24h-lemans.com';
    streamingUrl: string = 'https://streaming.lemans.org/en/login';
    channels: Array<ChannelType>;
    channelUrl: string = "";
    channelId = 1;
    configUrl: string;
    videos: VideosType;
    selectedMenu: string = "global";
    forcer: boolean = false;
    expert: boolean = false;
    darkMode: boolean = false;
    bestSectorMode: boolean = false;
    predictive: boolean = false;

    socket: Socket = null;

    showModalRequestLogin: boolean = false;
}
export default LiveReducerType;
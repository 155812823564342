import React, {Component} from 'react';
import RaceFlag from "../RaceFlag";
import {connect} from "react-redux";

declare var reactParameters;
import PlayerTimeProgress from "./PlayerTimeProgress";
import RaceNameStatus from "./RaceNameStatus";
import {t} from "../../../translation/translator";
import Fade from 'react-reveal/Fade';
import {SessionStatusEnum} from "../../../../../../../../../server/common/Enum/live/SessionTypeEnum";
import ReducerType from "../../../type/ReducerType";
import {displayTime} from "../../../reducer/live/reducer";
import {FlagsEnum} from "../../../../../../../../../server/common/Enum/live/FlagsEnum";
import {ReactSVG} from "react-svg";

const storeToProps = (state: ReducerType) => ({
    params: state.live.live.params,
    flagVisible: state.live.flagVisible,
    currentSession: state.live.currentSession,
    lang: state.live.lang,
    forcer: state.live.forcer,
    videos: state.live.videos
});

type State = { radioPlaying: boolean, radioUrl: string }
type Props = ReturnType<typeof storeToProps>;

class PlayerView extends Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            radioPlaying: false,
            radioUrl: ''
        };
    }

    async playRadio() {
        let radioUrl = (this.props.lang == "fr") ?
            this.props.videos.radio_fr :
            this.props.videos.radio_en;
        let radioAttr = (this.props.lang == "fr") ?
            this.props.videos.radio_fr_attr :
            this.props.videos.radio_en_attr;

        let url = radioUrl;
        if (false === reactParameters.elms) {
            // demande du token
            let membersUrl = reactParameters.fiawecUrl + "/fr/channel/token?attr=" + radioAttr + "&t=" + Math.random();

            let token = await (await fetch(membersUrl)).text();
            url = radioUrl + "?token=" + token;
        }
        this.setState({radioPlaying: true, radioUrl: url});
    }

    render() {
        let radioUrl = ""

        if (this.props.videos) {
            radioUrl = (this.props.lang == "fr") ?
                this.props.videos.radio_fr :
                this.props.videos.radio_en;
        }

        let live = (this.props.currentSession && this.props.currentSession.status == SessionStatusEnum.LIVE) || this.props.forcer;
        let remaining = this.props.params.remaining;
        let displayRemainingTime = displayTime(remaining)
        if (remaining < 0 || FlagsEnum.CHECK === this.props.params.raceState) {
            displayRemainingTime = "-";
        }
        return (
            <div className="player">
                {live &&
                <div className="info info-left">
                    <div>{t("elapsed_time", this.props.lang)}</div>
                    {FlagsEnum.CHECK != this.props.params.raceState ?
                        displayTime(this.props.params.elapsedTime) :
                        "-"}
                </div>
                }
                <div className="controls">
                    <Fade spy={this.props.flagVisible}>
                        <>
                            {this.props.flagVisible && <RaceNameStatus/>}
                            {!this.props.flagVisible && <RaceFlag class="fcy-player" checkVisibility={false}/>}
                        </>
                    </Fade>
                    <div className="radio">
                        {!this.state.radioPlaying && radioUrl &&
                            <span onClick={this.playRadio.bind(this)}>
                                <ReactSVG
                                    src={"/bundles/front/svg/radio.svg"}/>
                            </span>
                        }
                        {this.state.radioUrl &&
                            <iframe width="250" height="50" frameBorder={0} src={this.state.radioUrl}/>
                        }
                    </div>
                </div>
                {live &&
                <>
                    <div className="info info-right">
                        <div>{t("remaining_time", this.props.lang)}</div>
                        {displayRemainingTime}
                    </div>
                    <PlayerTimeProgress/>
                </>
                }

            </div>
        )
    }
}

const Player = connect(storeToProps, null)(PlayerView);
export default Player;
declare var reactParameters;

/**
 * https://github.com/center-key/fetch-json
 */
import axios from "axios";

/**
 * Fournis des fonctions générique d'accès au donnée API
 * Exploité par les dataProviders
 */
let apiUrl: any = null;

export function setApiUrl(_apiUrl: string) {
    apiUrl = _apiUrl;
}

const ApiProvider = {
    NEED_CONNECT: "NEED_CONNECT",
    STATUS_OK: 200,

    getOptions: () => {
        return {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
    },
    /**
     * Effectue une requête de type GET
     * Traite le JSON et retourne un objet JS
     */
    get: async (resource: string, params: Array<string> = []) : Promise<any> => {
        if (apiUrl == null) {
            setApiUrl(reactParameters.liveApiUrl);
        }

        let url = [apiUrl, resource, ...params].join("/");
        let options = ApiProvider.getOptions();
        return new Promise((resolve, reject) => {
            axios.get(url,options)
                .then((data: any) => {
                    // si status 401 on exige une reconnexion
                    if (data.status == "401") {
                        throw ApiProvider.NEED_CONNECT;
                    } else if (data.status && data.status != "200") {
                        throw JSON.stringify(data);
                    }
                    resolve(data);
                })
                .catch(reject);
        });
    },

    /**
     * Effectue une requête de type POST
     * Traite le JSON et retourne un objet JS
     */
    post: async (resource: string, params: Array<string>, dataPost: object) => {
        let options = ApiProvider.getOptions();
        return ApiProvider.pushRequest(resource, "post", params, dataPost, options);
    },

    /**
     * Effectue une requête de type PUT
     * Traite le JSON et retourne un objet JS
     */
    put: async (resource: string, params: Array<string>, dataPost: object) => {
        let options = ApiProvider.getOptions();
        return ApiProvider.pushRequest(resource, "put", params, dataPost, options);
    },

    /**
     * Effectue une requête de MAJ sur l'api
     * Traite le JSON et retourne un objet JS
     */
    pushRequest: async (resource: string, method: string, params: Array<string>, dataPost: object, options: object) => {
        let url = [reactParameters.liveApiUrl, resource, ...params].join("/").replace("//", "/");
        return new Promise((resolve, reject) => {
            axios[method](url, dataPost, options)
                .then((data: any) => {
                    // si status 401 on exige une reconnexion
                    if (data.status == "401") {
                        throw ApiProvider.NEED_CONNECT;
                    } else if (data.status && data.status != "200") {
                        throw JSON.stringify(data);
                    }
                    resolve(data);
                })
                .catch(reject);
            reject;
        });
    }
};
export default ApiProvider;
import React, {Component} from 'react';
import KeynoteType from "../../type/KeynoteType";

declare var $;

type Props = { item: KeynoteType };

export class KeynoteCardView extends Component<Props, null> {

    createHtml(string: string) {
        if(this.props.item.alias == 'LemansContentBuilderBundle:KnInfographicsComponent'){
            let regexp = /href="[\w:/\-\.]*"/g;
            string = string.replace(regexp, "");
        }
        if(this.props.item.alias == 'LemansContentBuilderBundle:KnNewsComponent'){
            string = string.replace('<a', '<a target="blank"');
        }
        return {__html: string};
    }

    componentDidMount(): void {
        $('#keynote-content' + this.props.item.id + ' .oembed').oembed();
    }

    render() {

        return (
            <div className={"keynote-card" + (this.props.item.highlight ? ' live ' : ' ') + this.props.item.class}>
                <div className="time">
                    <span>{this.props.item.full_date}</span>
                    <div className="icon" style={{backgroundColor: this.props.item.bgColor}}>
                        <i className={this.props.item.icon}/>
                    </div>
                </div>
                <div id={'keynote-content' + this.props.item.id} className="content"
                     dangerouslySetInnerHTML={this.createHtml(this.props.item.content)}/>
            </div>
        )
    }

}

export default KeynoteCardView;
import React, {useState} from 'react';
import {Member} from "../../type/live-api-generate";
import {VideosType} from "../../type/ReferentialType";
import ActionLiveType from "../../reducer/live/ActionLiveType";
import ActionTunnel from "../../reducer/tunnel/ActionTunnel";
import ReducerType from "../../type/ReducerType";
import {connect} from "react-redux";
import {t} from "../../translation/translator";
import {Person, SignOut} from "akar-icons";
declare var reactParameters;

type localProps = {
    account: string
};
const storeToProps = (state: ReducerType) => ({
    member: state.tunnel.member,
    lang: state.live.lang
});
const storeDispatchToProps = (dispatch) => ({
    logout: () => dispatch(ActionTunnel.logout()),
    displayIdentification: () => dispatch(ActionTunnel.displayIdentification(true)),
});

type Props = localProps & ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>;

const LoginView = (props: Props) => {
    let login = props.account;
    if ("fr" === props.lang) {
        login = login.replace('/en','/fr');
    }
    let logout = login.replace('login', 'logout');
    let account = login.replace('login', 'account');
    if (true === reactParameters.elms) {
        // ajout du param redirectUrl pour revenir sur la page courante :
        login += "?redirectUrl=" + window.location.href;
    }

    let name = props.member ?
        (props.member.firstName ? props.member.firstName : props.member.first_name) :
        '';

    let submenu = null;

    let picture = "/bundles/front/svg/_login.svg";
    let alt = t("login",props.lang)
    if (null !== props.member) {
        alt = name;
        if (props.member.picture) {
            // WEC
            picture = "https://www.fiawec.com" + props.member.picture
        } else if (props.member.profileImg) {
            // ELMS
            picture = props.member.profileImg;
        }

        submenu = (<div className="submenu">
                <ul>
                    <li>
                        <a href={account}>
                            <Person/>
                            <span>
                                {t('my_account', props.lang)}
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href={logout}>
                            <SignOut/>
                            <span>
                                {t('logout', props.lang)}
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }

    return (
        <div className="login">
            <a href={login} title={name}>
                <img className="avatar" src={picture} alt={alt}/>
            </a>
            {submenu}
        </div>
    );
}
export default connect(storeToProps, storeDispatchToProps)(LoginView);
import React, {Component} from 'react';
import Entry from "../../../../../../../../../server/common/Models/live/Entry";
import {ReferentialCategoryType, ReferentialDriverType, ReferentialEntryType} from "../../../type/ReferentialType";
import {ChronoTypeEnum} from "../../../../../../../../../server/common/Enum/live/SessionTypeEnum";
import {LastLastTypeEnum} from "../../../../../../../../../server/common/Models/alkamel/Enum/LastLastTypeEnum";
import SectorOrLap from "../../../../../../../../../server/common/Models/alkamel/SectorOrLap";
import {t} from "../../../translation/translator";

type Props = Entry & {
    index: number,
    rowCount: number|null,
    lang: string,
    bestSectors: SectorOrLap[],
    entryRef: ReferentialEntryType,
    categoryRef: ReferentialCategoryType,
    driversList: Array<ReferentialDriverType>,
    catFilter: number,
    chronoType: string,
    expert: boolean,
    selectCallback: (ReferentialEntryType, ReferentialDriverType) => void,
    bestSectorMode: boolean,
    sorting: string,
    previousLapGap: string,
    darkMode: boolean,
};

type State = {
    fadeOutArrow: string,
    arrowClass: string
}

export default class LiveTableRow extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            fadeOutArrow: "",
            arrowClass: ""
        };
    }

    componentDidMount(): void {
        this.componentDidUpdate(null, null);
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<{ fadeOutArrow: string; arrowClass: string }>, snapshot?: any
    ): void {
        // don't update if class exist
        if (this.updateClassStateChange(false) != this.state.arrowClass) {
            this.updateClassStateChange(true);
            setTimeout(() => {
                this.setState({fadeOutArrow: "fade-out"});
                setTimeout(() => {
                    this.setState({arrowClass: "", fadeOutArrow: ""});
                }, 1000);
            }, 500);
        }
    }

    updateClassStateChange(updateState: boolean) {
        let posChange = (this.props.catFilter == -1) ? this.props.positionChange : this.props.categoryPositionChange;
        if (posChange < 0) {
            if (updateState)
                this.setState({arrowClass: "going-up"});
            return "going-up";
        } else if (posChange > 0) {
            if (updateState)
                this.setState({arrowClass: "going-down"});
            return "going-down";
        }
        return this.state.arrowClass;
    }

    generateNameDriver() {
        //return this.props.driver;

        let driver = this.props.driver;
        let drivers = driver.split(', ');
        return drivers.map((d, i) => {
            let dv = d;
            if (0 !== i) {
                dv = ', ' + dv;
            }
            if ((this.props.chronoType === ChronoTypeEnum.FP || this.props.chronoType === ChronoTypeEnum.Q24H) && this.props.bestLapDriver === d) {
                return <b key={d} title={t("best_lap_driver", this.props.lang)}>{dv}</b>
            } else {
                return <span key={d}>{dv}</span>
            }
        })

        //return this.props.driver.replace(this.props.bestLapDriver,'<b>' + this.props.bestLapDriver + '</b>');
        // let d = this.props.driversList.find((d) => d.id == this.props.driverId);
        // return (d && d.firstname && d.lastname) ? d.lastname.toUpperCase() + ", " + d.firstname : this.props.driver;
    }

    generateColorLap() {
        switch (this.props.lastLapBestType) {
            case LastLastTypeEnum.BEST_PARTICIPANT:
                return "best-last";
            case LastLastTypeEnum.BEST_SESSION:
                return "best-time";
        }
        return '';
    }

    formatTime(time: number): string {
        let formatted = "";
        let withMinutes = false;
        // format du temps en minutes:secondes.milli
        if (60 * 1000 < time) {
            let minutes = Math.floor(time / 60000);
            formatted = minutes + ":";
            time -= 60000 * minutes;
            withMinutes = true;
        }
        if (withMinutes && 10000 > time) {
            formatted += "0";
        }
        formatted += (time / 1000).toFixed(3);
        return formatted;
    }

    getIdealLap(entry: Entry) {
        if (!entry.bestTimeSector1 || !entry.bestTimeSector2 || !entry.bestTimeSector3) {
            return "-";
        }
        let ideal = entry.bestTimeSector1 + entry.bestTimeSector2 + entry.bestTimeSector3;
        return this.formatTime(ideal);
    }

    rowClicked() {

        let d = this.props.driversList.find((d) => d.id == this.props.driverId);
        if (this.props.entryRef) {
            this.props.selectCallback(this.props.entryRef, d)
        }
    }

    renderSector(number: number, time: string, bestEntry: string, bestOverall: SectorOrLap, sorting: string) {
        let classname = "gap numeric align-middle";
        if (sorting === "bestTimeSector" + number.toString()) {
            classname += " current-sort ";
        }
        if (true === this.props.bestSectorMode) {
            return <td className={classname}>
                <span>{bestEntry && bestEntry.toString()}</span>
            </td>
        }

        if (bestOverall && time === bestOverall.timeStr) {
            classname += " best-time";
        } else if (time === bestEntry) {
            classname += " best-last";
        }
        let content = <>-</>;
        if (time) {
            content = <>{time.toString()}</>;
        }
        let overall = ""
        if (bestOverall) {
            overall = " / Overall : " + bestOverall.timeStr;
        }
        return <td className={classname}>
            <span title={"Best : " + bestEntry.toString() + overall}>{content}</span>
        </td>
    }

    render() {
        let gapLaps = (this.props.catFilter == -1) ? this.props.gapLaps : this.props.classGapLaps;
        let gapTime = (this.props.catFilter == -1) ? this.props.gapTime : this.props.classGapTime;
        let gaplapsPrevious = (this.props.catFilter == -1) ? this.props.gapPrevLaps : this.props.classGapPrevLaps;
        let gapTimePrevious = (this.props.catFilter == -1) ? this.props.gapPrevTime : this.props.classGapPrevTime;

        let gap = '';
        // si on est dans le même tour que le précédent, écart au premier en temps, sinon en laps
        if (gapLaps === this.props.previousLapGap) {
            gap = this.formatTime(gapTime);
        } else {
            gap = gapLaps;
        }

        let gapPrevious = '';
        if (gaplapsPrevious && gaplapsPrevious != '-') {
            gapPrevious = gaplapsPrevious;
        } else {
            gapPrevious = this.formatTime(gapTimePrevious);
        }

        let ranking = (this.props.catFilter == -1) ? this.props.ranking : this.props.categoryRanking;

        // si index != ranking : on trie par autre chose (ideal lap, ...) et on affiche l'index et la diff
        let position = <span>{1 + this.props.index}</span>
        let positionDiff = <span></span>
        if ((this.props.index + 1) != ranking) {
            let diff = this.props.index + 1 - ranking;
            let diffStr = diff.toString()
            let diffClass = 'positive';
            if (diff > 0) {
                diffStr = "+" + diffStr;
                diffClass = 'negative';
            }
            positionDiff = <span className={diffClass}> ({diffStr})</span>
        }

        let className = "hover openTeamModal";
        if (null !== this.props.rowCount && this.props.index == this.props.rowCount-1) {
            className += " last-row";
        }
        return (
            <tr id={"line-" + this.props.number} className={className} onClick={this.rowClicked.bind(this)}>
                {this.props.chronoType == ChronoTypeEnum.Race &&
                <>
                    <td className={"pos numeric " + this.state.arrowClass + " " + this.state.fadeOutArrow + " align-middle"}>
                        {position}
                        {positionDiff}
                    </td>
                    <td className="ranking numeric align-middle"><span>{this.props.number}</span></td>
                    <td className="status align-middle">
                                <span className={this.props.state.toLowerCase()}>
                                    {this.props.state}
                                </span>
                    </td>
                    <td className="class align-middle"
                        style={this.props.darkMode ?
                            {backgroundColor: this.props.categoryRef ? this.props.categoryRef.color : ''} :
                            {color: this.props.categoryRef ? this.props.categoryRef.color : ''}}>
                        <span>{this.props.entryRef && this.props.entryRef.category_label}</span></td>
                    <td className="team align-middle">
                        <span>{this.props.team}</span>
                    </td>
                    <td className="driver align-middle">
                        <span>{this.generateNameDriver()}</span>
                    </td>
                    {!this.props.expert &&
                    <td className="car-img align-middle">
                        <div className="model-picture-wrapper">
                            <div>
                                {this.props.entryRef && this.props.entryRef.picture_url &&
                                <div><img alt={this.props.car + "#" + this.props.number} src={this.props.entryRef.picture_url}/></div>
                                }
                            </div>
                        </div>
                    </td>
                    }
                    <td className="car align-middle">
                        <div className="model-wrapper">
                            <div></div>
                            <div>{this.props.car}</div>
                        </div>
                    </td>
                    {!this.props.expert &&
                    <td className="tyres align-middle">
                        {this.props.entryRef && this.props.entryRef.tyres_picture ?
                            <div><img alt={this.props.tyre} src={this.props.entryRef.tyres_picture}/></div> :
                            <div>{this.props.tyre}</div>
                        }
                    </td>
                    }
                    <td className="lap numeric align-middle">{this.props.lap}</td>
                    <td className="classement numeric align-middle">{1 === ranking || gap == "" ? "-" : gap}</td>
                    {/* int */}
                    <td className="gap numeric align-middle">{1 === ranking || gapPrevious == "" ? "-" : gapPrevious}</td>
                    {this.props.expert &&
                    <>
                        {this.renderSector(1, this.props.currentSector1, this.props.bestSector1, this.props.bestSectors[1], this.props.sorting)}
                        {this.renderSector(2, this.props.currentSector2, this.props.bestSector2, this.props.bestSectors[2], this.props.sorting)}
                        {this.renderSector(3, this.props.currentSector3, this.props.bestSector3, this.props.bestSectors[3], this.props.sorting)}
                        {this.props.bestSectorMode &&
                        <td className={"last numeric align-middle " + (this.props.sorting == "ideallap" ? "current-sort" : "")}>
                            {this.getIdealLap(this.props)}
                        </td>
                        }
                    </>
                    }
                    {/* LAST */}
                    <td className={"int numeric align-middle " + (this.props.sorting == "lastlapTime" ? " current-sort " : " ") + this.generateColorLap()}>
                        {this.props.lastlap}
                    </td>
                    {/* BEST */}
                    <td className={"last numeric align-middle" + (this.props.sorting == "bestlapTime" ? " current-sort " : " ")}>
                        {this.props.bestLap}
                    </td>

                    <td className="spd numeric align-middle ">{this.props.speed}</td>
                    <td className="pit numeric">{this.props.pitstop}</td>
                </>
                }
                {(this.props.chronoType == ChronoTypeEnum.FP || this.props.chronoType == ChronoTypeEnum.Q24H) &&
                    <>
                        <td className={"pos numeric " + this.state.arrowClass + " " + this.state.fadeOutArrow + " align-middle"}>
                            {position}
                            {positionDiff}
                        </td>
                        <td className="ranking numeric align-middle"><span>{this.props.number}</span></td>
                        <td className="status align-middle"><span
                            className={this.props.state.toLowerCase()}>{this.props.state}</span></td>
                        <td className="class align-middle"
                            style={{color: this.props.categoryRef && this.props.categoryRef.color}}>
                            <span>{this.props.entryRef && this.props.entryRef.category_label}</span></td>
                        <td className="team align-middle"><span>{this.props.team}</span></td>
                        <td className="driver align-middle">
                            <span>{this.generateNameDriver()}</span></td>
                        {!this.props.expert &&
                            <td className="car-img align-middle">
                                <div className="model-picture-wrapper">
                                    {this.props.entryRef && this.props.entryRef.picture_url &&
                                        <div><img alt={this.props.car + "#" + this.props.number} src={this.props.entryRef.picture_url}/></div>
                                    }
                                </div>
                            </td>
                        }
                        <td className="car align-middle">
                            <div className="model-wrapper">
                                <div></div>
                                <div>{this.props.car}</div>
                            </div>
                        </td>
                        {!this.props.expert &&
                            <td className="tyres align-middle">
                                <div>
                                    {this.props.entryRef && this.props.entryRef.tyres_picture ?
                                        <div><img alt={this.props.tyre} src={this.props.entryRef.tyres_picture}/></div> :
                                        <div>{this.props.tyre}</div>
                                    }
                                </div>
                            </td>
                        }
                        <td className={"last numeric align-middle" + (this.props.sorting == "bestlapTime" ? " current-sort " : " ")}>
                            {this.props.bestLap}
                        </td>
                        <td className="last numeric align-middle">{(1 === ranking || "" === gap) ? "-" : gap}</td>
                        {this.props.expert &&
                            <>
                                {this.renderSector(1, this.props.currentSector1, this.props.bestSector1, this.props.bestSectors[1], this.props.sorting)}
                                {this.renderSector(2, this.props.currentSector2, this.props.bestSector2, this.props.bestSectors[2], this.props.sorting)}
                                {this.renderSector(3, this.props.currentSector3, this.props.bestSector3, this.props.bestSectors[3], this.props.sorting)}
                                {this.props.bestSectorMode &&
                                    <td className={"last numeric align-middle " + (this.props.sorting == "ideallap" ? "current-sort" : "")}>
                                        {this.getIdealLap(this.props)}
                                    </td>
                                }
                            </>
                        }
                        <td className={"int numeric align-middle " + (this.props.sorting == "lastlapTime" ? " current-sort " : " ") + this.generateColorLap()}>
                            {this.props.lastlap}
                        </td>
                        <td className="lap numeric align-middle">{this.props.lap}</td>
                        <td className="spd numeric" scope="col">{this.props.speed}</td>
                        <td className="pit numeric align-middle">{this.props.pitstop}</td>
                    </>
                }
                {(this.props.chronoType == ChronoTypeEnum.Q2LAPS) &&
                    <>
                        <td className={"pos numeric " + this.state.arrowClass + " " + this.state.fadeOutArrow + " align-middle"}>
                            {position}
                            {positionDiff}
                        </td>
                        <td className="ranking numeric align-middle"><span>{this.props.number}</span></td>
                        <td className="status align-middle"><span
                            className={this.props.state.toLowerCase()}>{this.props.state}</span></td>
                        <td className="class align-middle"
                            style={{color: this.props.categoryRef && this.props.categoryRef.color}}>
                            <span>{this.props.entryRef && this.props.entryRef.category_label}</span></td>
                        <td className="team align-middle"><span>{this.props.team}</span></td>
                        <td className="driver align-middle">
                            <span>{this.generateNameDriver()}</span></td>
                        {!this.props.expert &&
                            <td className="car-img align-middle">
                                <div className="model-picture-wrapper">
                                    {this.props.entryRef && this.props.entryRef.picture_url &&
                                        <div><img alt={this.props.car + "#" + this.props.number} src={this.props.entryRef.picture_url}/></div>
                                    }
                                </div>
                            </td>
                        }
                        <td className="car align-middle">
                            <div className="model-wrapper">
                                <div></div>
                                <div>{this.props.car}</div>
                            </div>
                        </td>
                        {!this.props.expert &&
                            <td className="tyres align-middle">
                                <div>
                                    {this.props.entryRef && this.props.entryRef.tyres_picture ?
                                        <div><img alt={this.props.tyre} src={this.props.entryRef.tyres_picture}/></div> :
                                        <div>{this.props.tyre}</div>
                                    }
                                </div>
                            </td>
                        }
                        <td className={"last numeric align-middle" + (this.props.sorting == "bestlapTime" ? " current-sort " : " ")}>
                            {this.props.bestLap}
                        </td>
                        <td className="last numeric align-middle">{(1 === ranking || "" === gap) ? "-" : gap}</td>
                        {this.props.expert &&
                            <>
                                {this.renderSector(1, this.props.currentSector1, this.props.bestSector1, this.props.bestSectors[1], this.props.sorting)}
                                {this.renderSector(2, this.props.currentSector2, this.props.bestSector2, this.props.bestSectors[2], this.props.sorting)}
                                {this.renderSector(3, this.props.currentSector3, this.props.bestSector3, this.props.bestSectors[3], this.props.sorting)}
                                {this.props.bestSectorMode &&
                                    <td className={"last numeric align-middle " + (this.props.sorting == "ideallap" ? "current-sort" : "")}>
                                        {this.getIdealLap(this.props)}
                                    </td>
                                }
                            </>
                        }

                        <td className={"last numeric align-middle" + (this.props.sorting == "secondBestlapTime" ? " current-sort " : " ")}>
                            {this.props.secondBestLap}
                        </td>
                        <td className={"int numeric align-middle " + (this.props.sorting == "lastlapTime" ? " current-sort " : " ") + this.generateColorLap()}>
                            {this.props.lastlap}
                        </td>
                        <td className="lap numeric align-middle">{this.props.lap}</td>
                        <td className="spd numeric" scope="col">{this.props.speed}</td>
                        <td className="pit numeric align-middle">{this.props.pitstop}</td>
                    </>
                }
                {this.props.chronoType == ChronoTypeEnum.QWEC &&
                <>
                    <td className={"pos numeric " + this.state.arrowClass + " " + this.state.fadeOutArrow + " align-middle"}>
                        {position}
                        {positionDiff}
                    </td>
                    <td className="ranking numeric align-middle"><span>{this.props.number}</span></td>
                    <td className="status align-middle"><span
                        className={this.props.state.toLowerCase()}>{this.props.state}</span></td>
                    <td className="class align-middle"
                        style={{color: this.props.categoryRef && this.props.categoryRef.color}}>
                        <span>{this.props.entryRef && this.props.entryRef.category_label}</span></td>
                    <td className="team align-middle"><span>{this.props.team}</span></td>
                    <td className="driver align-middle"><span>{this.generateNameDriver()}</span></td>
                    {!this.props.expert &&
                    <td className="car-img align-middle">
                        <div className="model-wrapper">
                            <div></div>
                            <div>{this.props.entryRef && this.props.entryRef.picture_url &&
                            <div><img alt={this.props.car + "#" + this.props.number} src={this.props.entryRef.picture_url}/></div>
                            }</div>
                        </div>
                    </td>
                    }
                    <td className="car align-middle">
                        <div className="model-wrapper">
                            <div></div>
                            <div>{this.props.car}</div>
                        </div>
                    </td>
                    {!this.props.expert &&
                    <td className="tyres align-middle">
                        {this.props.entryRef && this.props.entryRef.tyres_picture ?
                            <div><img alt={this.props.tyre} src={this.props.entryRef.tyres_picture}/></div> :
                            <div>{this.props.tyre}</div>
                        }
                    </td>
                    }
                    <td className="last numeric align-middle">
                        <div>{this.props.averageTime}</div>
                    </td>
                    <td className="last numeric align-middle">{(this.props.gap) ? this.props.gap : "-"}</td>
                    {this.props.expert &&
                    <>
                        {this.renderSector(1, this.props.currentSector1, this.props.bestSector1, this.props.bestSectors[1], this.props.sorting)}
                        {this.renderSector(2, this.props.currentSector2, this.props.bestSector2, this.props.bestSectors[2], this.props.sorting)}
                        {this.renderSector(3, this.props.currentSector3, this.props.bestSector3, this.props.bestSectors[3], this.props.sorting)}
                        {this.props.bestSectorMode &&
                        <td className={"last numeric align-middle " + (this.props.sorting == "ideallap" ? "current-sort" : "")}>
                            {this.getIdealLap(this.props)}
                        </td>
                        }
                    </>
                    }
                    <td className="last numeric align-middle">{this.props.d1L1}</td>
                    <td className="last numeric align-middle">{this.props.d2L1}</td>
                    <td className="last numeric align-middle">{this.props.lastlap}</td>
                </>
                }

            </tr>
        )
    }
}
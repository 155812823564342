import React, {Component} from 'react';
import {connect} from "react-redux";
import {t} from "../../translation/translator";
import LiveReducerType from "../../type/LiveReducerType";
import {SessionStatusEnum} from "../../../../../../../../server/common/Enum/live/SessionTypeEnum";
import ReducerType from "../../type/ReducerType";

const storeToProps = (state: ReducerType) => ({
    params: state.live.live.params,
    currentSession: state.live.currentSession,
    lang: state.live.lang
});
type Props = ReturnType<typeof storeToProps>;

export class WeatherEntryView extends Component<Props, any> {
    degToCard(deg) {
        if (deg > 11.25 && deg <= 33.75) {
            return "NNE";
        } else if (deg > 33.75 && deg <= 56.25) {
            return "ENE";
        } else if (deg > 56.25 && deg <= 78.75) {
            return "E";
        } else if (deg > 78.75 && deg <= 101.25) {
            return "ESE";
        } else if (deg > 101.25 && deg <= 123.75) {
            return "ESE";
        } else if (deg > 123.75 && deg <= 146.25) {
            return "SE";
        } else if (deg > 146.25 && deg <= 168.75) {
            return "SSE";
        } else if (deg > 168.75 && deg <= 191.25) {
            return "S";
        } else if (deg > 191.25 && deg <= 213.75) {
            return "SSW";
        } else if (deg > 213.75 && deg <= 236.25) {
            return "SW";
        } else if (deg > 236.25 && deg <= 258.75) {
            return "WSW";
        } else if (deg > 258.75 && deg <= 281.25) {
            return "W";
        } else if (deg > 281.25 && deg <= 303.75) {
            return "WNW";
        } else if (deg > 303.75 && deg <= 326.25) {
            return "NW";
        } else if (deg > 326.25 && deg <= 348.75) {
            return "NNW";
        } else {
            return "N";
        }
    }

    render() {
        // if (this.props.currentSession && this.props.currentSession.status != SessionStatusEnum.LIVE) {
        //     return <></>;
        // }
        return (
            <div className="bottom">
                <div className="weather-entry">
                    <div className="label">{t("wind", this.props.lang)}</div>
                    {
                        this.props.params.windSpeed ?
                            <div
                                className="info">{this.degToCard(this.props.params.windDirection)} at {this.props.params.windSpeed.toFixed(2)} k/h</div> :
                            <div className="info">-</div>
                    }
                </div>
                <div className="weather-entry">
                    <div className="label">{t("air_temp", this.props.lang)}</div>
                    {this.props.params.airTemp ?
                        <div className="info">{this.props.params.airTemp.toFixed(2)}°</div> :
                        <div className="info">-</div>
                    }
                </div>
                <div className="weather-entry">
                    <div className="label">{t("humidity", this.props.lang)}</div>
                    {this.props.params.humidity ?
                        <div className="info">{this.props.params.humidity.toFixed(2)}%</div> :
                        <div className="info">-</div>
                    }
                </div>
                <div className="weather-entry">
                    <div className="label">{t("track_temp", this.props.lang)}</div>
                    {this.props.params.trackTemp ?
                        <div className="info">{this.props.params.trackTemp.toFixed(2)}°</div> :
                        <div className="info">-</div>
                    }
                </div>
                <div className="weather-entry">
                    <div className="label">{t("pressure", this.props.lang)}</div>
                    {this.props.params.pressure ?
                        <div className="info">{this.props.params.pressure.toFixed(2)} hPa</div> :
                        <div className="info">-</div>
                    }
                </div>

            </div>
        )
    }
}

const WeatherEntry = connect(storeToProps, null)(WeatherEntryView);
export default WeatherEntry;
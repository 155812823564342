import React from "react";

type Props = {
    onClick: () => void;
    children: any;
    current?: boolean,
    secondary?: boolean
}

export const Button = (props: Props) => {
    return <div className={"button " + (props.current ? " current" : " ") + (props.secondary ? " secondary" : " ") } onClick={props.onClick}>
        <div></div>
        {props.children}
    </div>
}
import { combineReducers } from 'redux';
import live from "./live/reducer";
import tunnel from "./tunnel/reducer";
import stints from "./stints/reducer";
import race_control from "./race_control/reducer";
import laps from "./laps/reducer";
import { connectRouter } from 'connected-react-router'

/**
 * Définit les reducers de l'application
 */


const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    live,
    tunnel,
    stints,
    race_control,
    laps
})
export default createRootReducer;
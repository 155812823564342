import React, {Component} from 'react';
import {ReferentialRaceType} from "../../type/ReferentialType";
import {getLabel} from "../../translation/translator";
import {connect} from "react-redux";
import MyModal from './MyModal';
import ReducerType from "../../type/ReducerType";
declare var reactParameters;
type Props = { liveHome: string, race: ReferentialRaceType, lang: string, forLemans: boolean, connected: boolean };

const storeToProps = (state: ReducerType) => ({
    race: state.live.referential.race,
    lang: state.live.lang,
    forLemans: state.live.forLemans,
    connected: state.live.connected});

export class BrandRaceView extends Component<Props, any> {

    render() {
        return (
            <div className="brand-race">
                {this.props.race && !this.props.forLemans &&
                <a>
                    {this.props.race.logo && reactParameters.elms &&
                    <img alt="racename" src={this.props.race.logo} title={getLabel(this.props.race, "name", this.props.lang)}/>
                    }
                    {(!this.props.race.logo || !reactParameters.elms) &&
                    <span>
                        {getLabel(this.props.race, 'name', this.props.lang)}
                    </span>
                    }
                </a>
                }
                {this.props.forLemans &&
                    <MyModal lang={this.props.lang}/>
                }
            </div>
        )
    }
}

const BrandRace = connect(storeToProps, null)(BrandRaceView);
export default BrandRace;
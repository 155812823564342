import {JsonObject, JsonProperty} from "json2typescript";
import {NumberConverter} from "../../../src/Converter/NumberConverter";
import {BooleanConverter} from "../../../src/Converter/BooleanConverter";
import Position from "./Position";
import Driver from "../alkamel/Driver";
import {LastLastTypeEnum} from "../alkamel/Enum/LastLastTypeEnum";

@JsonObject("entry")
export default class Entry {

    lastLapDiff: number;
    position: Position;

    @JsonProperty("categoryPosition", NumberConverter, true)
    categoryPosition: number = 0;

    @JsonProperty("previousLastLap", NumberConverter, true)
    previousLastLap: number = 0;

    @JsonProperty("pos", NumberConverter)
    ranking: number = 0;

    @JsonProperty("pos", NumberConverter)
    positionChange: number = 0;

    @JsonProperty("pos", NumberConverter)
    categoryRanking: number = 0;

    @JsonProperty("pos", NumberConverter)
    categoryPositionChange: number = 0;

    @JsonProperty("num", NumberConverter)
    number: number = 0;

    @JsonProperty("ecmParticipantId", NumberConverter)
    id: number = 0;

    @JsonProperty("team")
    team: string = "-";

    @JsonProperty("tyre")
    tyre: string = "-";

    @JsonProperty("driver")
    driver: string = "-";

    @JsonProperty("bestLapDriver")
    bestLapDriver: string = "-";

    @JsonProperty("car")
    car: string = "-";

    @JsonProperty("lap")
    lap: number = 0;

    @JsonProperty("gap")
    gap: string = "-";

    @JsonProperty("gapPrev")
    gapPrev: string = "-";

    @JsonProperty("classGap")
    classGap: string = "-";

    @JsonProperty("classGapPrev")
    classGapPrev: string = "-";

    @JsonProperty("gapTime")
    gapTime: number = 0;

    @JsonProperty("gapPrevTime")
    gapPrevTime: number = 0;

    @JsonProperty("classGapTime")
    classGapTime: number = 0;

    @JsonProperty("classGapPrevTime")
    classGapPrevTime: number = 0;

    @JsonProperty("gapLaps")
    gapLaps: string = "-";

    @JsonProperty("gapPrevLaps")
    gapPrevLaps: string = "-";

    @JsonProperty("classGapLaps")
    classGapLaps: string = "-";

    @JsonProperty("classGapPrevLaps")
    classGapPrevLaps: string = "-";

    @JsonProperty("lastlap")
    lastlap: string = "-";

    lastlapTime: number = 0;
    bestlapTime: number = 0;
    secondBestlapTime: number = 0;

    @JsonProperty("pitstop", NumberConverter)
    pitstop: number = 0;

    @JsonProperty("bestlap")
    bestLap: string = "-";

    @JsonProperty("secondBestLap")
    secondBestLap: string = "-";

    @JsonProperty("speed")
    speed: string = "-";

    bestTimeSector1: number = 0;
    bestTimeSector2: number = 0;
    bestTimeSector3: number = 0;

    bestSector1: string = "";

    bestSector2: string = "";

    bestSector3: string = "";

    currentSector1: string = "";

    currentSector2: string = "";

    currentSector3: string = "";

    @JsonProperty("sector", NumberConverter)
    sector: number = 0;

    @JsonProperty("ecmDriverId", NumberConverter)
    driverId: number = 0;

    @JsonProperty("ecmCategoryId", NumberConverter)
    categoryId: number = 0;

    @JsonProperty("now")
    state: string = "-";

    @JsonProperty("WEC", BooleanConverter)
    isWEC: boolean = undefined;

    @JsonProperty("cat")
    category: string = "-";

    @JsonProperty("nat")
    nationality: string = "-";

    @JsonProperty("average_laps_count", String, true)
    avLaps: string = "-";

    @JsonProperty("average_time", String, true)
    averageTime: string = "-";

    @JsonProperty("time", String, true)
    time: string = "-";

    @JsonProperty("average_d1_l1", String, true)
    d1L1: string = "-";

    @JsonProperty("average_d2_l1", String, true)
    d2L1: string = "-";

    @JsonProperty("lastPassingTime", NumberConverter)
    lastPassingTime: number = 0;

    drivers: Driver[] = [];

    lastLapBestType: LastLastTypeEnum = LastLastTypeEnum.NONE;

    globalCategoryPosition?: number;

}
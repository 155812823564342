import React, {useState, useEffect} from "react";
import 'moment-timezone';
import {t} from '../translation/translator';
import lang from '../translation/lang';
import moment = require("moment");
import Live from "../../../../../../../server/common/Models/live/Live";

export default function Countdown(props: { date: number, timezone: string, locale: string }) {
    const [now, setNow] = useState(moment());
    let start = moment.unix(props.date);

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(moment());
        }, 1000);
        return () => clearInterval(interval);
    }, []);


    let duration = moment.duration(start.diff(now));

    let days = Math.floor(duration.asDays()).toLocaleString('en-US', {minimumIntegerDigits: 2});
    let hours = Math.floor(duration.hours()).toLocaleString('en-US', {minimumIntegerDigits: 2});
    let minutes = Math.floor(duration.minutes()).toLocaleString('en-US', {minimumIntegerDigits: 2});
    let seconds = Math.floor(duration.seconds()).toLocaleString('en-US', {minimumIntegerDigits: 2});

    if (days == "NaN") {
        days = "-";
    }
    if (hours == "NaN") {
        hours = "-";
    }
    if (minutes == "NaN") {
        minutes = "-";
    }
    if (seconds == "NaN") {
        seconds = "-";
    }
    return <div className="session-timer">
        <div className="session-timer-item">
            <div className="numerals">{days}</div>
            <div className="unit">{t('days', props.locale)}</div>
        </div>
        <div className="session-timer-item">
            <div className="numerals">{hours}</div>
            <div className="unit">{t('hours', props.locale)}</div>
        </div>
        <div className="session-timer-item">
            <div className="numerals">{minutes}</div>
            <div className="unit">{t('mins', props.locale)}</div>
        </div>
        <div className="session-timer-item">
            <div className="numerals">{seconds}</div>
            <div className="unit">{t('secs', props.locale)}</div>
        </div>
    </div>
}
import './scss/main.scss';
import React from "react";
import ReactDOM from 'react-dom';
import LiveMain from "./react/LiveMain";
import {Provider} from "react-redux";

import Countdown from './react/component/Countdown';
import Translator from "./react/component/Translator";
import {ChampionshipType} from "./react/type/ReferentialType";
import {configureStore} from "./react/reducer/configureStore";

const $ = require("jquery");

require('umbrella_core/App');

const Kernel = require('./js/utils/Kernel');

let win = window as any;
win.Kernel = new Kernel();
win.Api = require('umbrella_core/components/appproxy/Api');

win.Kernel.registerComponent('Home', require('./js/components/Home'));
win.Kernel.registerComponent('Header', require('./js/components/Header'));
win.Kernel.registerComponent('CustomSelect', require('./js/components/CustomSelect'));
win.Kernel.registerComponent('Footer', require('./js/components/Footer'));

win.$ = $;
win.jQuery = $;
require('./js/libs/jquery.oembed.js');
win.mountApp = function () {

    let $body = $('body');

    // mount components
    win.Kernel.mountComponents($body);
};
var initialHeadOffset;
var initialFilterOffset;

$(document).ready(() => {
    let $headerTiming = $(".part-table .table-content thead");
    let $filterTiming = $(".part-table .table-header");
    let $player = $('.player');

    let tableHeaderFixed = false;
    //

    let videoSizeInit = false;
    window.addEventListener("resize", function () {
        document.body.style.height = window.innerHeight + "px";
        $(".part-video").css("height", "auto");
       // $(".part-video").css("height", $(".part-video").height());
    })
    window.addEventListener('scroll', function (e) {
        let scrollPosition = $(window).scrollTop();
        if (!videoSizeInit) {
         //   $(".part-video").css("height", $(".part-video").height());
            videoSizeInit = true;

        }
        if (scrollPosition > window.innerHeight / 2) {
            $('body').addClass('scrolled');

        } else {
            $('body').removeClass('scrolled');
        }
        if (undefined == $headerTiming) {
            let breakpointTableHeader = (initialHeadOffset ? initialHeadOffset : $headerTiming.offset().top);
            // si le header du tableau n'est plus visible
            if (scrollPosition > breakpointTableHeader - 70) {
                if (!$headerTiming.hasClass("fixed-head")) {
                    initialHeadOffset = $headerTiming.offset().top;
                    // fixer le header du tableau
                    $headerTiming.addClass("fixed-head");
                    $headerTiming.css("width", $headerTiming.parent().width());
                    // $headerTiming.css("top",$player.position().top + $player.height() + $filterTiming.height());
                    tableHeaderFixed = true;

                    let scrollLeft = $(".table-race").scrollLeft();
                    if (scrollLeft > 0) {
                        let sidebarWidth = $(".sidebar").is(":visible") ? $(".sidebar").width() : 0;
                        $headerTiming.css("left", -1 * scrollLeft + sidebarWidth);
                    }
                }
            } else {
                if ($headerTiming.hasClass("fixed-head")) {
                    $headerTiming
                        .removeClass("fixed-head");
                    tableHeaderFixed = false;

                }
            }

            let breakpointTableFilter = (initialFilterOffset ? initialFilterOffset : $filterTiming.offset().top);
            // si le filtre du tableau n'est plus visible
            if (scrollPosition > breakpointTableFilter - 70) {
                if (!$filterTiming.hasClass("fixed-filter")) {
                    initialFilterOffset = $filterTiming.offset().top;

                    // fixer le filtre
                    $filterTiming.css("width", $headerTiming.parent().width())
                        .addClass("fixed-filter");
                }
            } else {
                if ($filterTiming.hasClass("fixed-filter")) {
                    $filterTiming
                        .removeClass("fixed-filter");
                }
            }
        }
    })

    $(".table-race").on("scroll", function (e) {
        if (tableHeaderFixed) {
            let scrollLeft = $(".table-race").scrollLeft();
            let sidebarWidth = $(".sidebar").is(":visible") ? $(".sidebar").width() : 0;
            $headerTiming.css("left", -1 * scrollLeft + sidebarWidth);
        }
    })
});

/****************************
 * Composants React
 ****************************/
// declare var reactParameters;
// if ("dev" !== reactParameters.env) {
//     Sentry.init({
//         dsn: "https://0929e593148d498e9f5f6e89adba5a99@sentry.6tm.eu:9000/17",
//         integrations: [new Integrations.BrowserTracing()],
//         environment: reactParameters.env,
//
//         // We recommend adjusting this value in production, or using tracesSampler
//         // for finer control
//         tracesSampleRate: 1.0,
//     });
// }
let dark = false;
if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    dark = true;
}
let livePage = document.getElementById('livePage');
if (livePage) {
    let home = livePage.getAttribute('home');
    let raceId = livePage.getAttribute('data-race');
    let session = livePage.getAttribute('data-session');
    let sessionId = session ? parseInt(session) : 0;
    let account = livePage.getAttribute('account');
    let membersUrl = livePage.getAttribute('membersUrl');
    let liveHome = livePage.getAttribute('live-home');
    let socialNetworkUrl = livePage.getAttribute('social');
    let configUrl = livePage.getAttribute('config');
    let memberData = livePage.getAttribute('member');
    let forLemans = livePage.getAttribute('forLemans') == 'true';
    let noLogin = livePage.getAttribute('noLogin') == '1';
    let lemansUrl = livePage.getAttribute('lemansUrl');
    let acoUrl = livePage.getAttribute('acoUrl');
    let radioFrAttr = livePage.getAttribute('radio_fr_attr');
    let radioEnAttr = livePage.getAttribute('radio_en_attr');
    let streamingUrl = livePage.getAttribute('streamingUrl');
    let forcer = (livePage.getAttribute('forcer') == "1");

    let championship = new ChampionshipType(parseInt(livePage.getAttribute('championship-id')), livePage.getAttribute('championship-alias'));

    let member = memberData ? JSON.parse(livePage.getAttribute('member')) : null;
    let store = configureStore(sessionId, championship, configUrl, forLemans, lemansUrl, streamingUrl, forcer, noLogin)

    ReactDOM.render(
        <Provider store={store}>
            <LiveMain raceId={raceId} home={home} liveHome={liveHome} account={account} member={member} acoUrl={acoUrl}
                      packsUrl={membersUrl} dark={dark}
                      session={sessionId} championship={championship} social={socialNetworkUrl}
                      radioFrAttr={radioFrAttr} radioEnAttr={radioEnAttr}/>
            <Translator/>
        </Provider>
        , livePage);
}
let countdowns = document.getElementsByClassName('countdown');
for (let i = 0; i < countdowns.length; i++) {
    let countdown = countdowns[i];
    let timestamp = parseInt(countdown.getAttribute('date'));
    let timezone = countdown.getAttribute('timezone');
    let locale = countdown.getAttribute('locale');
    ReactDOM.render(
        <Countdown date={timestamp} timezone={timezone} locale={locale}/>
        , countdown);
}

import {WeatherEnum} from "../../../../../../../server/common/Enum/live/WeatherEnum";

const lang = {
    race_control_empty: {
      fr: 'Aucun message pour le moment',
      en: 'No message yet'
    },
    see_all: {
        fr:'Voir tout',
        en: 'See all'
    },
    force_top: {
        fr:'Bloquer en haut',
        en: 'No hover'
    },
    latest_news: {
        fr: 'Dernières actus',
        en: 'Latest news'
    },
    live_overview: {
        fr: 'Vue globale',
        en: 'Live Overview'
    },
    live_keynote: {
        fr: 'LiveText',
        en: 'LiveText'
    },
    live_raceevent: {
        fr: 'Faits de course',
        en: 'Race Facts'
    },
    live_pittime: {
        fr: 'Arrêts',
        en: 'Pit time'
    },
    live_drivetime: {
        fr: 'Temps au volant',
        en: 'Drive time'
    },
    live_carstats: {
        fr: 'Statistiques voiture',
        en: 'Car stats'
    },
    live_carcomparisons: {
        fr: 'Comparatifs',
        en: 'Car comparison'
    },
    live_racecontrol: {
        fr: 'Direction de course',
        en: 'Race control'
    },
    session_stats: {
        fr: 'Statistiques session',
        en: 'Session statistics'
    },
    manufacturer: {
        fr: 'Constructeur',
        en: 'Manufacturer'
    },
    race: {
        fr: 'Course',
        en: 'Race'
    },
    pole: {
        fr: 'Pôle',
        en: 'Pole'
    },
    team: {
        fr: 'Equipe',
        en: 'Team'
    },
    predictif: {
        fr: 'Prédiction',
        en: 'Predictive'
    },
    back_live: {
        fr: 'Retour au direct',
        en: 'Back to live'
    },
    car_comparaison: {
        fr: "Comparaison véhicule",
        en: "Car comparison"
    },
    team_recap: {
        fr: "Recap équipe",
        en: "Team recap"
    },
    comparativegauge: {
        fr: "Jauge Comparative",
        en: "Comparative gauge"
    },
    avlap: {
        fr: "Tour moy.",
        en: "AV Lap"
    },
    convert_time: {
        fr: "Convertir à l'heure locale",
        en: "Convert to local time"
    },
    local_time: {
        fr: "Heure locale",
        en: "Local time"
    },
    race_time: {
        fr: "Heure de la course",
        en: "Race time"
    },
    av_time: {
        fr: "Temps moy.",
        en: "AV Time"
    },
    av_d1l1: {
        fr: "Moy. D1L1",
        en: "AV D1L1"
    },
    av_d2l1: {
        fr: "Moy. D2L1",
        en: "AV D2L1"
    },
    more_about_live: {
        fr: "A propos du compte live",
        en: "More about live account"
    },
    login: {
        fr: "Connexion",
        en: "Login"
    },
    logout: {
        fr: 'Déconnexion',
        en: 'Log out'
    },
    my_account: {
      fr: 'Mon compte',
      en: "My account"
    },
    prenium_live: {
        fr: "Le live premium",
        en: "Premium live"
    },
    streaming_experience: {
        fr: "Expérience streaming",
        en: "Streaming experience"
    },
    selected_car: {
        fr: "Voiture sélectionnée",
        en: "Selected car"
    },
    favoris: {
        fr: "Favoris",
        en: "Favourites"
    },
    category_leader: {
        fr: "Leader Catégorie",
        en: "Class leader"
    },
    cat_pos: {
        fr: "Cat.",
        en: "Class"
    },
    numberlap: {
        fr: "Nb tour",
        en: "Nr of laps"
    },
    drivingtime: {
        fr: "Temps au volant",
        en: "Driving time"
    },
    safety_car_flag: {
        fr: "Safety Car",
        en: "Safety Car"
    },
    off_flag: {
        fr: "Course terminée",
        en: "Race over"
    },
    chk_flag: {
        fr: "Drapeau à damier",
        en: "Checkered flag"
    },
    full_yellow_flag: {
        fr: "FCY",
        en: "FCY"
    },
    yellow_flag: {
        fr: "Yellow Flag",
        en: "Yellow Flag"
    },
    red_flag: {
        fr: "Red Flag",
        en: "Red Flag"
    },
    green_flag: {
        fr: "Green Flag",
        en: "Green Flag"
    },
    elapsed_time: {
        fr: "Temps écoulé",
        en: "Elapsed time"
    },
    remaining_time: {
        fr: "Temps restant",
        en: "Remaining time"
    },
    pressure: {
        fr: 'Pression',
        en: "Pressure"
    },
    wind: {
        fr: 'Vent',
        en: "Wind"
    },
    track_temp: {
        fr: 'T° Piste',
        en: "Track temp"
    },
    humidity: {
        fr: 'Humidité',
        en: "Humidity"
    },
    weather: {
        fr: 'Méteo',
        en: "Weather"
    },
    air_temp: {
        fr: "Température de l'air",
        en: "Air temp"
    },
    [WeatherEnum.SUNNY]: {
        fr: 'Ensoleillé',
        en: "Sunny"
    },
    [WeatherEnum.MOSTLY_SUNNY]: {
        fr: 'Dégagé',
        en: "Mostly sunny"
    },
    [WeatherEnum.MOSTLY_CLOUDY]: {
        fr: 'Dégagé',
        en: 'Mostly cloudy'
    },
    [WeatherEnum.CLOUDY]: {
        fr: 'Nuageux',
        en: 'Cloudy'
    },
    [WeatherEnum.PARTLY_CLOUDY]: {
        fr: 'Couvert',
        en: "Partly cloudy"
    },
    [WeatherEnum.CLEAR_DAY]: {
        fr: 'Clair',
        en: "Clear"
    },
    [WeatherEnum.CLEAR_NIGHT]: {
        fr: 'Clair',
        en: "Clear"
    },
    [WeatherEnum.RAIN]: {
        fr: 'Pluie',
        en: "Rain"
    },
    [WeatherEnum.SLEET]: {
        fr: 'Neige',
        en: "Sleet"
    },
    [WeatherEnum.SNOW]: {
        fr: 'Neige',
        en: "Snow"
    },

    lastupdate: {
        fr: 'Mise à jour',
        en: "Latest Update"
    },
    overall: {
        fr: 'Tout',
        en: "Overall"
    },
    overallCategory: {
        fr: 'Tout par catégorie',
        en: "All by category"
    },
    seemore: {
        fr: 'Afficher Plus',
        en: "See More"
    },
    driver: {
        fr: "Pilote",
        en: "Driver"
    },
    team_driver: {
        fr: "Equipe",
        en: "Team"
    },
    car: {
        fr: "Voiture",
        en: "Car"
    },
    tyre: {
        fr: "Pneus",
        en: "Tyre"
    },
    tyre_short: {
        fr: "P",
        en: "T"
    },
    bestlap: {
        fr: "Meil. Tour",
        en: "Best"
    },
    secondbestlap: {
        fr: "2ème Meil. Tour",
        en: "2nd Best"
    },
    rank: {
        fr: "Rank",
        en: "Categorisation"
    },
    nblap: {
        fr: "Tours",
        en: "Laps"
    },
    lastlap: {
        fr: "Der. Tour",
        en: "Last"
    },
    speed: {
        fr: "Vitesse",
        en: "SPD"
    },
    pit_stop: {
        fr: "Arrêts",
        en: "Pits"
    },
    state: {
        fr: "Etat",
        en: "Status"
    },
    avspeed: {
        fr: "Vit. Moy.",
        en: "AV Speed"
    },
    partner_with: {
        fr: "En partenariat avec",
        en: "In partnership with"
    },
    end_session: {
        fr: "Fin de %session% dans",
        en: "End of %session% in"
    },
    share_live: {
        fr: "Partagez en direct",
        en: "Share live"
    },
    days: {
        fr: "Jours",
        en: "Days"
    },
    hours: {
        fr: "HRS",
        en: "HRS"
    },
    mins: {
        fr: "MINS",
        en: "MINS"
    },
    secs: {
        fr: "SECS",
        en: "SECS"
    },
    link_copied: {
        fr: 'Le lien a été copié',
        en: 'The URL has been copied to your clipboard'
    },
    track: {
        fr: 'Piste',
        en: 'Track'
    },
    pits: {
        fr: 'Stands',
        en: 'Pits'
    },
    premium_access: {
        fr: 'Accès Premium',
        en: 'Premium Access',
    },
    category_filter: {
       fr: 'Filtrer par catégorie',
       en: 'Filter by category'
    } ,
    entry_filter: {
       fr: 'Filtrer par voiture',
       en: 'Filter by car'
    } ,
    premium_modal_content: {
        fr: "Pour vous plonger encore plus loin dans l'expérience des 24 Heures du Mans, le Pack Premium vous permettra de suivre la course en DIRECT VIDEO et de visionner les caméras embarquées.<br>" +
            "Le direct vidéo démarrera le jeudi 17 septembre à 14h pour la 2e séance d'essais libres.<br>" +
            "Téléchargez également l’application officielle 24H-LEMANS® et faites partie de la course…",
        en: "To immerse yourself even further into the 24 Hours of Le Mans experience get the Premium Pack. It will allow you to follow the LIVE STREAMING and the onboard cameras.<br>" +
            "The live streaming will be from 17th september at 2:00pm (GMT+2) for the 2nd free practice session.<br>" +
            "Download now the official 24 Hours of Le Mans® app and be part of the race…"
    },
    welcome_wec_login_title: {
        fr: "Créez votre compte pour vivre l'expérience live WEC !",
        en: "Create your account to enjoy the WEC live experience !"
    },
    welcome_wec_buy_title: {
        fr: "L'expérience Live WEC pour les passionnés !",
        en: "The WEC Live experience for the enthusiat fans !"
    },
    welcome_aco_login_title: {
        fr: "Créez votre compte pour vivre l'expérience live 24h !",
        en: "Create your account to enjoy the 24h live experience !"
    },
    welcome_aco_buy_title: {
        fr: "L'expérience Live 24h pour les passionnés !",
        en: "The 24h Live experience for the enthusiat fans !"
    },
    welcome_wec_login_subtitle: {
        fr: "Pour les invités",
        en: "For the guests"
    },
    welcome_wec_buy_subtitle: {
        fr: "Pour les passionés",
        en: "For the enthusiastic fans"
    },
    welcome_aco_members_subtitle: {
        fr: "Pour les membres ACO",
        en: "For the ACO members"
    },
    welcome_wec_login_description: {
        fr: "Connectez-vous à votre compte et bénéficiez de toutes les statistiques en plus du Live Timing.",
        en: "Log in to your account and take advantage of all the statistics in addition to the Live Timing"
    },
    welcome_wec_buy_description: {
        fr: "Pour plus de sensations, découvrez nos packs Course ou Saison, et profitez des vidéos générales ou embarquées !",
        en: "For even more excitement, discover our Race or Season packs, and enjoy the live and onboard videos !"
    },
    welcome_aco_members_description: {
        fr: "Si vous êtes titulaire d'un bouquet vous y donnant droit, activez votre accès Premium !",
        en: "If you have a package that gives you access to it, activate your premium access !"
    },
    welcome_wec_buy_button: {
        fr: "Voir les packs",
        en: "See the packs"
    },
    welcome_wec_login_button: {
        fr: "Connexion",
        en: "Log in"
    },
    welcome_aco_members_button: {
        fr: "Activer mon pack",
        en: "Activate my pack"
    },
    wec_website: {
        fr: "Site FIAWEC",
        en: "FIAWEC website"
    },
    hide: {
        fr: "Masquer",
        en: "Hide"
    },
    acces_premium: {
        fr: "Accès Premium",
        en: "Premium access"
    },
    sector1: {
        fr: 'Secteur 1',
        en: 'Sector 1',
    },
    sector2: {
        fr: 'Secteur 2',
        en: 'Sector 2',
    },
    sector3: {
        fr: 'Secteur 3',
        en: 'Sector 3',
    },
    sector: {
        fr: "Secteur ",
        en: 'Sector '
    },
    best_sector: {
        fr: "Meil. S",
        en: 'Best S'
    },
    ideallap: {
        fr: "Tour idéal",
        en: 'Ideal lap'
    },
    expert_mode: {
        fr: "Mode Expert",
        en: "Expert mode"
    },
    best_sectors: {
        fr: "Meilleurs secteurs",
        en: "Best sectors"
    },
    login_to_access: {
        fr: 'Connectez-vous pour accéder à cette fonctionnalité',
        en: 'Log in to access this feature'
    },
    classLeaders: {
        fr: 'Leaders par catégorie',
        en: 'Class leaders'
    },
    carsState: {
        fr: 'Etat des voitures',
        en: 'Cars state'
    },
    teamCar: {
        fr: 'Équipe / Voiture',
        en: "Team / car"
    },
    class: {
        fr: 'Catégorie',
        en: 'Class'
    },
    flagStatistics: {
        fr: 'Statistiques des drapeaux',
        en: 'Flag statistics'
    },
    hideSidebar: {
        fr: 'Masquer',
        en: 'Hide'
    },
    showSidebar: {
        fr: 'Ouvrir',
        en: 'Show'
    },
    total_pit_time: {
        fr: 'Temps passé au stand',
        en: 'Total pit time'
    },
    descending: {
        fr: 'par ordre décroissant',
        en: 'descending order'
    },
    fullwidth: {
        fr: 'Pleine largeur',
        en: 'Full width'
    },
    drive_time: {
        fr: 'Temps au volant',
        en: 'Drive time'
    },
    start: {
        fr: "Début",
        en: "Start"
    },
    end: {
        fr: "Fin",
        en: "End"
    },
    stint_start: {
        fr: 'Début du relais',
        en: 'Stint start'
    },
    stint_end: {
        fr: 'Fin du relais',
        en: 'Stint end'
    },
    pit_start: {
        fr: 'Début de l\'arrêt',
        en: 'Pit start'
    },
    pit_end: {
        fr: 'Fin de l\'arrêt',
        en: 'Pit end'
    },
    in_progress: {
        fr: 'En cours',
        en: 'In progress'
    },
    duration: {
        fr: "Durée",
        en: "Duration"
    },
    since: {
        fr: "Depuis",
        en: "Since"
    },
    current: {
        fr: "Actuellement",
        en: "Current"
    },
    history: {
        fr: "Historique",
        en: "History"
    },
    time: {
        fr: "Temps",
        en: "Time"
    },
    count: {
        fr: "Nombre",
        en: "Count"
    },
    lap: {
        fr: "Tour",
        en: "Lap"
    },
    laps: {
        fr: "tours",
        en: "laps"
    },
    stint: {
        fr: 'Relais',
        en: 'Stint'
    },
    stints: {
        fr: 'Relais',
        en: 'Stints'
    },
    driver_totals: {
        fr: 'Total par pilote',
        en: 'Driver totals'
    },
    driver_time_share: {
        fr: 'Temps de pilotage par pilote',
        en: 'Drive share by time'
    },
    pit_stats: {
        fr: 'Statistiques des arrêts au stand',
        en: 'Pit stop stats'
    },
    pit_stops: {
        fr: "Nombre d'arrêts",
        en: 'Pit stops'
    },
    longest_stint: {
        fr: 'Relais le plus long',
        en: 'Longest stint'
    },
    shortest_stint: {
        fr: 'Relais le plus court',
        en: 'Shortest stint'
    },
    start_lap: {
        fr: "Tour début",
        en: "Start lap",
    },
    start_time: {
        fr: "Début",
        en: "Start time",
    },
    end_lap: {
        fr: "Tour fin",
        en: "End lap",
    },
    end_time: {
        fr: "Fin",
        en: "End time",
    },
    lap_gap: {
        fr: 'Ecart tour',
        en: 'Lap gap'
    },
    cumulative_gap: {
        fr: 'Ecart cumulé',
        en: 'Cumulative gap'
    },
    vs: {
        fr: 'vs',
        en: 'vs'
    },
    car_comparison: {
        fr: 'Comparaison de voitures',
        en: 'Car comparison'
    },
    stats: {
        fr: 'Statistiques',
        en: 'Stats'
    },
    comparison: {
        fr: 'Comparaison',
        en: 'Comparison'
    },
    select_car: {
        fr: "Sélectionnez une voiture",
        en: "Select a car"
    },
    best_lap_driver: {
        fr: "A réalisé le meilleur tour de la voiture",
        en: "Recorded the car's best lap"
    },
    race_control: {
        fr: 'Direction de course',
        en: 'Race control'
    },
    driver_no_laps: {
        fr: "Ce pilote n'a pas pris le volant",
        en: "Driver yet to drive"
    },
    close: {
        fr: "Fermer",
        en: "Close"
    },
    elmsAccount: {
        fr: "Compte ELMS",
        en: "ELMS Account"
    }
};
export default lang;

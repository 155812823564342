import React, {Component} from 'react';
import {connect} from "react-redux";
import LiveReducerType from "../../type/LiveReducerType";
import RaceEventType from "../../type/RaceEventType";
import {t} from "../../translation/translator";
import {ReferentialCategoryType, ReferentialEntryType, ReferentialSessionType} from "../../type/ReferentialType";
import moment = require("moment");
import ReducerType from "../../type/ReducerType";

declare var reactParameters;

type Props = { lang: string, currentSession: ReferentialSessionType, entries: ReferentialEntryType[], categories: ReferentialCategoryType[] };
type State = { error: boolean, isLoaded: boolean, isLoading: boolean, items: Array<RaceEventType>, intervalKey: NodeJS.Timeout, has_more: boolean, current_entry_number: string, current_category: string }

const storeToProps = (state: ReducerType) => ({
    lang: state.live.lang,
    currentSession: state.live.currentSession,
    entries: state.live.referential.entries,
    categories: state.live.referential.categories
});

export class LiveRaceEventView extends Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isLoading: true,
            items: [],
            intervalKey: null,
            has_more: false,
            current_entry_number: "",
            current_category: ""
        };
    }

    filterEntry(entry_number) {
        this.setState({current_entry_number: entry_number});
    }
    filterCategory(category) {
        this.setState({current_category: category});
    }

    fetchRaceEvents() {
        let session_id = this.props.currentSession.id;
        fetch(reactParameters.apiUrl + "/api/app_aco/event?length=100&session_id=" + session_id + "&offset=" + this.state.items.length)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.events.length > 0) {
                        let concat = this.state.items.concat(result.events);
                        this.setState({
                            isLoaded: true,
                            items: concat,
                            has_more: result.count > concat.length
                        });
                    } else {
                        this.setState({
                            isLoaded: true,
                            isLoading: false,
                        });
                    }
                }, (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                    })
                }
            );
    }

    fetchNewRaceEvents() {
        fetch(reactParameters.apiUrl + "/api/app_aco/event?length=100&session_id=" + this.props.currentSession.id)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.events.length > 0) {
                        if (this.state.items.length > 0) {
                            let nb = 0;
                            let final_nb = 0;
                            if (result.events[0].id != this.state.items[0].id) {
                                result.events.forEach(event => {
                                    if (event.id == this.state.items[0].id) {
                                        final_nb = nb;
                                    } else {
                                        nb++;
                                    }
                                });
                            }
                            if (final_nb > 0) {
                                this.setState({
                                    items: result.events.slice(0, final_nb).concat(this.state.items),
                                });
                            }
                        } else {
                            this.setState({
                                items: result.events,
                            });
                        }
                    }
                }
            );
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (typeof this.props.lang !== 'undefined' && !this.state.isLoading
            && this.state.items.length === 0 || (this.state.items.length > 0 && prevState.items.length > 0 && this.state.items[0].id != prevState.items[0].id)) {
            this.fetchRaceEvents();
        }
    }

    componentDidMount(): void {
        if (typeof this.props.lang !== 'undefined') {
            this.fetchRaceEvents();
        }
        let interval = setInterval(() => {
            this.fetchNewRaceEvents();
        }, 30000);
        this.setState({'intervalKey': interval});
    }

    componentWillUnmount(): void {
        clearInterval(this.state.intervalKey)
    }

    createHtml(string: string) {
        return {__html: string};
    }

    render() {
        const {error, isLoaded, items} = this.state;
        return (
            <div className="race-events-content">
                {error &&
                <div className="status-message">Problem loading data</div>
                }
                {!isLoaded &&
                <div className="status-message"><i className="fa fa-circle-o-notch fa-spin fa-fw"/> Loading ...</div>
                }
                {isLoaded && !error &&
                <>
                    <div className="filters">
                        <select onChange={(e) => this.filterCategory(e.target.value)}>
                            <option value="">{t("category_filter", this.props.lang)}</option>
                            {this.props.categories.map((category) => {
                                return <option value={category.name} key={category.id}>{category.name}</option>
                            })}
                        </select>
                        <select onChange={(e) => this.filterEntry(e.target.value)}>
                            <option value="">{t("entry_filter", this.props.lang)}</option>
                            {this.props.entries.map((entry) => {
                                return <option value={entry.number} key={entry.id}>#{entry.number}</option>
                            })}
                        </select>
                    </div>
                    <div className="race-events-wrapper">
                        <div className="race-events-header">
                            <div className="race-events-header-col">
                                <span className="icon">
                                    <img src="/bundles/front/images/icon-raceflag.png" alt="race flag"/>
                                </span>
                                <span className="title">{t("track", this.props.lang)}</span>
                            </div>
                            <div className="race-events-header-col">
                                <span className="icon">
                                    <img src="/bundles/front/images/icon-gaspump.png" alt="gas pump"/>
                                </span>
                                <span className="title">{t("pits", this.props.lang)}</span>
                            </div>
                        </div>
                        <div className="race-events">

                            {items.map((item) => {
                                    if ("" !== this.state.current_entry_number) {
                                        // l'item doit contenir l'entry :
                                        if (0 === item.entries.length || undefined == item.entries.find(e => e.number == this.state.current_entry_number)) {
                                            return null;
                                        }
                                    }
                                    if ("" !== this.state.current_category) {
                                        // l'item doit contenir l'entry :
                                        if (0 === item.entries.length || undefined == item.entries.find(e => e.category_label == this.state.current_category)) {
                                            return null;
                                        }
                                    }
                                    switch (item.type) {
                                        case 'RACE':
                                            return <article className="race-event-entry race-event-entry-left"
                                                            key={item.id}>

                                                <time className="race-event-time">
                                                    <span>{item.date}</span>
                                                </time>

                                                <div className="race-event-icon">
                                                </div>

                                                <div className="race-event-content">

                                                    <span className="station">
                                                        {item.station}
                                                    </span>
                                                    {item.entries.map(entry =>
                                                        <span key={entry.id} className="category"
                                                              style={{backgroundColor: entry.category_color}}>
                                                            #{entry.number} - {entry.team}
                                                        </span>)
                                                    }

                                                    <div className="content"
                                                         dangerouslySetInnerHTML={this.createHtml(item.keyword)}/>

                                                </div>

                                            </article>
                                        case 'PIT_IN':
                                            return <article className="race-event-entry race-event-entry-right"
                                                            key={item.id}>

                                                <time className="race-event-time">
                                                    <span>{item.date}</span>
                                                </time>

                                                <div className="race-event-icon type-in" title="Pit in">
                                                    IN
                                                </div>

                                                <div className="race-event-content">
                                                    {item.entries.length > 0 &&
                                                    <>
                                                            <span className="category"
                                                                  style={{backgroundColor: item.entries[0].category_color}}>
                                                                {item.entries[0].category_label}
                                                            </span>
                                                        <div className="content">
                                                        <span
                                                            className="vehicle">#{item.entries[0].number} - {item.entries[0].team}</span>
                                                            {item.driver_in &&
                                                            <span className="drivers">
                                                                    {item.driver_in.driver_label}
                                                                </span>
                                                            }
                                                        </div>
                                                    </>
                                                    }
                                                </div>

                                            </article>
                                        case 'PIT_OUT':
                                            return <article className="race-event-entry race-event-entry-right"
                                                            key={item.id}>

                                                <time className="race-event-time">
                                                    <span>{item.date}</span>
                                                </time>

                                                <div className="race-event-icon type-out" title="Pit out">
                                                    OUT
                                                </div>

                                                <div className="race-event-content">
                                                    {item.entries.length > 0 &&
                                                    <>
                                                            <span className="category"
                                                                  style={{backgroundColor: item.entries[0].category_color}}>
                                                                {item.entries[0].category_label}
                                                            </span>
                                                        <div className="content">
                                                        <span
                                                            className="vehicle">#{item.entries[0].number} - {item.entries[0].team}</span>
                                                            {item.driver_in &&
                                                            <span className="drivers">
                                                                        {item.driver_in.driver_label}
                                                                {item.hasDriverChanged &&
                                                                <>
                                                                    <i className="fa fa-arrow-right" aria-hidden="true"/>
                                                                    {item.driver_out.driver_label}
                                                                </>
                                                                }
                                                                    </span>
                                                            }
                                                        </div>
                                                    </>
                                                    }

                                                    <div className="icons">
                                                        {item.hasDriverChanged &&
                                                        <span className="icon icon-driver-changed">
                                                                <img src="/bundles/front/images/helmet.png"
                                                                     alt="helmet"/>
                                                            </span>
                                                        }
                                                        {item.fuel > 0 &&
                                                        <span className="icon icon-fuel">
                                                                <img src="/bundles/front/images/gasoil.png"
                                                                     alt="gasoil"/>
                                                            </span>
                                                        }
                                                        {item.tire &&
                                                        <span className="icon icon-tire">
                                                                <img src="/bundles/front/images/tyre.png" alt="tire"/>
                                                            </span>
                                                        }
                                                    </div>

                                                </div>

                                            </article>
                                    }
                                }
                            )}

                        </div>
                    </div>
                </>
                }

                {this.state.has_more &&
                <a className="load-more" onClick={() => this.fetchRaceEvents()}>{t('seemore', this.props.lang)}</a>
                }
            </div>
        )
    }

}

const LiveRaceEvent = connect(storeToProps, null)(LiveRaceEventView);
export default LiveRaceEvent;
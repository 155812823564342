import React, {Component} from 'react';
import {connect} from "react-redux";
import LiveReducerType from "../../type/LiveReducerType";
import ActionLiveType from "../../reducer/live/ActionLiveType";
import ReducerType from "../../type/ReducerType";


const storeToProps = (state: ReducerType) => ({
    lang: state.live.lang,
});
const storeDispatchToProps = (dispatch) => ({
    switchLang: (lang: string) => dispatch(ActionLiveType.switchLang(lang)),
});
type Props = {
    lang: string,
    switchLang: (lang: string) => null,
};


class LocaleSwitch extends Component<Props> {

    constructor(props) {
        super(props);
        this.switchLang = this.switchLang.bind(this);
    }

    switchLang(lang: string) {
        let current_url = window.location.href;
        let current_lang = ('fr' === lang) ? 'en' : 'fr';
        let new_url = current_url.replace(current_lang, lang);
        window.history.pushState("changing locale to " + lang, "Live 24h - " + lang.toUpperCase(), new_url);
        this.props.switchLang(lang);
    }

    render() {
        let lang = this.props.lang;
        return (
            <div className="locale">

                <button>{this.props.lang}</button>
                <ul>
                    <li><a className={lang === 'en' ? "active" : ""} onClick={() => this.switchLang("en")}>EN</a></li>
                    <li><a className={lang === 'fr' ? "active" : ""} onClick={() => this.switchLang("fr")}>FR</a></li>
                </ul>
            </div>
        )
    }
}

const LocaleSwitcher = connect(storeToProps, storeDispatchToProps)(LocaleSwitch);
export default LocaleSwitcher;
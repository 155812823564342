import React, {Component} from 'react';
import WeatherEntry from "../fragment/WeatherEntry";
import Sharing from "../fragment/Sharing";
import RaceFlag from "../fragment/RaceFlag";
import { connect } from "react-redux";
import {t} from "../../translation/translator";
import ReducerType from "../../type/ReducerType";
import RaceControlPage from "../fragment/RaceControl/RaceControlPage";
import {Button} from "../fragment/Button";
import {ArrowUp, TextAlignJustified} from "akar-icons";
import {NavigationEnum} from "../../enum/NavigationEnum";
import ActionTunnel from "../../reducer/tunnel/ActionTunnel";
import ActionLiveType from "../../reducer/live/ActionLiveType";

const storeToProps = (state: ReducerType) => ({
    weather: state.live.live.params.weather,
    lang:state.live.lang,
    member: state.tunnel.member,
    noLogin: state.live.noLogin,
});
const storeDispatchToProps = (dispatch) => ({
    showTunnel: () => dispatch(ActionTunnel.toggleTunnel(false)),
    navigate: (view: string, lang: string, params: string = "") => dispatch(ActionLiveType.navigate(view, lang, params)),
    showModalRequestLogin: () => dispatch(ActionLiveType.updateShowModalRequestLogin(true))
});
type Props = ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>;
type State = {toggleDropDownWeather: boolean,toggleDropDownRC: boolean};

class CourseInfoShareDropDownView extends Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            toggleDropDownWeather: false,
            toggleDropDownRC: false,
        }
    }

    onClickDropDownWeather(e) {
        e.preventDefault();
        this.setState({toggleDropDownWeather: !this.state.toggleDropDownWeather});
    }
    onClickDropDownRC(e = null) {
        if (e)
            e.preventDefault();
        this.setState({toggleDropDownRC: !this.state.toggleDropDownRC});
    }

    goToRaceControl() {
        if (null === this.props.member && false === this.props.noLogin) {
            this.props.showModalRequestLogin();
            this.props.showTunnel();
        } else {
            this.props.navigate(NavigationEnum.RACE_CONTROL, this.props.lang)
        }
    }
    render() {
        return (
            <div className="fcy fcy-responsive race-over">
                <RaceFlag class="top" checkVisibility mobileMode />
                <a href="#" onClick={(e) => this.onClickDropDownWeather(e)} className={((this.state.toggleDropDownWeather) ? "show":"")+" weather-entry-selected weather-entry js-dropdown-toggle"}>
                    <div className="label">{t('weather', this.props.lang)}</div>
                    <div className="info">{t(this.props.weather, this.props.lang)}</div>
                </a>
                <div className={((this.state.toggleDropDownWeather) ? "show":"")+" js-dropdown"}>
                    <WeatherEntry />
                    <div className="social-sharing">
                        <div> {t("share_live", this.props.lang).toUpperCase()}</div>
                        <Sharing lang={this.props.lang} />
                    </div>
                </div>
                <a href="#" onClick={(e) => this.onClickDropDownRC(e)} className={((this.state.toggleDropDownRC) ? "show":"")+" weather-entry-selected weather-entry js-dropdown-toggle mt-2"}>
                    <div className="label">{t('race_control', this.props.lang)}</div>
                    <div className="info">{t(this.props.weather, this.props.lang)}</div>
                </a>
                <div className={((this.state.toggleDropDownRC) ? "show":"")+" js-dropdown"}>
                   <RaceControlPage nbMax={5}></RaceControlPage>
                    <div className="justify-content-around d-flex mt-1">
                        <Button onClick={() => this.goToRaceControl()}>
                            <TextAlignJustified/>
                            <span className="label"> {t('see_all',this.props.lang)}</span></Button>
                        <Button onClick={() => this.onClickDropDownRC()}>
                            <ArrowUp/>
                            <span className="label"> {t('close',this.props.lang)}</span></Button>
                    </div>
                </div>
            </div>

        )
    }
}
const CourseInfoShareDropDown = connect(storeToProps, storeDispatchToProps)(CourseInfoShareDropDownView);
export default CourseInfoShareDropDown;

import {actionTypeEnum} from "./actionTypeEnum";
import {connectToSocket, disconnectFromSocket} from "./reducer";
import ParticipantLaps from "../../../../../../../../server/common/Models/alkamel/ParticipantLaps";

/**
 * Définit les actions possibles qu'offre le reducer Laps
 */
export default class ActionLaps {
    static readonly updateLaps = (laps: ParticipantLaps[]) => ({type: actionTypeEnum.UPDATE_LAPS, data: laps});
    static readonly connectToLapsSocket = () => connectToSocket()
    static readonly disconnectFromLapsSocket = () => disconnectFromSocket()
};

export enum WeatherEnum {
    NONE = "N/A",
    CLEAR_DAY = "clear-day",
    CLEAR_NIGHT = "clear-night",
    CLOUDY = "cloudy",
    PARTLY_CLOUDY = "partly_cloudy",
    PARTLY_CLOUDY_DAY = "partly-cloudy-day",
    PARTLY_CLOUDY_NIGHT = "partly-cloudy-night",
    RAIN = "rain",
    SLEET = "sleet",
    SNOW = "snow",
    SUN = "sun",
    SUNNY = "sunny",
    MOSTLY_SUNNY = "mostly_sunny",
    MOSTLY_CLOUDY = "mostly_cloudy",
    WIND = "wind",
    CHANCE_OF_RAIN = "chance_of_rain",
    CHANCE_OF_SNOW = "chance_of_snow",
    CHANCE_OF_STORM = "chance_of_storm",
    CHANCE_OF_TSTORM = "chance_of_tstorm",
    DUST = "dust",
    FLURRIES = "flurries",
    FOG = "fog",
    HAZE = "haze",
    ICY = "icy",
    MIST = "mist",
    SMOKE = "smoke",
    STORM = "storm",
    THUNDERSTORM = "thunderstorm"
}
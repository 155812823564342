import React from "react";
import ReducerType from "../../../type/ReducerType";
import {connect} from "react-redux";
import {t} from "../../../translation/translator";

const storeToProps = (state: ReducerType) => ({
    rc: state.race_control,
    lang: state.live.lang
});

type Props = ReturnType<typeof storeToProps> & {nbMax: number};
const RaceControlPageView = (props: Props) => {
    let messages = props.rc.messages;
    messages.sort((m1, m2) => m2.dayTime - m1.dayTime);

    if (props.nbMax) {
        messages = messages.slice(0,props.nbMax);
    }
    if (0 === messages.length) {
        return <div className="race-control-empty">
            {t('race_control_empty',props.lang)}
        </div>;
    }

    return (
        <div className="race-control-messages">

            {messages.map((message, index) => {
                    return (
                        <div key={index}
                             className="message"
                            style={{backgroundColor: message.backgroundColor, color: message.foregroundColor}}>
                            <div className="time">{message.localTime}</div>
                            <div className="text">{message.text}</div>
                        </div>)
                }
            )}
        </div>
    );
}

export default connect(storeToProps, null)(RaceControlPageView);
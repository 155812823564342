import ReducerType from "../../type/ReducerType";
import {connect} from 'react-redux';
import {t} from "../../translation/translator";
import Select, {components} from 'react-select';
import React from "react";
import {ReferentialEntryType} from "../../type/ReferentialType";

type Props = {
    selected: ReferentialEntryType,
    selectedCallback: (entry: ReferentialEntryType) => void,
    className: string
}

const storeToProps = (state: ReducerType) => ({
    entries: state.live.referential.entries,
    categories: state.live.referential.categories,
    teams: state.live.referential.teams,
    lang: state.live.lang,
});
const storeDispatchToProps = (dispatch) => ({
});

const CarSelectorView = (props: Props & ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>) => {

    let categoriesOrder = [];
    for (let index in props.categories)
    {
        categoriesOrder[props.categories[index].id] = props.categories[index].order
    }
    let entries = props.entries.sort((e1,e2) => {
        if (!categoriesOrder[e1.category] || !categoriesOrder[e1.category]) {
            return 0;
        }
        if (categoriesOrder[e1.category] != categoriesOrder[e2.category]) {
            return categoriesOrder[e1.category] - categoriesOrder[e2.category]
        } else {
            // meme categ : par numéro
            return parseInt(e1.number) - parseInt(e2.number)
        }
    })

    function select(entry: ReferentialEntryType) {
        if (props.selectedCallback) {
            props.selectedCallback(entry);
        }
    }
    let selectedCategoryColor = props.selected ? props.categories.find(c => props.selected.category === c.id).color : '#FFFFFF';
    return (
        <div className={"car-select " + props.className } style={{
            backgroundColor:selectedCategoryColor
        }}>
            <Select
                placeholder={t("select_car",props.lang)}
                value={props.selected}
                options={entries.filter(e => e !== null)}
                onChange={(value:any) => select(value)}
                components={{
                    SingleValue: ((props1 => {
                        let entry:ReferentialEntryType = props1.data;
                        let team = props.teams.find(t => t.id === entry.team);
                        let label = "#" + entry.number + " - " + team.name;
                        return (
                            <div>
                                {label}
                            </div>
                        )
                    })),
                    Option: ((props1) =>
                    {
                        const {
                            innerRef,
                            innerProps,
                        } = props1;
                        let entry:ReferentialEntryType = props1.data;
                        let team = props.teams.find(t => t.id === entry.team);
                        let category = props.categories.find(c => c.id === entry.category);
                        let label = "#" + entry.number + " - " + team.name;
                        return (
                        <div
                            ref={innerRef}
                            {...innerProps}
                            className="car-item"
                            style={{backgroundColor:category.color}}>
                            {label}
                        </div>
                        )
                    })
                }}
                />
        </div>
    );
}
export default connect(storeToProps, storeDispatchToProps)(CarSelectorView);
import {actionTypeEnum} from "./actionTypeEnum";
import TunnelReducerType from "../../type/TunnelReducerType";
import {GetReducerType} from "../../type/ReducerType";
import ApiProvider from "../../dataProvider/ApiProvider";
import ActionTunnel from "./ActionTunnel";
import LiveReducerType from "../../type/LiveReducerType";
import ActionLiveType from "../live/ActionLiveType";
import {Cookies} from "react-cookie";
import { push } from 'connected-react-router'

/**
 * Données initiales
 */
const initialState = new TunnelReducerType();

/**
 * Reducer Tunnel
 * @param state
 * @param action
 */
const reducer = (state = initialState, action: { type: actionTypeEnum, data: any }) => {

    switch (action.type) {

        case actionTypeEnum.setMember:
            return {...state, member: action.data};

        case actionTypeEnum.displayIdentification:
            return {...state, displayIdentification: action.data}

        case actionTypeEnum.setHasRacePack:
            return {...state, hasCurrentRacePack: action.data}

        case actionTypeEnum.toggleTunnel:
            return {...state, tunnelHidden: action.data}



        default:
            return state;
    }
};


export default reducer;


export function initTunnelDisplayFromCookie() {
    return async function (dispatch: Function, getState: GetReducerType) {
        let data = getCookie();
        if (true === data) {
            dispatch(ActionTunnel.toggleTunnel(true));
        }
    }
}

function getCookie(): boolean {
    let cookie = (new Cookies().get("hideTunnel"));
    return "1" === cookie;
}

/**
 * Récupère les données du membre connecté
 * @param cbSuccess
 */
export function getMember(cbSuccess: () => void = null) {
    return async function (dispatch: Function, getState: GetReducerType) {
        try {
            let result = await ApiProvider.get("fr/api/account/me");
            dispatch(ActionTunnel.setMember(result.data));
            if (null !== cbSuccess) {
                return cbSuccess();
            }
        } catch (e) {
            if (e == ApiProvider.NEED_CONNECT) {
                //ras
            } else {
                //Toast.show({text: "Erreur réseau pour la synchronisation du référentiel", type: "danger", duration: 5000, position: "top"});
                console.log(e);
            }
        }
    }
}

/**
 * Déconnecte le membre
 * @param cbSuccess
 */
export function logout(cbSuccess: () => void = null) {
    return async function (dispatch: Function, getState: GetReducerType) {
        try {
            let result = await ApiProvider.get("fr/api/logout");
            if (ApiProvider.STATUS_OK === result.status) {
                dispatch(ActionTunnel.setMember(null));
            }
            // on supprime les cookies :
            const cookies = new Cookies();
            cookies.set("rp", 0);
            cookies.remove("_wa");
            cookies.set("expert",0);

            if (null !== cbSuccess) {
                return cbSuccess();
            }
        } catch (e) {
            if (e == ApiProvider.NEED_CONNECT) {
                //ras
            } else {
                //Toast.show({text: "Erreur réseau pour la synchronisation du référentiel", type: "danger", duration: 5000, position: "top"});
                console.log(e);
            }
        }
    }
}
import React from "react";
import ParamsType from "./Models/ParamsType";
import ReducerType from "../../type/ReducerType";
import {connect} from "react-redux";
import WelcomeMessage from "./Components/WelcomeMessage";

const storeToProps = (state: ReducerType) => ({
    member: state.tunnel.member,
    displayIdentification: state.tunnel.displayIdentification,
    buying: state.tunnel.buying
});
const storeDispatchToProps = (dispatch) => ({});

/**
 * Point d'entrée du module
 */
const TunnelView = (props: ParamsType & ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>) => {
    return <WelcomeMessage {...props} />
}
export default connect(storeToProps, storeDispatchToProps)(TunnelView);
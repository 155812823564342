
class Footer {
    constructor($view){
        this.view = $view;

        this.facebook = $view.find("a[data-sn='facebook']")
        this.twitter = $view.find("a[data-sn='twitter']")
        this.youtube = $view.find("a[data-sn='youtube']")
        this.linkedin = $view.find("a[data-sn='linkedin']")
        this.instagram = $view.find("a[data-sn='instagram']")

        this.url = $view.data('url');
        this.init();
    }
    init(){
        let self = this;
        $.get(this.url,(data) => {
            for (let sn in data) {
                if (data[sn]) {
                    self[sn].show();
                    self[sn].attr('href',data[sn]);
                } else {
                    self[sn].hide();
                }
            }
        });
    }

}
module.exports = Footer;
import Entry from "../../../../../../../../server/common/Models/live/Entry";
import ParticipantStints from "../../../../../../../../server/common/Models/alkamel/ParticipantStints";
import {actionTypeEnum} from "./actionTypeEnum";
import {connectToSocket, disconnectFromSocket, updateDriverStints} from "./reducer";
import {DriverStints, ReferentialEntryType} from "../../type/ReferentialType";

/**
 * Définit les actions possibles qu'offre le reducer Stints
 */
export default class ActionStints {
    static readonly updateStints = (stints: ParticipantStints[]) => ({type: actionTypeEnum.UPDATE_STINTS, data: stints});
    static readonly updateDriverStints = (stints: DriverStints[]) => ({type: actionTypeEnum.UPDATE_DRIVER_STINTS, data: stints});
    static readonly calculateDriverStints = (data: ParticipantStints[]) => updateDriverStints(data)
    static readonly connectToStintsSocket = () => connectToSocket()
    static readonly disconnectFromStintsSocket = () => disconnectFromSocket()
};

import {actionTypeEnum} from "./actionTypeEnum";
import {connectToSocket, disconnectFromSocket} from "./reducer";
import RaceControlMessage from "../../../../../../../../server/common/Models/alkamel/RaceControlMessage";

/**
 * Définit les actions possibles qu'offre le reducer
 */
export default class ActionRaceControl {
    static readonly updateRaceControl = (msg: RaceControlMessage[]) => ({type: actionTypeEnum.UPDATE_RACE_CONTROL, data: msg});
    static readonly connectToRaceControlSocket = (socket) => connectToSocket(socket)
    static readonly disconnectFromRaceControlSocket = () => disconnectFromSocket()
};

import React, {Component} from 'react';
import {t} from "../../translation/translator";
import {connect} from "react-redux";
import LiveReducerType from "../../type/LiveReducerType";
import ActionLiveType from "../../reducer/live/ActionLiveType";
import ReducerType from "../../type/ReducerType";


const storeToProps = (state: ReducerType) => ({
    lang: state.live.lang,
    localTime: state.live.localTime
});
const storeDispatchToProps = (dispatch) => ({
    switchTime: (local: boolean) => dispatch(ActionLiveType.switchTime(local)),
});
type Props = {
    lang: string,
    localTime: boolean,
    switchTime: (local: boolean) => null
};
class BrandTimeSwitchView extends Component<Props> {
    constructor(props) {
        super(props);
        this.switchTime = this.switchTime.bind(this)
    }
    switchTime() {
        this.props.switchTime(!this.props.localTime);
    }
    render() {
        let key = this.props.localTime ? "local_time" : "race_time";
        return (
            <div className="brand-time-switch">
                <div className="local-time" onClick={this.switchTime}>
                    {t(key, this.props.lang)}
                </div>
            </div>
        )
    }
}

const BrandTimeSwitch = connect(storeToProps, storeDispatchToProps)(BrandTimeSwitchView);
export default BrandTimeSwitch;
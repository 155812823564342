import {JsonObject, JsonProperty} from "json2typescript";
import {NumberConverter} from "../../../src/Converter/NumberConverter";
import {TimeDurationToSecond} from "../../../src/Converter/TimeDurationToSecond";
import {FlagsEnum} from "../../Enum/live/FlagsEnum";
import Weather from "../alkamel/Weather";
import WeatherSessionData from "../alkamel/WeatherSessionData";

@JsonObject("params")
export default class Params {

    @JsonProperty("remaining", NumberConverter, true)
    remaining: number = 0;

    percentProgressLive: number = 0; // int between 0 and 100

    sessionType: number = 0;

    @JsonProperty("svg", String, true)
    svg: number = 0;

    @JsonProperty("eventName")
    eventName: string = "N/A";

    @JsonProperty("sessionId", NumberConverter)
    sessionId: number = 0;

    @JsonProperty("sessionName")
    sessionName: string = "N/A";

    @JsonProperty("timestamp", NumberConverter)
    timestamp: number = 0;

    @JsonProperty("safetycar")
    safetyCar: string = "N/A";

    @JsonProperty("weather")
    weather: string;

    @JsonProperty("airTemp", NumberConverter)
    airTemp: number = 0;

    @JsonProperty("trackTemp", NumberConverter)
    trackTemp: number = 0;

    @JsonProperty("humidity", NumberConverter)
    humidity: number = 0;

    @JsonProperty("pressure", NumberConverter)
    pressure: number = 0;

    @JsonProperty("windSpeed", NumberConverter)
    windSpeed: number = 0;

    @JsonProperty("windDirection", NumberConverter)
    windDirection: number = 0;

    @JsonProperty("elapsed", TimeDurationToSecond)
    elapsedTime: number = 0;

    @JsonProperty("etatcourse")
    raceState: FlagsEnum = undefined;

    startTime: number = 0;

    duration: number = 0;

    /**
     * Durée totale d'arrêt de la session
     */
    stopTime: number = 0;

    /**
     * Si la session est arrêtée, heure depuis laquelle c'est le cas
     */
    stoppedSinceTime: number = 0;

    replay: boolean = false;

}
import {JsonConverter, JsonCustomConvert} from "json2typescript";

@JsonConverter
export class BooleanConverter implements JsonCustomConvert<boolean> {

    deserialize(data: any): boolean {
        return (data == "true");
    }

    serialize(data: boolean): any {
        return data;
    }
}
import React, {Component, useEffect, useState} from 'react';

type Props = {
    items: Array<any>,
    defaultText: string,
    callback: (id: number) => void,
    defaultValue?: number,
    required?: boolean
};

export const CustomSelect = (props: Props) => {
    let [id, setId] = useState(null !== props.defaultValue ? props.defaultValue : -1);
    let [open, setOpen] = useState(false);

    useEffect(() => {
        if (null !== props.defaultValue) {
            props.callback(props.defaultValue);
        }
    },[])

    function clickCategory(e: React.MouseEvent, id: number) {
        e.preventDefault();
        setId(id);
        setOpen(false)
        props.callback(id);
    }

    function toggle() {
        setOpen(!open);
    }

    let text = "";
    let selected = props.items.find((item) => item.id == id);
    if (undefined !== selected) {
        text = (selected.text);
    } else {
        text = props.defaultText;
    }
    return (
        <div className="select">
            <div className="select-styled" onClick={toggle}>{text}</div>
            <ul className="select-options" style={{display: open ? "block" : "none"}}>
                {!props.required &&
                <li onClick={(e) => clickCategory(e, -1)}>{props.defaultText}</li>
                }
                {props.items.map((cat, index) => {
                    return <li
                        key={cat.id}
                        onClick={(e) => clickCategory(e, cat.id)}
                    >
                        {cat.text}
                    </li>
                })}
            </ul>
        </div>
    )
}
import React from "react";
import ReducerType from "../../../type/ReducerType";
import {connect} from "react-redux";
import {t} from "../../../translation/translator";
import {Flags} from "./Flags";
import {ClassLeaders} from "./ClassLeaders";
import {CarsState} from "./CarsStats";

const storeToProps = (state: ReducerType) => ({
    progressFlagState: state.live.live.progressFlagState,
    elapsedTime: state.live.live.params.elapsedTime,
    totalTime: state.live.live.params.duration,
    lang: state.live.lang,
    params: state.live.live.params,
    categories: state.live.referential.categories,
    darkMode: state.live.darkMode,
    currentResult: state.live.live.entries.filter((entry, index) => null !== entry).sort((a, b) => a.ranking - b.ranking)
});

type Props = ReturnType<typeof storeToProps>;
const SessionStatsView = (props: Props) => {
    return (
        <div className="row no-mobile-scroll">
            <div className="col-lg-8">
                <h2>{t("classLeaders", props.lang)}</h2>
                <ClassLeaders {...props} />
                <h2>{t("carsState", props.lang)}</h2>
                <CarsState {...props} />
            </div>
            <div className="col-lg-4">
                <h2>{t("flagStatistics", props.lang)}</h2>
                <Flags {...props} />
            </div>
        </div>

    );
}

export default connect(storeToProps, null)(SessionStatsView);
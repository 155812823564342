class CustomSelect {
    constructor($view) {
        this.view = $view;
        this.bind()
    }
    bind(){
        let self = this;
        let $this = this.view, options = $(this.view).children('option'), numberOfOptions = options.length;

        $this.addClass('select-hidden');
        $this.wrap('<div class="select"></div>');
        $this.after('<div class="select-styled"></div>');

        let $styledSelect = $this.next('div.select-styled');
        $styledSelect.text($this.children('option').eq(0).text());

        let $list = $('<ul />', {
            'class': 'select-options'
        }).insertAfter($styledSelect);

        for (let i = 0; i < numberOfOptions; i++) {
            $('<li />', {
                text: options.eq(i).text(),
                rel: options.eq(i).val(),
                selected: options.eq(i)[0].defaultSelected,
            }).appendTo($list);
        }

        let $listItems = $list.children('li');

        let defaultSelected = $listItems.filter((index, item) => {
            return $(item).attr('selected') === 'selected'
        });

        if(defaultSelected.length > 0){
            let val = $(defaultSelected).text();
            $styledSelect.text(val);
        }

        $styledSelect.click(function(e) {
            e.stopPropagation();
            $('div.select-styled.active').not(this).each(function(){
                $(this).removeClass('active').next('ul.select-options').hide();
            });
            $(this).toggleClass('active').next('ul.select-options').toggle();
        });

        $listItems.click(function(e) {
            e.stopPropagation();
            $styledSelect.text($(this).text()).removeClass('active');
            $this.val($(this).attr('rel'));
            $list.hide();
            let selected = options.filter((index, option) => {
                return $(option).attr('value') === $(e.currentTarget).attr('rel')
            });
            $(options).each((i, el)=> {
                el.defaultSelected = false;
            });
            $(selected)[0].defaultSelected = true;
            let url = $(self.view).data("url");
            let season = $(e.currentTarget).attr('rel');
            Api.GET(url.replace("-1", season));
        });

        $(document).click(function() {
            $styledSelect.removeClass('active');
            $list.hide();
        });

    }
}
module.exports = CustomSelect;
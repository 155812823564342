import React, {Component} from 'react';
import {connect} from "react-redux";
import LiveReducerType from "../../../type/LiveReducerType";
import {t} from "../../../translation/translator";
import {
    ChronoTypeEnum, SessionTypeToChronoTypeEnum
} from "../../../../../../../../../server/common/Enum/live/SessionTypeEnum";
import ReducerType from "../../../type/ReducerType";

type Props = ReturnType<typeof storeToProps> & {
    onSort : ((sorting: string) => void),
    sorting: string,
    ascending: boolean
};

type State = {
    catSelect: number,
    flipActive: boolean
};
const storeToProps = (state: ReducerType) => {
    let session = state.live.currentSession;
    let chronoType = null;
    if (session) {
        if (session.chrono_type) {
            chronoType = session.chrono_type;
        } else {
            // on "devine" depuis le type de session
        }
    }
    return {
        lang: state.live.lang,
        chronoType: chronoType,
        expert: state.live.expert,
        bestSectorMode: state.live.bestSectorMode
    }
};

class LiveTableHeaderView extends Component<Props, State> {

    renderQualifHeader() {
        let sectorPrefix = this.props.bestSectorMode ? "best_sector" : "sector";
        let sectorField = this.props.bestSectorMode ? "bestTimeSector" : "";
        let sortableClass = this.props.bestSectorMode ? "sortable " : "";
        return <tr>
            <th className="pos align-middle">Pos</th>
            <th className="ranking align-middle" scope="col">#</th>
            <th className="status align-middle">{t("state", this.props.lang)}</th>
            <th className="class align-middle">{t("cat_pos", this.props.lang)}</th>
            <th className="team align-middle">{t("team", this.props.lang)}</th>
            <th className="driver align-middle">{t("driver", this.props.lang)}</th>
            {!this.props.expert &&
                <th className="car-img align-middle" scope="col"></th>
            }
            <th className="car align-middle" scope="col">{t("car", this.props.lang)}</th>
            {!this.props.expert &&
                <th className="tyres align-middle" scope="col">{t("tyre_short", this.props.lang)}</th>
            }
            <th className="last align-middle">{t("av_time", this.props.lang)}</th>
            <th className="last align-middle">GAPS</th>
            {this.props.expert &&
                <>
                    <th className={"gap align-middle " + sortableClass + ((this.props.sorting === sectorField + 1) ? " current-sort" : "")} scope="col">
                        {this.props.bestSectorMode ?
                            <a onClick={() => this.props.onSort(sectorField + 1)}>{t(sectorPrefix, this.props.lang)}1</a> :
                            <>{t(sectorPrefix, this.props.lang)}1</>
                        }
                    </th>
                    <th className={"gap align-middle " + sortableClass + ((this.props.sorting === sectorField + 2) ? " current-sort" : "")} scope="col">
                        {this.props.bestSectorMode ?
                            <a onClick={() => this.props.onSort(sectorField + 2)}>{t(sectorPrefix, this.props.lang)}2</a> :
                            <>{t(sectorPrefix, this.props.lang)}2</>
                        }
                    </th>
                    <th className={"gap align-middle " + sortableClass + ((this.props.sorting === sectorField + 3) ? " current-sort" : "")} scope="col">
                        {this.props.bestSectorMode ?
                            <a onClick={() => this.props.onSort(sectorField + 3)}>{t(sectorPrefix, this.props.lang)}3</a> :
                            <>{t(sectorPrefix, this.props.lang)}3</>
                        }
                    </th>

                    {this.props.bestSectorMode &&
                        <th className={"best align-middle "  + sortableClass + (this.props.sorting === "ideallap" ? " current-sort" : "") } scope="col"><a onClick={() => this.props.onSort("ideallap")}>{t("ideallap", this.props.lang)}</a></th>
                    }
                </>
            }
            <th className="last align-middle">{t("av_d1l1", this.props.lang)}</th>
            <th className="last align-middle">{t("av_d2l1", this.props.lang)}</th>
            <th className="last align-middle">{t("lastlap", this.props.lang)}</th>
        </tr>
    }


    renderNotQualifHeader() {
        let sectorPrefix = this.props.bestSectorMode ? "best_sector" : "sector";
        let sectorField = this.props.bestSectorMode ? "bestTimeSector" : "";
        let sortableClass = this.props.bestSectorMode ? "sortable " : "";
        return <tr>
            <th className="pos align-middle" scope="col">Pos</th>
            <th className="ranking align-middle" scope="col">#</th>
            <th className="status align-middle" scope="col">{t("state", this.props.lang)}</th>
            <th className="class align-middle" scope="col">{t("cat_pos", this.props.lang)}</th>
            <th className="team align-middle" scope="col">{t("team", this.props.lang)}</th>
            <th className="driver align-middle" scope="col">{t("driver", this.props.lang)}</th>
            {!this.props.expert &&
                <th className="car-img align-middle" scope="col"></th>
            }
            <th className="car align-middle" scope="col">{t("car", this.props.lang)}</th>
            {!this.props.expert &&
                <th className="tyres  align-middle" scope="col">{t("tyre_short", this.props.lang)}</th>
            }
            <th className={"best align-middle" + ((this.props.sorting === "bestlapTime") ? " current-sort " : " ")} scope="col">
                <a onClick={() => this.props.onSort("bestlapTime")}>
                    {t("bestlap", this.props.lang)}
                </a>
            </th>
            <th className="last align-middle" scope="col">GAPS</th>

            {this.props.expert &&
                <>
                    <th className={"gap align-middle " + sortableClass + ((this.props.sorting === sectorField + 1) ? " current-sort" : "")} scope="col">
                        {this.props.bestSectorMode ?
                            <a onClick={() => this.props.onSort(sectorField + 1)}>{t(sectorPrefix, this.props.lang)}1</a> :
                            <>{t(sectorPrefix, this.props.lang)}1</>
                        }
                    </th>
                    <th className={"gap align-middle " + sortableClass + ((this.props.sorting === sectorField + 2) ? " current-sort" : "")} scope="col">
                        {this.props.bestSectorMode ?
                            <a onClick={() => this.props.onSort(sectorField + 2)}>{t(sectorPrefix, this.props.lang)}2</a> :
                            <>{t(sectorPrefix, this.props.lang)}2</>
                        }
                    </th>
                    <th className={"gap align-middle " + sortableClass + ((this.props.sorting === sectorField + 3) ? " current-sort" : "")} scope="col">
                        {this.props.bestSectorMode ?
                            <a onClick={() => this.props.onSort(sectorField + 3)}>{t(sectorPrefix, this.props.lang)}3</a> :
                            <>{t(sectorPrefix, this.props.lang)}3</>
                        }
                    </th>

                    {this.props.bestSectorMode &&
                        <th className={"best align-middle " + sortableClass + (this.props.sorting === "ideallap" ? " current-sort" : "") } scope="col"><a onClick={() => this.props.onSort("ideallap")}>{t("ideallap", this.props.lang)}</a></th>
                    }
                </>
            }
            <th className={"last align-middle " + sortableClass  + ((this.props.sorting === "lastlapTime") ? " current-sort " : " ")} scope="col">
                <a onClick={() => this.props.onSort("lastlapTime")}>
                    {t("lastlap", this.props.lang)}
                </a>
            </th>
            <th className="lap align-middle" scope="col">{t("nblap", this.props.lang)}</th>
            <th className="spd align-middle" scope="col">{t("speed", this.props.lang)}</th>
            <th className="pit align-middle" scope="col">{t("pit_stop", this.props.lang)}</th>
        </tr>
    }


    renderQualif2LapsHeader() {
        let sectorPrefix = this.props.bestSectorMode ? "best_sector" : "sector";
        let sectorField = this.props.bestSectorMode ? "bestTimeSector" : "";
        let sortableClass = this.props.bestSectorMode ? "sortable " : "";
        return <tr>
            <th className="pos align-middle" scope="col">Pos</th>
            <th className="ranking align-middle" scope="col">#</th>
            <th className="status align-middle" scope="col">{t("state", this.props.lang)}</th>
            <th className="class align-middle" scope="col">{t("cat_pos", this.props.lang)}</th>
            <th className="team align-middle" scope="col">{t("team", this.props.lang)}</th>
            <th className="driver align-middle" scope="col">{t("driver", this.props.lang)}</th>
            {!this.props.expert &&
                <th className="car-img align-middle" scope="col"></th>
            }
            <th className="car align-middle" scope="col">{t("car", this.props.lang)}</th>
            {!this.props.expert &&
                <th className="tyres  align-middle" scope="col">{t("tyre_short", this.props.lang)}</th>
            }
            <th className={"best align-middle" + ((this.props.sorting === "bestlapTime") ? " current-sort " : " ")} scope="col">
                <a onClick={() => this.props.onSort("bestlapTime")}>
                    {t("bestlap", this.props.lang)}
                </a>
            </th>
            <th className="last align-middle" scope="col">GAPS</th>

            {this.props.expert &&
                <>
                    <th className={"gap align-middle " + sortableClass + ((this.props.sorting === sectorField + 1) ? " current-sort" : "")} scope="col">
                        {this.props.bestSectorMode ?
                            <a onClick={() => this.props.onSort(sectorField + 1)}>{t(sectorPrefix, this.props.lang)}1</a> :
                            <>{t(sectorPrefix, this.props.lang)}1</>
                        }
                    </th>
                    <th className={"gap align-middle " + sortableClass + ((this.props.sorting === sectorField + 2) ? " current-sort" : "")} scope="col">
                        {this.props.bestSectorMode ?
                            <a onClick={() => this.props.onSort(sectorField + 2)}>{t(sectorPrefix, this.props.lang)}2</a> :
                            <>{t(sectorPrefix, this.props.lang)}2</>
                        }
                    </th>
                    <th className={"gap align-middle " + sortableClass + ((this.props.sorting === sectorField + 3) ? " current-sort" : "")} scope="col">
                        {this.props.bestSectorMode ?
                            <a onClick={() => this.props.onSort(sectorField + 3)}>{t(sectorPrefix, this.props.lang)}3</a> :
                            <>{t(sectorPrefix, this.props.lang)}3</>
                        }
                    </th>

                    {this.props.bestSectorMode &&
                        <th className={"best align-middle " + sortableClass + (this.props.sorting === "ideallap" ? " current-sort" : "") } scope="col"><a onClick={() => this.props.onSort("ideallap")}>{t("ideallap", this.props.lang)}</a></th>
                    }
                </>
            }
            <th className={"last align-middle " + sortableClass  + ((this.props.sorting === "secondBestlapTime") ? " current-sort " : " ")} scope="col">
                <a onClick={() => this.props.onSort("secondBestlapTime")}>
                    {t("secondbestlap", this.props.lang)}
                </a>
            </th>
            <th className={"last align-middle " + sortableClass  + ((this.props.sorting === "lastlapTime") ? " current-sort " : " ")} scope="col">
                <a onClick={() => this.props.onSort("lastlapTime")}>
                    {t("lastlap", this.props.lang)}
                </a>
            </th>
            <th className="lap align-middle" scope="col">{t("nblap", this.props.lang)}</th>
            <th className="spd align-middle" scope="col">{t("speed", this.props.lang)}</th>
            <th className="pit align-middle" scope="col">{t("pit_stop", this.props.lang)}</th>
        </tr>
    }

    renderRaceHeader() {
        let sectorPrefix = this.props.bestSectorMode ? "best_sector" : "sector";
        let sectorField = this.props.bestSectorMode ? "bestTimeSector" : "";
        let sortableClass = this.props.bestSectorMode ? "sortable " : "";
        return <tr>
            <th className="pos align-middle" scope="col">Pos</th>
            <th className="ranking align-middle" scope="col">#</th>
            <th className="status align-middle" scope="col">{t("state", this.props.lang)}</th>
            <th className="class align-middle" scope="col">{t("cat_pos", this.props.lang)}</th>
            <th className="team align-middle" scope="col">{t("team", this.props.lang)}</th>
            <th className="driver align-middle" scope="col">{t("driver", this.props.lang)}</th>
            {!this.props.expert &&
            <th className="car-img align-middle" scope="col"></th>
            }
            <th className="car align-middle" scope="col">{t("car", this.props.lang)}</th>
            {!this.props.expert &&
            <th className="tyres  align-middle" scope="col">{t("tyre_short", this.props.lang)}</th>
            }
            <th className="lap align-middle" scope="col">{t("nblap", this.props.lang)}</th>
            <th className="classement align-middle" scope="col">GAPS</th>
            <th className="int align-middle" scope="col">Int</th>
            {this.props.expert &&
            <>
                <th className={"gap align-middle " + sortableClass + (this.props.sorting === sectorField + 1 ? " current-sort" : "")} scope="col"><a onClick={() => this.props.onSort(sectorField + 1)}>{t(sectorPrefix, this.props.lang)}1</a></th>
                <th className={"gap align-middle " + sortableClass +(this.props.sorting === sectorField + 2 ? " current-sort" : "")} scope="col"><a onClick={() => this.props.onSort(sectorField + 2)}>{t(sectorPrefix, this.props.lang)}2</a></th>
                <th className={"gap align-middle " + sortableClass +(this.props.sorting === sectorField + 3 ? " current-sort" : "")} scope="col"><a onClick={() => this.props.onSort(sectorField + 3)}>{t(sectorPrefix, this.props.lang)}3</a></th>
                {this.props.bestSectorMode &&
                <th className={"best align-middle sortable " + (this.props.sorting === "ideallap" ? " current-sort" : "") } scope="col"><a onClick={() => this.props.onSort("ideallap")}>{t("ideallap", this.props.lang)}</a></th>
                }
            </>
            }
            <th className="last align-middle" scope="col">{t("lastlap", this.props.lang)}</th>
            <th className="best align-middle" scope="col">{t("bestlap", this.props.lang)}</th>
            <th className="spd align-middle" scope="col">{t("speed", this.props.lang)}</th>
            <th className="pit align-middle" scope="col">{t("pit_stop", this.props.lang)}</th>
        </tr>
    }


    render() {
        return (
            <thead>
            {this.props.chronoType == ChronoTypeEnum.Race &&
                this.renderRaceHeader()
            }
            {(this.props.chronoType == ChronoTypeEnum.Q24H || this.props.chronoType == ChronoTypeEnum.FP) &&
                this.renderNotQualifHeader()
            }
            {this.props.chronoType == ChronoTypeEnum.QWEC &&
                this.renderQualifHeader()
            }
            {this.props.chronoType == ChronoTypeEnum.Q2LAPS &&
                this.renderQualif2LapsHeader()
            }
            </thead>
        )
    }
}

const LiveTableHeader = connect(storeToProps, null)(LiveTableHeaderView);
export default LiveTableHeader;
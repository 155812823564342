import React, {Component} from 'react';
import {ReferentialSessionType} from "../../type/ReferentialType";
import {connect} from "react-redux";
import ActionLiveType from "../../reducer/live/ActionLiveType";
import {getDate, getLabel} from '../../translation/translator';
import {SessionStatusEnum} from "../../../../../../../../server/common/Enum/live/SessionTypeEnum";
import ReducerType from "../../type/ReducerType";


type Props = {
    currentSession: ReferentialSessionType,
    sessions: Array<ReferentialSessionType>,
    lang: string,
    switchSession: (sessionId: number) => null,
    clearEntries: () => null,
    closeResponsive: () => void };

const storeToProps = (state: ReducerType) => (
    {
        lang: state.live.lang,
        sessions: state.live.referential.sessions,
        currentSession: state.live.currentSession
    });
type State = { toggleDropDown: boolean };

const storeDispatchToProps = (dispatch) => ({
    switchSession: (sessionId: number) => dispatch(ActionLiveType.switchSession(sessionId)),
    clearEntries: () => dispatch(ActionLiveType.clearEntries()),
});

export class AccordionView extends Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            toggleDropDown: false
        }

    }

    switchSession(e, session: ReferentialSessionType) {
        e.preventDefault();
        if (session.status != SessionStatusEnum.PENDING) {
            this.props.switchSession(session.id);
            this.props.clearEntries();
           // this.setState({toggleDropDown: !this.state.toggleDropDown});
            if (this.props.closeResponsive) {
                this.props.closeResponsive();
            }
        }
    }

    onClickDropDown(e) {
        e.preventDefault();
        this.setState({toggleDropDown: !this.state.toggleDropDown});
    }

    renderSession(session?: ReferentialSessionType, withDate: boolean = false) {

        let status = '';
        let name = getLabel(session,'name', this.props.lang);



        name = name.replace("Essai Libre ","FP");
        name = name.replace("Essais Libres ","FP");
        name = name.replace("Essais Libre ","FP");
        name = name.replace("Free Practice ","FP");
        name = name.replace("Qualification - ","Q-");
        name = name.replace("Essais qualificatifs","Q ");
        name = name.replace("Qualification ","Q-");
        name = name.replace("Qualifying - ","Q-");
        name = name.replace("Qualifs - ","Q-");
        name = name.replace("Qualifying ","Q-");
        // name = name.replace("session ","");
        // name = name.replace("Session ","");
        name = name.replace("Driver Collective","");
        name = name.replace("Collectif - Pilote","");
        name = name.replace("Pilote Bronze test collectif","Test Bronze");
        name = name.replace("Pilote Bronze - Test Collectif","Test Bronze");

        if (session) {
            switch (session.status) {
                case SessionStatusEnum.LIVE:
                    status = "live";
                    break;
                case SessionStatusEnum.OVER:
                    status = "over";
                    break;
                case SessionStatusEnum.PENDING:
                    status = "pending";
                    break;
            }
        }
        return (
            <div className={"session " + status}>
            <span className="picto-session"></span>
            <span className="session-infos" title={name}>
                <span className="session-name ">{name}</span>
                {withDate && session &&
                <span className="session-date">{getDate(session.start_date,this.props.lang)}</span>
                }
            </span>
            </div>
        );
    }
    render() {
        return (
            <>
                <a href="#" onClick={(e) => this.onClickDropDown(e)}
                   className={((this.state.toggleDropDown) ? "show" : "") + " session-link js-dropdown-toggle current-session"}>
                    {this.renderSession(this.props.currentSession)}
                </a>
                <div className={((this.state.toggleDropDown) ? "show" : "") + " js-dropdown"}>
                    {this.props.sessions.map((session: ReferentialSessionType) => {
                        return (<a href="#" onClick={(e) => this.switchSession(e, session)} key={session.id}
                                   className={"session-link " + (this.props.currentSession && session.id == this.props.currentSession.id ? "current" : "")}>
                            {this.renderSession(session, true)}
                        </a>);
                    })}
                </div>
            </>
        );
    }
}

const Accordion = connect(storeToProps, storeDispatchToProps)(AccordionView);
export default Accordion;
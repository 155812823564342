import React, {Component} from 'react';
import {connect} from "react-redux";
import LiveReducerType from "../../type/LiveReducerType";
import ActionLiveType from "../../reducer/live/ActionLiveType";
import ReducerType from "../../type/ReducerType";
import {ReactSVG} from "react-svg";


const storeToProps = (state: ReducerType) => ({
    dark: state.live.darkMode,
});
const storeDispatchToProps = (dispatch) => ({
    toggleDark: (dark: boolean) => dispatch(ActionLiveType.toggleDark(dark)),
});
type Props = {
    dark: boolean,
    toggleDark: (dark: boolean) => null,
};


class DarkSwitch extends Component<Props> {

    constructor(props) {
        super(props);
        this.toggleDark = this.toggleDark.bind(this);
    }

    toggleDark(dark: boolean) {
        this.props.toggleDark(dark);
    }

    render() {
        return (
            <div className="locale dark-toggle">
                <button onClick={() => this.toggleDark(!this.props.dark)}>
                    <img alt="Toggle dark mode"
                        src={"/bundles/front/images/dark_light.png"}/>
                </button>
            </div>
        )
    }
}

const DarkSwitcher = connect(storeToProps, storeDispatchToProps)(DarkSwitch);
export default DarkSwitcher;
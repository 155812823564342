import React, {Component, useState} from 'react';
import BrandTimeSwitch from "../fragment/BrandTimeSwitch";
import Accordion from "../fragment/Accordion";
import BrandRace from "../fragment/BrandRace";
import LocaleSwitch from "../fragment/LocaleSwitch";
import {ChampionshipType, ReferentialSessionType} from "../../type/ReferentialType";
import LiveReducerType from "../../type/LiveReducerType";
import {LiveSideBarView} from "./LiveSideBar";
import {connect} from "react-redux";
import {t} from "../../translation/translator";
import ActionLiveType from "../../reducer/live/ActionLiveType";
import ReducerType from "../../type/ReducerType";
import {NavigationEnum} from "../../enum/NavigationEnum";
import NavItem from "../fragment/NavItem";
import RwdNavItem from "../fragment/RwdNavItem";
import {useCookies} from "react-cookie";
import ActionTunnel from "../../reducer/tunnel/ActionTunnel";
import DarkSwitch from "../fragment/DarkSwitch";

declare var reactParameters;

type Props = {
    lang: string,
    open: boolean,
    liveHome: string,
    session: number,
    sessions: Array<ReferentialSessionType>,
    selectedMenu: string,
    championship: ChampionshipType,
    forLemans: boolean,
    tunnelHidden: boolean,
    hasRacePack: boolean,
    closeResponsive: () => void,
    showTunnel: () => void,
    navigate: (view: string, lang: string, params: string) => void,
} & ReturnType<typeof storeDispatchToProps> & ReturnType<typeof storeToProps>;

type State = {
    currentSession: ReferentialSessionType,
};

const storeToProps = (state: ReducerType) => ({
    sessions: state.live.referential.sessions,
    championship: state.live.championship,
    lang: state.live.lang,
    selectedMenu: state.live.selectedMenu,
    forLemans: state.live.forLemans,
    member: state.tunnel.member,
    tunnelHidden: state.tunnel.tunnelHidden,
    hasRacePack: state.tunnel.hasCurrentRacePack,
    race: state.live.referential.race
});

const storeDispatchToProps = (dispatch) => ({
    showTunnel: () => dispatch(ActionTunnel.toggleTunnel(false)),
    navigate: (view: string, lang: string, params: string = "") => dispatch(ActionLiveType.navigate(view, lang, params)),
});

export const ResponsiveMenuView = (props: Props) => {

    let [currentSession, setCurrentSession] = useState(props.sessions.find(s => s.id == props.session))
    const [cookies, setCookie] = useCookies(['hideTunnel', 'sidebarCollapsed']);

    function _showTunnel() {
        setCookie('hideTunnel', 0);
        props.showTunnel();
        props.navigate("live", props.lang, "")
        props.closeResponsive();
    }
    return (
        <nav className={"main-nav-rwd " + ((props.open) ? "expanded" : "")}>
            {props.tunnelHidden && !props.hasRacePack &&
            <div className="brand-race">
                <a onClick={(e) => {
                    e.preventDefault();
                    _showTunnel();
                }} href="#">
                            <span>
                                {t("premium_access", props.lang)}
                            </span>
                </a>
            </div>
            }
            <div className="accordion-menu">
                <Accordion currentSession={currentSession} sessions={props.sessions}
                           closeResponsive={props.closeResponsive}/>
            </div>
            <RwdNavItem page={NavigationEnum.LIVE} svg="play" title="live_overview" lang={props.lang}
                        free={true} onClick={() => {
                if (props.closeResponsive) {
                    props.closeResponsive();
                }
            }}/>
            {props.forLemans && props.race && props.race.id === 4689 &&
            <>
                {/*<RwdNavItem page={NavigationEnum.KEYNOTE} svg="newspaper" title="live_keynote" lang={props.lang}*/}
                {/*            member={props.member} onClick={() => {*/}
                {/*    if (props.closeResponsive) {*/}
                {/*        props.closeResponsive();*/}
                {/*    }*/}
                {/*}}/>*/}
                <RwdNavItem page={NavigationEnum.RACE_EVENTS} svg="clock" title="live_raceevent" lang={props.lang}
                            member={props.member} onClick={() => {
                    if (props.closeResponsive) {
                        props.closeResponsive();
                    }
                }}/>

            </>
            }
            <RwdNavItem page={NavigationEnum.SESSION_STATS} svg="stats" title="session_stats" lang={props.lang}
                        member={props.member} onClick={() => {
                if (props.closeResponsive) {
                    props.closeResponsive();
                }
            }}/>
            <RwdNavItem page={NavigationEnum.PIT_TIME} svg="drill" title="live_pittime" lang={props.lang}
                        member={props.member} onClick={() => {
                if (props.closeResponsive) {
                    props.closeResponsive();
                }
            }}/>
            <RwdNavItem page={NavigationEnum.DRIVE_TIME} svg="wheel" title="live_drivetime" lang={props.lang}
                        member={props.member} onClick={() => {
                if (props.closeResponsive) {
                    props.closeResponsive();
                }
            }}/>
            <RwdNavItem page={NavigationEnum.CAR_STATS} svg="car" title="live_carstats" lang={props.lang}
                        member={props.member} onClick={() => {
                if (props.closeResponsive) {
                    props.closeResponsive();
                }
            }}/>
            <RwdNavItem page={NavigationEnum.CAR_COMPARISON} svg="compare" title="live_carcomparisons" lang={props.lang}
                        member={props.member} onClick={() => {
                if (props.closeResponsive) {
                    props.closeResponsive();
                }
            }}/>
            <RwdNavItem page={NavigationEnum.RACE_CONTROL} svg="compare" title="live_racecontrol" lang={props.lang}
                        member={props.member} onClick={() => {
                if (props.closeResponsive) {
                    props.closeResponsive();
                }
            }}/>
            <BrandTimeSwitch lang={props.lang}/>
            <DarkSwitch/>
            <LocaleSwitch/>
            <div className="bottom">
                <a href="#" className="legal-mentions">
                    Legal mentions
                </a>
                <div className="copyright">
                    © European Le Mans Series
                </div>
                <div className="social-icons">
                    <a href="#">
                        <i className="fa fa-facebook fa-fw" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                        <i className="fa fa-twitter fa-fw" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                        <i className="fa fa-instagram fa-fw" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                        <i className="fa fa-youtube-play fa-fw" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                        <i className="fa fa-linkedin fa-fw" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </nav>
    )
}

const ResponsiveMenu = connect(storeToProps, storeDispatchToProps)(ResponsiveMenuView);
export default ResponsiveMenu;
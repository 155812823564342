import React, {useState} from 'react';
import {connect} from "react-redux";
import ReducerType from "../../../type/ReducerType";
import {t} from "../../../translation/translator";
import ActionLiveType from "../../../reducer/live/ActionLiveType";
import {CustomSelect} from "../CustomSelect";
import {addGlobalCategoryPositions, getColumnName, getResults} from "./middleware";
import {ClassificationType, PredictiveRowType} from "../../../type/ReferentialType";
import {ClassificationTypeEnum} from "../../../../../../../../../server/common/Enum/live/ClassificationTypeEnum";
import {CircleChevronLeft} from "akar-icons";

declare var reactParameters;

const storeToProps = (state: ReducerType) => {
    return {
        classifications: state.live.referential.classifications.sort((a, b) => a.ponderation - b.ponderation),
        lang: state.live.lang,
        referential: state.live.referential,
        results: addGlobalCategoryPositions(state.live.live.entries)
    }
};

const storeDispatchToProps = (dispatch) => ({
    togglePredictive: (enable: boolean) => dispatch(ActionLiveType.togglePredictive(enable)),
    updateClassifications: (ref: ClassificationType[]) => dispatch(ActionLiveType.updateClassifications(ref))
});

type Props = ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>;

const PredictiveView = (props: Props) => {

    if (props.classifications.length == 0) {
        fetch(reactParameters.refUrl + "classifications/" + props.referential.race.id + ".json").then((response) => {
            response.json().then((ref) => {
                props.updateClassifications(ref);
            })
        });
    }

    let [classificationIndex, setClassificationIndex] = useState(0);

    let title_field = "title" + props.lang;
    let currentClassification = props.classifications.find((item, index) => index === classificationIndex);

    function renderHeader() {
        if (undefined === currentClassification || !currentClassification.data.championship.sessions) {
            return <></>;
        }
        return <>
            <tr>
                <th colSpan={2} className="pos align-middle">Pos</th>
                {currentClassification.type === ClassificationTypeEnum.TEAM &&
                    <th key="number" className="ranking align-middle" scope="col">#</th>
                }
                <th className="driver align-middle">{getColumnName(currentClassification, props.lang)}</th>
                {currentClassification.data.championship.sessions.map((session, index) => {
                    return <th key={index} className="align-middle race-name" colSpan={2}>{session.event_name}</th>
                })}
                <th className="align-middle">{t("total", props.lang)}</th>
            </tr>
            <tr className="subheader">

                <th colSpan={2} className="pos align-middle">(prev.)</th>
                {currentClassification.type === ClassificationTypeEnum.TEAM &&
                    <th className="ranking align-middle" scope="col">&nbsp;</th>
                }
                <th className="driver align-middle">&nbsp;</th>


                {currentClassification.data.championship.sessions.map((session, index) => {
                    return <>
                        <th key={"race" + index} className="align-middle points-type race">{t('race', props.lang)}</th>
                        <th key={"pole" + index} className="align-middle points-type pole">{t('pole', props.lang)}</th>
                    </>
                })}
                <th className="align-middle points-type">{t("predictive", props.lang)}</th>
            </tr>
        </>
    }

    function getData() {
        let raceIndex = props.referential.races.findIndex((race, index) => race.id === props.referential.race.id);
        let rtlmIndex = props.referential.races.findIndex((race, index) => race.name_fr === "Road To Le Mans");

        //TODO le raceIndex est en fait l'index de la session Course courante parmi toutes les session Course

        if (rtlmIndex >= 0 && raceIndex > rtlmIndex) {
            // si on a passé RTLM, on ajoute un (double course)
            raceIndex++;
        }
        // en 2023, pour la coupe du monndne Hypercar, les 2 premieres courses ne comptent pas
        //TODO supprimer en 2024
        if (currentClassification && currentClassification.titleen == "2023 FIA World Cup for Hypercar Teams") {
            raceIndex -= 2;
        }
        let data = getResults(props.referential, props.results, currentClassification, raceIndex)
        return data;
    }

    function renderRow(row: PredictiveRowType, index: number) {
        let arrowClass = "";
        if (row.new_position < row.old_position) {
            arrowClass = "going-up";
        } else if (row.new_position > row.old_position) {
            arrowClass = "going-down";
        }
        if (!currentClassification.data.championship.sessions) {
            return <></>
        }
        return <tr key={index}>
            <td className={arrowClass + " pos just-arrow numeric"}>&nbsp;</td>
            <td className="pos numeric">{row.new_position} ({row.old_position})</td>
            {currentClassification.type === ClassificationTypeEnum.TEAM &&
                <td className="ranking numeric">{row.number}</td>
            }
            <td className="driver">{row.name}</td>

            {row.points.map((points, index) => {
                    return <>
                        <td className={"align-middle points-type numeric race " + (points.predictive ? " predictive" : "")}
                            key={"race" + index}>
                            <div><span>{points.race}</span></div>
                        </td>
                        <td className={"align-middle points-type numeric pole " + (points.predictive ? " predictive" : "")}
                            key={"pole" + index}>{points.pole}</td>
                    </>
                }
            )}

            {currentClassification.data.championship.sessions.map((session, index) => {
                if (index < row.points.length) {
                    return null;
                }

                return <>
                    <td className={"align-middle points-type numeric race predictive"} key={"next-race" + index}>
                        <div><span>0</span></div>
                    </td>
                    <td className={"align-middle points-type numeric pole predictive"} key={"next-pole" + index}>0</td>
                </>
            })}

            <td className="align-middle points-type numeric total">{row.total}</td>
        </tr>;
    }

    let data = getData();
    return (
        <div className="predictive part-table">

            <div className="table-header">
                <CustomSelect
                    items={props.classifications.map((item, index) =>
                        ({id: index, text: item[title_field]}))}
                    defaultText=""
                    required={true}
                    callback={(id: number) => setClassificationIndex(id)}
                    defaultValue={classificationIndex}
                />

                <a className="back" onClick={() => props.togglePredictive(false)}>
                    <div/>
                    <CircleChevronLeft/>
                    <span className="label">{t("back_live", props.lang)}</span>
                </a>
            </div>

            <div className="table-content table-race">
                <table className="table table-striped" style={{position: 'relative'}}>
                    <thead>
                    {renderHeader()}
                    </thead>

                    <tbody>
                    {data.map((row, index) => renderRow(row, index))}
                    </tbody>
                </table>
            </div>

        </div>);
}

const Predictive = connect(storeToProps, storeDispatchToProps)(PredictiveView);
export default Predictive;
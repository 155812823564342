import React, {Component, useEffect} from 'react';
import VideoDisplay from "../fragment/VideoDisplay";
import {CourseInfoShare} from "./CourseInfoShare";
import CourseInfoShareDropDown from "./CourseInfoShareDropdown";
import LiveTable from "./LiveTable";
import {VideosType} from "../../type/ReferentialType";
import {connect} from "react-redux";
import RaceFlag from "../fragment/RaceFlag";
import WeatherEntry from "../fragment/WeatherEntry";
import {t} from "../../translation/translator";
import Sharing from "../fragment/Sharing";
import ActionLiveType from "../../reducer/live/ActionLiveType";
import MultiVideoDisplay from "../fragment/MultiVideoDisplay";
import ReducerType from "../../type/ReducerType";
import ActionTunnel from "../../reducer/tunnel/ActionTunnel";
import Tunnel from "../Tunnel/Tunnel";
import {isMobile, isMobileOnly} from "react-device-detect";
import Predictive from "../fragment/predictive/Predictive";
import ChannelType from "../../type/ChannelType";
import {SessionStatusEnum} from "../../../../../../../../server/common/Enum/live/SessionTypeEnum";

declare var reactParameters;

type ParamsType = {
    loginUrl: string,
    packsUrl: string,
    acoUrl: string
};

type Props = ParamsType & ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>;

const storeToProps = (state: ReducerType) => ({
    predictive: state.live.predictive,
    currentSession: state.live.currentSession,
    currentRace: state.live.referential.race,
    lang: state.live.lang,
    championship: state.live.championship,
    connected: state.live.connected,
    configUrl: state.live.configUrl,
    videos: state.live.videos,
    forLemans: state.live.forLemans,
    member: state.tunnel.member,
    hasRacePack: state.tunnel.hasCurrentRacePack,
    tunnelHidden: state.tunnel.tunnelHidden,
    channels: state.live.channels,
    replay: state.live.live.params.replay,
    darkMode: state.live.darkMode,
});

const storeDispatchToProps = (dispatch) => ({
    setVideos: (videos: VideosType, lang: string) => {
        dispatch(ActionLiveType.setVideos(videos));
        if (videos.channels && videos.channels.length > 0) {
            // on crée les channels aussi pour le multivideo :
            let channels: ChannelType[] = videos.channels.map((value, index) => {
                return {
                    attr: null,
                    entrynumber: value.number,
                    picture: value.picture,
                    url: value.url
                };
            });
            // // on insère les flux fr/en que si l'url n'est pas null ou vide
            if (null !== videos.fr && '' !== videos.fr) {
                channels.splice(0, 0, {url: videos.fr, picture: videos.picture_fr, default_fr: true});
            }
            if (null !== videos.en && '' !== videos.en) {
                channels.splice(0, 0, {url: videos.en, picture: videos.picture_en, default_en: true});
            }

            // On met la channel par défaut en fonction de la locale
            let id = 0;
            let channelUrl = channels[0].url;
            for (var i = 0; i < channels.length; i++) {
                if ((undefined !== channels[i].default_fr && lang === 'fr')
                    || (channels[i].default_en && lang === 'en')){
                    channelUrl = channels[i].url
                    id = i;
                    break;
                }
            }
            dispatch(ActionLiveType.setChannels(channels));
            dispatch(ActionLiveType.setChannelId(id));
            dispatch(ActionLiveType.setChannel(channelUrl));

        }
    },
    getMember: () => dispatch(ActionTunnel.getMember()),
});

class LiveContentView extends Component<Props, any> {

    constructor(props: Props) {
        super(props);

    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<any>, nextContext: any): boolean {
        if (nextProps.currentSession != this.props.currentSession) {
            var request = new Request(this.props.configUrl + "?id=" + nextProps.currentSession.id);
            fetch(request)
                .then((response) => {
                    response.json().then((config) => {
                        this.props.setVideos(config, this.props.lang);
                    })
                });
        }
        return true;
    }

    render() {
        let video = false;
        if (this.props.videos) {
            video = (this.props.lang == "fr" ? !!this.props.videos.fr : !!this.props.videos.en) || (this.props.videos.channels && this.props.videos.channels.length > 0);
        }
        let multiVideo = this.props.forLemans ||
            (this.props.channels && this.props.channels.length >= 2 && !this.props.replay) ||
            (this.props.championship.id == 1 && false === reactParameters.elms && !this.props.replay);
        if (multiVideo && this.props.currentSession && false === reactParameters.elms) {
            // pour Le Mans/WEC, on se base sur l'ID de la session en plus de la connexion
            video = this.props.connected
                &&
                (reactParameters.sessionIdsWithVideo.includes(this.props.currentSession.id) ||
                    reactParameters.sessionTypesWithVideo.includes(this.props.currentSession.type_id.toString())
                );

        }

        const mainAreaWithVideo = <>
            <div className="col-lg-9 col-md-12 col-sm-12">
                {multiVideo ?
                    <MultiVideoDisplay/>
                    :
                    <VideoDisplay/>
                }
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12">
                <CourseInfoShare lang={this.props.lang}/>
                <CourseInfoShareDropDown/>
            </div>
        </>;

        const mainAreaWithTable = <>
            <div className="col-lg-3 col-md-12 col-sm-12">
                <div className="fcy fullwidth">
                    <RaceFlag class="top" checkVisibility mobileMode={false}/>
                </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="fcy fullwidth">
                    <WeatherEntry/>
                </div>
                <CourseInfoShareDropDown/>

            </div>
            <div className="col-lg-2 col-md-12 col-sm-12">
                <div className="fcy fullwidth">
                    <div className="social-sharing">
                        <div> {t("share_live", this.props.lang).toUpperCase()}</div>
                        <div className="icons">
                            <Sharing lang={this.props.lang}/>
                        </div>
                    </div>
                </div>
            </div>
        </>;
        let mainArea = null;
        // si on est en WEC, tunnel si on ne l'a pas masqué (on check aussi qu'on n'est pas en ELMS, forcé sur le champ WEC pour le sim)
        if (1 === this.props.championship.id && (!this.props.replay) && (this.props.currentSession && SessionStatusEnum.OVER !== this.props.currentSession.status) && false === reactParameters.elms) {
            // si le membre est connecté, qu'une course est en cours et que le membre y a accès : mode vidéo
            if ((null !== this.props.member && this.props.hasRacePack) || (!this.props.hasRacePack && true === this.props.tunnelHidden)) {
                if (video) {
                    mainArea = mainAreaWithVideo;
                } else {
                    mainArea = mainAreaWithTable;
                }
            } else {
                mainArea = <>
                    <div className="col-lg-9 col-md-12 col-sm-12 tunnel-container">
                        <Tunnel loginUrl={this.props.loginUrl} packsUrl={this.props.packsUrl}
                                acoUrl={this.props.acoUrl}/>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12">
                        <CourseInfoShare lang={this.props.lang}/>
                        <CourseInfoShareDropDown/>
                    </div>
                </>;
            }

        } else {
            if (video && ((1 === this.props.championship.id && (this.props.currentSession && SessionStatusEnum.OVER !== this.props.currentSession.status)) || 1 !== this.props.championship.id)) {
                mainArea = mainAreaWithVideo;
            } else {
                mainArea = mainAreaWithTable;
            }
        }
        const rolexStyle = {
            width: "210px",
            height: "70px",
            border: 0,
            margin: 0,
            padding: 0,
            overflow: "hidden",
            scroll: 'none'
        };

        return (
            <div className={"live-content" + (this.props.darkMode ? " dark" : " light")}>
                <div className="part part-video">
                    <div className="container-fluid">
                        <div className="row">
                            {mainArea}
                        </div>
                    </div>
                </div>
                {true === this.props.predictive &&
                    <Predictive/>
                }
                {false === this.props.predictive &&
                    <LiveTable/>
                }
            </div>
        )
    }
}

const LiveContent = connect(storeToProps, storeDispatchToProps)(LiveContentView);
export default LiveContent;
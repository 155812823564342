import {connect} from 'react-redux';
import React from "react";
import ReducerType from "../../type/ReducerType";
import {ReferentialEntryType} from "../../type/ReferentialType";

type Props = {
    entry: ReferentialEntryType
}
const storeToProps = (state: ReducerType) => ({
    categories: state.live.referential.categories,
    teams: state.live.referential.teams,
    brands: state.live.referential.brands,
    cars: state.live.referential.cars,
});

const EntryCartoucheView = (props: Props & ReturnType<typeof storeToProps>) => {
    let team = props.teams.find((team) => team.id === props.entry.team)
    let car = props.cars.find((car) => car.id === props.entry.vehicle)
    let brand = props.brands.find((brand) => brand.id === props.entry.brand)
    let category = props.categories.find((cat) => cat.id === props.entry.category)

    return <div className="cartouche"  style={{borderColor:category.color}}>
        <div className="number" style={{backgroundColor:category.color}}>{props.entry.number}</div>
        <div className="cartouche-content">
            <div className="team-name">{team ? team.name : ''}</div>
            <div className="car-name">{brand ? brand.title : ''} {car ? car.name : ''}</div>
        </div>
    </div>
}
export default connect(storeToProps,null)(EntryCartoucheView)
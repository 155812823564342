import React from "react";
import ReducerType from "../../../type/ReducerType";
import {connect} from 'react-redux';
import {t} from "../../../translation/translator";
import ActionTunnel from "../../../reducer/tunnel/ActionTunnel";
import ParamsType from "../Models/ParamsType";
import {Block} from "akar-icons"
import {useCookies} from "react-cookie";


const storeToProps = (state: ReducerType) => ({
    member: state.tunnel.member,
    lang: state.live.lang,
    forLeMans: state.live.forLemans
});
const storeDispatchToProps = (dispatch) => ({
    displayIdentification: (data: boolean) => {
        dispatch(ActionTunnel.displayIdentification(data))
    },
    hideTunnel: () => {
        // set the cookie to hide it next time too

        dispatch(ActionTunnel.toggleTunnel(true))
    },
});

/**
 * Composant Message d'accueil : soit pour se logger ou acheter, soit juste pour acheter
 */
const WelcomeMessageView = (props: ParamsType & ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>) => {
    let login = props.loginUrl;
    if ("fr" === props.lang) {
        login = login.replace('/en','/fr');
    }
    const [cookies, setCookie] = useCookies(['hideTunnel']);
    function hideTunnel() {
        setCookie('hideTunnel',1);
        props.hideTunnel();
    }
    let alias = props.forLeMans ? "aco" : "wec";
    let titleKey = null === props.member ? "welcome_" + alias + "_login_title" : "welcome_" + alias + "_buy_title";
    let messageConnexion = <div>
        <div className="text">
            <span className="subtitle">{t('welcome_wec_login_subtitle', props.lang)}</span>
            <span className="description">{t('welcome_wec_login_description', props.lang)}</span>
        </div>
        <a className="button" href={login}>{t('welcome_wec_login_button', props.lang)}</a>
    </div>
    let messageAchat = <div>
        <div className="text">
            <span className="subtitle">{t('welcome_wec_buy_subtitle', props.lang)}</span>
            <span className="description">{t('welcome_wec_buy_description', props.lang)}</span>
        </div>
        <a className="button packs" href={props.packsUrl}>{t('welcome_wec_buy_button', props.lang)}</a>
    </div>
    let messageACO = <div>
        <div className="text">
            <span className="subtitle">{t('welcome_aco_members_subtitle', props.lang)}</span>
            <span className="description">{t('welcome_aco_members_description', props.lang)}</span>
        </div>
        <a className="button packs" href={props.acoUrl}>{t('welcome_aco_members_button', props.lang)}</a>
    </div>


    return <a href="https://fiawec.tv">
        <img src={"/bundles/front/images/wec/2023_block_app_" + props.lang + ".png"} />
    </a>

    return <div className="welcome">
        <a className="hide" href="#" onClick={(e) => {
            e.preventDefault();
            hideTunnel();
            (window as any).initialHeadOffset = null;
        }}>
            {t("hide", props.lang)}
            <Block/>
        </a>
        {/*<div className="intro">*/}
        {/*    <span className="lock"></span>*/}
        {/*    <div className="title">{t(titleKey, props.lang)}</div>*/}
        {/*</div>*/}
        <div className="actions">
            {!props.forLeMans &&
                <a href="https://fiawec.tv">
                    <img src={"/bundles/front/images/wec/2023_block_app_" + props.lang + ".png"} />
                </a>
            }
            {/*{null === props.member ? messageConnexion : null}*/}
            {/*{messageAchat}*/}
            {props.forLeMans && messageACO}
        </div>

    </div>;
};
export default connect(storeToProps, storeDispatchToProps)(WelcomeMessageView);
export default class ConstantLiveType {
    static readonly UPDATE_SOCKET = "updateSocket";
    static readonly UPDATE_SOCIAL_NETWORKS = "updateSN";
    static readonly UPDATE_LIVE = "updateLive";
    static readonly UPDATE_PARAMS = "updateParams";
    static readonly UPDATE_FLAGS = "updateFlags";
    static readonly UPDATE_ENTRIES = "updateEntries";
    static readonly UPDATE_BEST_SECTORS = "updateBestSectors";
    static readonly CLEAR_ENTRIES = "clearEntries";
    static readonly UPDATE_RESULTS = "updateResults";
    static readonly UPDATE_REFERENTIAL = "updateReferential";
    static readonly UPDATE_CLASSIFICATIONS = "updateClassifications";
    static readonly UPDATE_SESSIONS = "updateSessions";
    static readonly UPDATE_FLAG_VISIBLE = "updateFlagVisible";
    static readonly SWITCH_LANG = "switchLang";
    static readonly SWITCH_TIME = "switchTime";
    static readonly SWITCH_SESSION = "switchSession";
    static readonly CONNECT = "connect";
    static readonly DISCONNECT = "disconnect";
    static readonly SET_NAME = "setName";
    static readonly SET_CHANNELS = "channels";
    static readonly SET_VIDEOS = "setVideos";
    static readonly SET_CHANNEL = "setChannel";
    static readonly SET_CHANNEL_ID = "setChannelId";
    static readonly UPDATE_SELECTED_MENU = "updateSelected";
    static readonly TOGGLE_EXPERT = "toggleExpert";
    static readonly TOGGLE_DARK = "toggleDark";
    static readonly TOGGLE_BESTSECTORMODE = "toggleBestSectorMode";
    static readonly TOGGLE_PREDICTIVE = "togglePredictive";
    static readonly FILTER_CATEGORY = "filterCatgory";
    static readonly UPDATE_SHOW_MODAL_REQUEST_LOGIN = "updateModalRequestLogin";
}
import {t} from "../../../translation/translator";
import {CarStateEnum, States} from "../../../../../../../../../server/common/Enum/live/CarStateEnum";
import React from "react";
import {ReferentialCategoryType} from "../../../type/ReferentialType";
import Entry from "../../../../../../../../../server/common/Models/live/Entry";

type Props = {
    lang: string;
    categories: Array<ReferentialCategoryType>;
    currentResult: Array<Entry>;
}

export const CarsState = (props: Props) => {
    function getCarNumberByStateAndCateg(state: CarStateEnum, category: ReferentialCategoryType) {
        return props.currentResult.filter((entry, index) => entry.state === state && entry.categoryId === category.id).length;
    }

    let carsByCategory = [];
    return (
        <div className="table-race">
            <table className="table-race table table-striped">
                <thead>
                <tr>
                    <th className="align-middle">{t("state", props.lang)}</th>
                    {props.categories.map((category, index) => {
                        return <th className="numeric align-middle" key={index}>{category.name}</th>
                    })}
                    <th className="total numeric align-middle">{t("total", props.lang)}</th>
                </tr>
                </thead>
                <tbody>
                {States.map((state, index) => {
                    let current = 0;
                    return <tr key={index}>
                        <td className="status align-middle">
                        <span className={state.toLowerCase()}>
                            {state.toUpperCase()}
                        </span>
                        </td>
                        {props.categories.map((category, index) => {
                            let number = getCarNumberByStateAndCateg(state, category);
                            current += number;
                            if (!carsByCategory[category.id]) {
                                carsByCategory[category.id] = 0;
                            }
                            carsByCategory[category.id] += number;
                            return <td className=" numeric align-middle" key={index}>{number}</td>
                        })}
                        <td className="total  numeric align-middle">{current}</td>
                    </tr>
                })}
                <tr>
                    <td className="total align-middle">{t("total", props.lang)}</td>
                    {props.categories.map((category, index) => {
                        return <td className="total numeric align-middle" key={index}>{carsByCategory[category.id]}</td>
                    })}
                    <td className="total numeric align-middle">{props.currentResult.length}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}
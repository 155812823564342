export enum SessionTypeEnum {
    NOT_QUALIF = 1,
    QUALIF = 2,
    RACE = 3
}

export enum SessionStatusEnum {
    PENDING = "Not started",
    LIVE = "In progress",
    OVER = "Closed"
}


export enum ChronoTypeEnum {
    FP = "FP",
    Q24H = "Q24H",
    Race = "RACE",
    RACE = "RACE",
    QWEC = "QWEC",
    Q2LAPS = "Q2LAPS"
}
export enum ChronoTypeToSessionTypeEnum {
    FP = SessionTypeEnum.NOT_QUALIF,
    Q24H = SessionTypeEnum.NOT_QUALIF,
    Race = SessionTypeEnum.RACE,
    RACE = SessionTypeEnum.RACE,
    QWEC = SessionTypeEnum.QUALIF,
    Q2LAPS = SessionTypeEnum.QUALIF
}
export function SessionTypeToChronoTypeEnum(sessionType: any) {
    switch (parseInt(sessionType)) {
        case SessionTypeEnum.RACE:
            return ChronoTypeEnum.Race;
        case SessionTypeEnum.QUALIF:
            return ChronoTypeEnum.Q24H;
        case SessionTypeEnum.NOT_QUALIF:
            return ChronoTypeEnum.FP;
    }
    return "euh";
}

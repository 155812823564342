import Stint from "../../../../../../../../../server/common/Models/alkamel/Stint";
import React from "react";
import {t} from "../../../translation/translator";
import {displayTime} from "../../../reducer/live/reducer";
import {StintTypeEnum} from "../../../../../../../../../server/common/Models/alkamel/Enum/StintTypeEnum";
import Entry from "../../../../../../../../../server/common/Models/live/Entry";

type Props = {
    stint: Stint;
    lang: string;
    sessionStart: number;
    currentResult?: Entry
}

export const StintTooltip = (props: Props) => {

    let trad_prefix = props.stint.type === StintTypeEnum.TRACK ? 'stint_' : 'pit_';
    let lapDuration = "";
    if (props.stint.type === StintTypeEnum.TRACK) {
        if (props.stint.closeLapNumber) {
            // terminé
            lapDuration = (1+props.stint.closeLapNumber - props.stint.openLapNumber) + " " + t("laps",props.lang);
        } else {
            if (props.currentResult) {
                lapDuration = (1+props.currentResult.lap - props.stint.openLapNumber) + " " + t("laps",props.lang);
            }
        }
    }
    return <div className="tooltip-table">
        <table>
            <thead>
            <tr>
                <th>{t(trad_prefix + "start", props.lang)}</th>
                <th>{t(trad_prefix + "end", props.lang)}</th>
                <th>{t("duration", props.lang)}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{displayTime((props.stint.startTime - props.sessionStart) / 1000)}</td>
                <td>{props.stint.closeLapNumber ?
                <>
                    {displayTime((props.stint.finishTime - props.sessionStart) / 1000)}
                </> :
                    <>{t('in_progress',props.lang)}</>
                }</td>
                <td>{displayTime((props.stint.duration) / 1000)}</td>
            </tr>
            <tr>
                <td>{t("lap", props.lang)} {props.stint.openLapNumber}</td>
                <td>{props.stint.type === StintTypeEnum.TRACK && props.stint.closeLapNumber &&
                <> {t("lap", props.lang)} {props.stint.closeLapNumber} </>
                }
                </td>
                <td>{lapDuration}</td>
            </tr>
            </tbody>
        </table>
    </div>
}
import React, {Component} from 'react';
import NewsType from "../../type/NewsType";

type Props = { item: NewsType };

export class NewsCardView extends Component<Props, null> {

    createHtml(string: string) {
        return {__html: string};
    }

    render() {

        return (
            <div className="news-card">
                <a className="thumb" href={this.props.item.he_url} target="_blank">
                    <div className="thumb-inner">
                        <img src={this.props.item.picture_small_url} alt={this.props.item.title} />
                        <div className="category-tag">{this.props.item.site}</div>
                    </div>
                </a>
                <div className="content">
                    <span className="date">{this.props.item.publish_date}</span>
                    <a className="title" href={this.props.item.he_url} target="_blank">
                        {this.props.item.title}
                    </a>
                    <div className="social">
                        <a href={"https://www.facebook.com/sharer/sharer.php?u=" + this.props.item.url + "&amp;s=" + this.props.item.title}><i className="fa fa-facebook" aria-hidden="true"/></a>
                        <a href={"http://twitter.com/share?url=" + this.props.item.url + "&amp;text=" + this.props.item.title}><i className="fa fa-twitter" aria-hidden="true"/></a>
                    </div>
                </div>
            </div>
        )
    }

}

export default NewsCardView;
export enum NetEventsEnum {
    params = "params",
    entries = "entries",
    clear_entries = "clear_entries",
    flags = "flags",
    stints = "stints",
    race_control = "race_control",
    laps = "laps",
    race = "race",
    race_light = "race_light",
    mode = "mode",
    best_sectors = "best_sectors"
}
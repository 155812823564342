import React, {Component} from 'react';
import {connect} from "react-redux";
import {FlagsEnum} from "../../../../../../../../server/common/Enum/live/FlagsEnum";
import VisibilitySensor from "react-visibility-sensor";
import ActionLiveType from "../../reducer/live/ActionLiveType";
import {t} from "../../translation/translator";
import Fade from 'react-reveal/Fade';
import LiveReducerType from "../../type/LiveReducerType";
import {ReferentialSessionType} from "../../type/ReferentialType";
import ReducerType from "../../type/ReducerType";
import {getFlagColor, getFlagText} from "../../reducer/live/reducer";

const storeToProps = (state: ReducerType) => ({
    lang: state.live.lang,
    params: state.live.live.params,
    displaySession: state.live.currentSession
});
const storeToDispatch = (dispatch) => ({updateFlagVisible: (visible: boolean) => dispatch(ActionLiveType.updateFlagVisible(visible))});
type Props =
    ReturnType<typeof storeToProps> &
    ReturnType<typeof storeToDispatch> &
    { class: string, mobileMode?: boolean, checkVisibility: boolean, displaySession: ReferentialSessionType };

class RaceFlagView extends Component<Props, any> {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(isVisible) {
        if ((window.innerWidth <= 990 && this.props.mobileMode) || (window.innerWidth > 990 && !this.props.mobileMode)) {
            this.props.updateFlagVisible(isVisible);
        }
    }

    render() {
        let colorFlag = getFlagColor(this.props.params.raceState);

        if (this.props.displaySession && this.props.displaySession.status == "Closed") {
            colorFlag = "chk";
        }

        // construct class
        let className = this.props.class;

        if (colorFlag == "chk") {
            className += " race-over";
        } else {
            className += " " + colorFlag + " race-state";
        }

        return (
            <VisibilitySensor partialVisibility active={this.props.checkVisibility} onChange={this.onChange}>
                <Fade spy={this.props.params.raceState}>
                    <div className={className}>
                        {colorFlag == "chk" ?
                            <FlagCheckerBoard/> :
                            <>
                                {getFlagText(this.props.params.raceState, this.props.lang)}
                            </>
                        }
                    </div>
                </Fade>
            </VisibilitySensor>
        )
    }
}

export const FlagCheckerBoard = () => {
    return (
        <svg className={'checkerboard'} viewBox="0 0 152 37">
            <defs>
                <rect id="path-1" x="0" y="0" width="150" height="35" rx="1"></rect>
            </defs>
            <g id="Symbols" stroke="none" fill="none">
                <g id="NAV/Flag/Finish" transform="translate(1.000000, 1.000000)">
                    <g id="Flag">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1"></use>
                        </mask>
                        <use id="Rectangle" stroke="#000000" fill="#FFFFFF" xlinkHref="#path-1"></use>
                        <g id="Row" mask="url(#mask-2)" fill="#000000">
                            <rect id="Rectangle" x="0" y="0" width="5" height="5"></rect>
                            <rect id="Rectangle" x="10" y="0" width="5" height="5"></rect>
                            <rect id="Rectangle" x="20" y="0" width="5" height="5"></rect>
                            <rect id="Rectangle" x="30" y="0" width="5" height="5"></rect>
                            <rect id="Rectangle" x="40" y="0" width="5" height="5"></rect>
                            <rect id="Rectangle" x="50" y="0" width="5" height="5"></rect>
                            <rect id="Rectangle" x="60" y="0" width="5" height="5"></rect>
                            <rect id="Rectangle" x="70" y="0" width="5" height="5"></rect>
                            <rect id="Rectangle" x="80" y="0" width="5" height="5"></rect>
                            <rect id="Rectangle" x="90" y="0" width="5" height="5"></rect>
                            <rect id="Rectangle" x="100" y="0" width="5" height="5"></rect>
                            <rect id="Rectangle" x="110" y="0" width="5" height="5"></rect>
                            <rect id="Rectangle" x="120" y="0" width="5" height="5"></rect>
                            <rect id="Rectangle" x="130" y="0" width="5" height="5"></rect>
                            <rect id="Rectangle" x="140" y="0" width="5" height="5"></rect>
                        </g>
                        <g id="Row" mask="url(#mask-2)" fill="#000000">
                            <g transform="translate(5.000000, 5.000000)" id="Rectangle">
                                <rect x="0" y="0" width="5" height="5"></rect>
                                <rect x="10" y="0" width="5" height="5"></rect>
                                <rect x="20" y="0" width="5" height="5"></rect>
                                <rect x="30" y="0" width="5" height="5"></rect>
                                <rect x="40" y="0" width="5" height="5"></rect>
                                <rect x="50" y="0" width="5" height="5"></rect>
                                <rect x="60" y="0" width="5" height="5"></rect>
                                <rect x="70" y="0" width="5" height="5"></rect>
                                <rect x="80" y="0" width="5" height="5"></rect>
                                <rect x="90" y="0" width="5" height="5"></rect>
                                <rect x="100" y="0" width="5" height="5"></rect>
                                <rect x="110" y="0" width="5" height="5"></rect>
                                <rect x="120" y="0" width="5" height="5"></rect>
                                <rect x="130" y="0" width="5" height="5"></rect>
                                <rect x="140" y="0" width="5" height="5"></rect>
                            </g>
                        </g>
                        <g id="Row" mask="url(#mask-2)" fill="#000000">
                            <g transform="translate(0.000000, 10.000000)" id="Rectangle">
                                <rect x="0" y="0" width="5" height="5"></rect>
                                <rect x="10" y="0" width="5" height="5"></rect>
                                <rect x="20" y="0" width="5" height="5"></rect>
                                <rect x="30" y="0" width="5" height="5"></rect>
                                <rect x="40" y="0" width="5" height="5"></rect>
                                <rect x="50" y="0" width="5" height="5"></rect>
                                <rect x="60" y="0" width="5" height="5"></rect>
                                <rect x="70" y="0" width="5" height="5"></rect>
                                <rect x="80" y="0" width="5" height="5"></rect>
                                <rect x="90" y="0" width="5" height="5"></rect>
                                <rect x="100" y="0" width="5" height="5"></rect>
                                <rect x="110" y="0" width="5" height="5"></rect>
                                <rect x="120" y="0" width="5" height="5"></rect>
                                <rect x="130" y="0" width="5" height="5"></rect>
                                <rect x="140" y="0" width="5" height="5"></rect>
                            </g>
                        </g>
                        <g id="Row" mask="url(#mask-2)" fill="#000000">
                            <g transform="translate(5.000000, 15.000000)" id="Rectangle">
                                <rect x="0" y="0" width="5" height="5"></rect>
                                <rect x="10" y="0" width="5" height="5"></rect>
                                <rect x="20" y="0" width="5" height="5"></rect>
                                <rect x="30" y="0" width="5" height="5"></rect>
                                <rect x="40" y="0" width="5" height="5"></rect>
                                <rect x="50" y="0" width="5" height="5"></rect>
                                <rect x="60" y="0" width="5" height="5"></rect>
                                <rect x="70" y="0" width="5" height="5"></rect>
                                <rect x="80" y="0" width="5" height="5"></rect>
                                <rect x="90" y="0" width="5" height="5"></rect>
                                <rect x="100" y="0" width="5" height="5"></rect>
                                <rect x="110" y="0" width="5" height="5"></rect>
                                <rect x="120" y="0" width="5" height="5"></rect>
                                <rect x="130" y="0" width="5" height="5"></rect>
                                <rect x="140" y="0" width="5" height="5"></rect>
                            </g>
                        </g>
                        <g id="Row" mask="url(#mask-2)" fill="#000000">
                            <g transform="translate(0.000000, 20.000000)" id="Rectangle">
                                <rect x="0" y="0" width="5" height="5"></rect>
                                <rect x="10" y="0" width="5" height="5"></rect>
                                <rect x="20" y="0" width="5" height="5"></rect>
                                <rect x="30" y="0" width="5" height="5"></rect>
                                <rect x="40" y="0" width="5" height="5"></rect>
                                <rect x="50" y="0" width="5" height="5"></rect>
                                <rect x="60" y="0" width="5" height="5"></rect>
                                <rect x="70" y="0" width="5" height="5"></rect>
                                <rect x="80" y="0" width="5" height="5"></rect>
                                <rect x="90" y="0" width="5" height="5"></rect>
                                <rect x="100" y="0" width="5" height="5"></rect>
                                <rect x="110" y="0" width="5" height="5"></rect>
                                <rect x="120" y="0" width="5" height="5"></rect>
                                <rect x="130" y="0" width="5" height="5"></rect>
                                <rect x="140" y="0" width="5" height="5"></rect>
                            </g>
                        </g>
                        <g id="Row" mask="url(#mask-2)" fill="#000000">
                            <g transform="translate(5.000000, 25.000000)" id="Rectangle">
                                <rect x="0" y="0" width="5" height="5"></rect>
                                <rect x="10" y="0" width="5" height="5"></rect>
                                <rect x="20" y="0" width="5" height="5"></rect>
                                <rect x="30" y="0" width="5" height="5"></rect>
                                <rect x="40" y="0" width="5" height="5"></rect>
                                <rect x="50" y="0" width="5" height="5"></rect>
                                <rect x="60" y="0" width="5" height="5"></rect>
                                <rect x="70" y="0" width="5" height="5"></rect>
                                <rect x="80" y="0" width="5" height="5"></rect>
                                <rect x="90" y="0" width="5" height="5"></rect>
                                <rect x="100" y="0" width="5" height="5"></rect>
                                <rect x="110" y="0" width="5" height="5"></rect>
                                <rect x="120" y="0" width="5" height="5"></rect>
                                <rect x="130" y="0" width="5" height="5"></rect>
                                <rect x="140" y="0" width="5" height="5"></rect>
                            </g>
                        </g>
                        <g id="Row" mask="url(#mask-2)" fill="#000000">
                            <g transform="translate(0.000000, 30.000000)" id="Rectangle">
                                <rect x="0" y="0" width="5" height="5"></rect>
                                <rect x="10" y="0" width="5" height="5"></rect>
                                <rect x="20" y="0" width="5" height="5"></rect>
                                <rect x="30" y="0" width="5" height="5"></rect>
                                <rect x="40" y="0" width="5" height="5"></rect>
                                <rect x="50" y="0" width="5" height="5"></rect>
                                <rect x="60" y="0" width="5" height="5"></rect>
                                <rect x="70" y="0" width="5" height="5"></rect>
                                <rect x="80" y="0" width="5" height="5"></rect>
                                <rect x="90" y="0" width="5" height="5"></rect>
                                <rect x="100" y="0" width="5" height="5"></rect>
                                <rect x="110" y="0" width="5" height="5"></rect>
                                <rect x="120" y="0" width="5" height="5"></rect>
                                <rect x="130" y="0" width="5" height="5"></rect>
                                <rect x="140" y="0" width="5" height="5"></rect>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}


const RaceFlag = connect(storeToProps, storeToDispatch)(RaceFlagView);
export default RaceFlag;



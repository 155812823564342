import {t} from "../translation/translator";
import React, {ChangeEvent} from "react";

type Props = {
    checked: boolean,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
    label: string,
    lang: string
}

export const Toggle = (props: Props) => {
    return <div className="toggle">
                        <span>
                            {t(props.label, props.lang)}
                        </span>
        <label className="switch">
            <input type="checkbox" checked={props.checked || false} onChange={props.onChange}/>
            <span className="slider round"/>
        </label>
    </div>
}
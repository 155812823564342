import moment from "moment";
import {useEffect} from "react";
import socketIOClient from "socket.io-client";
import Live from "../../../../../../../server/common/Models/live/Live";
window.moment = moment;

class Header {
    constructor($view){
        this.view = $view;
        this.burger = this.view.find('.js-burger');
        this.localtime = this.view.find('.js-local-time');
        this.menuRwd = this.view.find('.main-nav-rwd');
        this.bind();
    }
    bind(){
        let self = this;
        self.burger.click(() => {
            self.burger.toggleClass('menu-expanded');
            self.menuRwd.toggleClass('expanded');
            $("body").toggleClass('stop-scrolling');
        });
        self.local = true;
        self.localtime.on("click", () => {
            let other = self.localtime.data("other");
            self.localtime.data("other", self.localtime.html());
            self.localtime.html(other);
            self.local = !self.local;
            $(".js-date").each((i,item) => {
                let timestamp = $(item).data("timestamp");
                let timezone = $(item).data("timezone");
                let day = $(item).data("day");
                if (!self.local) {
                    // passage en heure client
                    let mom = moment.unix(timestamp);
                    let localTime = mom.clone().local();

                    $(item).html(day + " / " + localTime.format("HH") + "h" + localTime.format("mm"));
                } else {
                    // passage en heure locale de la course
                    $(item).html($(item).data("local"));
                }
            });
        });

        $("body").on("click","a[data-lang]", (e) => {
            e.preventDefault();
            let newLang = $(e.target).data("lang");
            let other = (newLang == 'en') ? 'fr' : 'en';

            let url = window.location.href;
            if (url.indexOf("/fr")<0 && url.indexOf("/en")<0) {
                window.location = $(e.target).attr('href');
            } else {
                window.location = url.replace("/" + other,'/' + newLang);
            }
        });

        $(window).on('resize', () => {
            self.burger.removeClass('menu-expanded');
            self.menuRwd.removeClass('expanded');
            $("body").removeClass('stop-scrolling');
        });
    }


}
module.exports = Header;
import {actionTypeEnum} from "./actionTypeEnum";
import StintsReducerType from "../../type/StintsReducerType";
import ParticipantStints from "../../../../../../../../server/common/Models/alkamel/ParticipantStints";
import ReducerType from "../../type/ReducerType";
import {DriverStints} from "../../type/ReferentialType";
import ActionStints from "./ActionStints";
import {StintTypeEnum} from "../../../../../../../../server/common/Models/alkamel/Enum/StintTypeEnum";
import Stint from "../../../../../../../../server/common/Models/alkamel/Stint";
import LapAnalysis from "../../../../../../../../server/common/Models/alkamel/LapAnalysis";
import ParticipantLaps from "../../../../../../../../server/common/Models/alkamel/ParticipantLaps";
import DriverDetail from "../../type/DriverDetail";

/**
 * Données initiales
 */
const initialState = new StintsReducerType();

/**
 * Reducer Stints
 * @param state
 * @param action
 */
const reducer = (state = initialState, action: { type: actionTypeEnum, data: any }) => {
    if (undefined == state) {
        state = new StintsReducerType();
    }
    let newState = {...state};
    switch (action.type) {
        case actionTypeEnum.UPDATE_STINTS:
            let stints = newState.byEntry;
            let participantStints: ParticipantStints;

            for (let index in action.data) {
                participantStints = action.data[index];
                if (null != participantStints) {
                    let number = participantStints.participant;

                    // recherche dans les lignes existantes
                    let previous = stints.findIndex(e => null != e && e.participant == number);
                    if (0 <= previous) {
                        stints[previous] = participantStints;
                    } else {
                        stints.push(participantStints);
                    }
                }
            }
            newState.byEntry = stints;

            break;

        case actionTypeEnum.UPDATE_DRIVER_STINTS:
            let allDriversStints = newState.byDriver;

            for (let index in action.data) {
                let driverStints: DriverStints = action.data[index];
                if (null != driverStints) {
                    let driverId = driverStints.driverId;

                    // recherche dans les lignes existantes
                    let previous = allDriversStints.findIndex(e => null != e && e.driverId == driverId);
                    if (0 <= previous) {
                        allDriversStints[previous] = driverStints;
                    } else {
                        allDriversStints.push(driverStints);
                    }
                }
            }
            newState.byDriver = allDriversStints;
            break;
        default:
            return state;
    }
    return newState;
};


export function connectToSocket() {
    return async function (dispatch: Function, getState: () => ReducerType) {
        let socket = getState().live.socket;
        socket.emit("stints:join");
    }
}

export function disconnectFromSocket() {
    return async function (dispatch: Function, getState: () => ReducerType) {
        let socket = getState().live.socket;
        socket.emit("stints:leave");
    }
}

/**
 * Récupère les stats d'un driver
 * @param driverStints
 * @param carLaps
 * @param currentLapNumber
 */
export function getDriverDetail(driverStints: DriverStints, carLaps: ParticipantLaps, currentLapNumber: number) : DriverDetail {

    let nbStints = 0;
    let laps = 0;
    let best = 0;
    let driveTime = 0;

    for (let i in driverStints.stints) {
        let stint: Stint = driverStints.stints[i];
        if (stint.type === StintTypeEnum.TRACK) {
            nbStints++;
            let lastLap = stint.closeLapNumber;
            if (!stint.closeLapNumber) {
                // relais en cours : récupérer le tour courant
                    lastLap = currentLapNumber
            }
            laps += (1 + lastLap - stint.openLapNumber);
            driveTime += stint.duration;

            if (carLaps) {
                // parcours des tours du relais
                let lap: LapAnalysis = null;
                for (let lapNumber = stint.openLapNumber; lapNumber <= lastLap; lapNumber++) {
                    if (carLaps.laps[lapNumber]) {
                        lap = carLaps.laps[lapNumber];
                        if (lap.isValid && (0 === best || best > lap.time)) {
                            best = lap.time;
                        }
                    }
                }
            }
        }
    }
    return {
        stints: nbStints,
        laps: laps,
        best: best,
        drive_time: driveTime/1000
    };
}

export function updateDriverStints(data: ParticipantStints[]) {
    return async function (dispatch: Function, getState: () => ReducerType) {

        let byDrivers: DriverStints[] = [];
        let state = getState();
        let entries = state.live.referential.entries;
        for (let index in data) {
            let participantStints = data[index];
            if (null != participantStints) {
                let number = participantStints.participant;
                let entry = entries.find(e => e.number === number.toString())
                if (undefined !== entry) {
                    let currentDriverNumber = -1;
                    let currentType = ""
                    // on parcourt tous les stints de l'engagé pour les répartir par pilote
                    for (let start in participantStints.stints) {
                        let stint = participantStints.stints[start];

                        if (null !== stint && stint.driver > 0) {
                            let driverId = entry.drivers[stint.driver-1];
                            if (undefined != driverId) {
                                if (!byDrivers[driverId]) {
                                    byDrivers[driverId] = new DriverStints();
                                    byDrivers[driverId].driverId = driverId;
                                    byDrivers[driverId].participant = number;
                                }

                                // on regarde si on a changé de driver par rapport au précédent
                                if (currentDriverNumber != stint.driver || currentType != stint.type) {
                                    // nouveau driver : on lui ajoute un stint
                                    byDrivers[driverId].stints[start] = {...stint};

                                } else {
                                    // meme driver : on fusionne avec le précédent
                                    let lastKey = ""; //keys[keys.length-1];

                                    for (let i in byDrivers[driverId].stints) {
                                        lastKey = i;
                                    }

                                    let previous:Stint = byDrivers[driverId].stints[lastKey];

                                    if (previous){
                                        previous.finishTime = stint.finishTime;
                                        previous.closeLapNumber = stint.closeLapNumber;
                                        previous.duration = previous.finishTime - previous.startTime;
                                    } else {
                                        byDrivers[driverId].stints[start] = {...stint};
                                    }

                                }
                                currentDriverNumber = stint.driver;
                                currentType = stint.type;
                            }
                        }
                    }
                }
            }
        }
        for (let index in byDrivers) {
            let driverStints = byDrivers[index];
            driverStints.driveTime = 0;
            driverStints.pitTime = 0;
            for (let start in driverStints.stints) {
                if (driverStints.stints[start]) {
                    let stint = driverStints.stints[start];
                    if (stint.type === StintTypeEnum.PIT) {
                        driverStints.pitTime += stint.duration;
                    } else {
                        driverStints.driveTime += stint.duration;
                    }
                }
            }
        }
        dispatch(ActionStints.updateDriverStints(byDrivers));
    }
}

export default reducer;
import {actionTypeEnum} from "./actionTypeEnum";
import ReducerType from "../../type/ReducerType";
import LapsReducerType from "../../type/LapsReducerType";
import ParticipantLaps from "../../../../../../../../server/common/Models/alkamel/ParticipantLaps";

/**
 * Données initiales
 */
const initialState = new LapsReducerType();

/**
 * Reducer Laps
 * @param state
 * @param action
 */
const reducer = (state = initialState, action: { type: actionTypeEnum, data: any }) => {
    if (undefined == state) {
        state = new LapsReducerType();
    }
    let newState = {...state};
    switch (action.type) {
        case actionTypeEnum.UPDATE_LAPS:
            let laps = newState.laps;
            let participantLaps: ParticipantLaps;

            for (let index in action.data) {
                participantLaps = action.data[index];
                if (null != participantLaps) {
                    let number = participantLaps.participant;

                    // recherche dans les lignes existantes
                    let previous = laps.findIndex(e => null != e && e.participant == number);
                    if (0 <= previous) {
                        laps[previous] = participantLaps;
                    } else {
                        laps.push(participantLaps);
                    }
                }
            }
            newState.laps = laps;

            break;

        default:
            return state;
    }
    return newState;
};


export function connectToSocket() {
    return async function (dispatch: Function, getState: () => ReducerType) {
        let socket = getState().live.socket;
        socket.emit("laps:join");
    }
}

export function disconnectFromSocket() {
    return async function (dispatch: Function, getState: () => ReducerType) {
        let socket = getState().live.socket;
        socket.emit("laps:leave");
    }
}

export default reducer;